import React, { useEffect, useState } from 'react'

import { Container } from 'react-bootstrap'
import { IoMdStar } from "react-icons/io";
import Img1 from '../images/best-seller01.jpg'
import Img2 from '../images/best-seller02.jpg'
import Img3 from '../images/best-seller03.jpg'
import Img4 from '../images/best-seller04.jpg'
import Img5 from '../images/best-seller05.jpg'
import { useDispatch, useSelector } from 'react-redux';
import { fetchSettings } from '../Redux/actions/settingsActions';
import Slider from 'react-slick';
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import axios from 'axios';

function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}>
            <FaArrowRight />
        </div>
    );
}
function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}>
            <FaArrowLeft />
        </div>
    );
}

const BestSeller = () => {
    const [product, setProduct] = useState([]);
    // ******* CMS START ******* 
    const dispatch = useDispatch();
    const { settings, loading, error } = useSelector(state => state.settings);
    useEffect(() => {
        dispatch(fetchSettings());
    }, [dispatch]);
    const section_heading_1 = settings.find(setting => setting.name === 'section_heading_1');
    // ******* CMS END ******* 

    var setting = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 3,

                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    }

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                let baseUrl = process.env.REACT_APP_BASE_URL;
                const response = await axios.get(baseUrl + '/api/show-products');
                const featuredProducts = response.data.filter(
                    product => product.featured_tag === 'exclusive'
                );
                setProduct(featuredProducts);
            } catch (error) {
                console.error('Error fetching Featured product:', error);
            }
        };
        fetchProduct();
    }, []);
    return (
        <>
            <section>
                <div className="new-pro best-seller">
                    <Container>
                        <h2 className="head-sec">{section_heading_1?.value}</h2>
                        <Slider {...setting}>
                            {product.map((product, slug) => {
                                const cleanSlug = product.slug.replace(/-/g, " ");
                                const cleanedUrl = product.imagestwo
                                    .replace(/[\\[\]"]/g, '')
                                    .split(',')
                                    .map(url =>
                                        url.includes('/storage/app/')
                                            ? url.trim()
                                            : url.replace('/storage/', '/storage/app/')
                                    );
                                return (
                                    <div className="new-box" key={product.id}>
                                        <div className="img-holder">
                                            <Link to={"/single-product/" + product.id}>
                                                {cleanedUrl[0] && (
                                                    <img src={cleanedUrl[0]} alt={`${slug} image 1`} />
                                                )}
                                            </Link>
                                        </div>
                                        <div className="rate-area">
                                            <ul>
                                                <li><IoMdStar /></li>
                                                <li><IoMdStar /></li>
                                                <li><IoMdStar /></li>
                                                <li><IoMdStar /></li>
                                                <li><IoMdStar /></li>
                                            </ul>
                                            <span>(05 Review)</span>
                                        </div>
                                        <Link className="cat-title" to={"/single-product/" + product.id}>
                                            <strong>{cleanSlug}</strong>
                                        </Link>
                                        <p>product.description</p>
                                        <div className="new-prices">
                                            <span>${product.price}</span><del>${product.special_price}</del>
                                        </div>
                                    </div>
                                )
                            }
                            )}
                        </Slider>
                    </Container>
                </div>
            </section>
        </>
    )
}

export default BestSeller