export const loginSuccess = (userData) => {
  localStorage.setItem('user', JSON.stringify(userData));
  return {
    type: 'LOGIN_SUCCESS',
    payload: {
      ...userData,
      userId: userData.id
    }
  };
};
  
  export const loginFailure = (error) => {
    return {
      type: 'LOGIN_FAILURE',
      payload: error
    };
  };

  export const logout = () => {
    return {
      type: 'LOGOUT',
    };
  };
