import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Card, Container, Row, Col, Modal, Table } from 'react-bootstrap';
import Loading from '../../components/Loading';

const PaymentMethods = () => {
    const [show, setShow] = useState(false);
    const [payments, setPayments] = useState([]);
    const [formData, setFormData] = useState({
        userId: '',
        bank: '',
        name: '',
        card_number: '',
        expiry: '',
        cvv: '',
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    useEffect(() => {
        const fetchPayments = async () => {
            setLoading(true);
            setError('');
            try {
                const user = JSON.parse(localStorage.getItem('user'));
                const response = await axios.get('http://localhost/thatreadsnew/api/payment-show');
                // Filter payments by userId
                const userPayments = response.data.filter(payment => payment.user_id === user.id);
                setPayments(userPayments);
            } catch (err) {
                setError('Failed to load payment methods. Please try again.');
                console.error('Error fetching payment methods:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchPayments();
    }, []);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            setFormData((prevState) => ({
                ...prevState,
                userId: user.id.toString(),
            }));
        }
    }, []);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setSuccess('');
        try {
            let baseUrl = process.env.REACT_APP_BASE_URL;
            const response = await axios.post(baseUrl + '/api/payment-store', formData);
            setSuccess('User updated successfully!');
            console.log('Response:', response.data);
        } catch (err) {
            setError('Failed. Please try again.');
            console.error('Error:', err);
        } finally {
            setLoading(false);
        }
    };

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <div className="dash-card">
                <Card className="dash-sub-card">
                    <Card.Header as="h4" className="dash-card-header">Payment Methods</Card.Header>
                    <Card.Body>
                        {loading && <Loading />}
                        {error && <div className="alert alert-danger">{error}</div>}
                        {success && <div className="alert alert-success">{success}</div>}
                        <Container>
                            {!loading && payments.length > 0 ? (
                                <Table striped bordered hover variant="light">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Bank Name</th>
                                            <th>Card Holder Name</th>
                                            <th>Card Number</th>
                                            <th>Expiry</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {payments.map(payment => (
                                            <tr key={payment.id}>
                                                <td>{payment.id}</td>
                                                <td>{payment.bank}</td>
                                                <td>{payment.name}</td>
                                                <td>{payment.card_number}</td>
                                                <td>{payment.expiry}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            ) : (
                                !loading && <div>No payment methods found.</div>
                            )}
                            <button className='blue-btn btn-style round-btn btn btn-link' onClick={handleShow}>
                                Add New Payment Method
                            </button>
                            <Modal
                                size="lg"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                show={show} onHide={handleClose}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Modal heading</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <form onSubmit={handleSubmit}>
                                        <Row>
                                            <Col md={6} className="mb-3">
                                                <input
                                                    placeholder="Bank Name"
                                                    type="text"
                                                    name="bank"
                                                    value={formData.bank}
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    required
                                                />
                                            </Col>
                                            <Col md={6} className="mb-3">
                                                <input
                                                    placeholder="Card Holder Name"
                                                    type="text"
                                                    name="name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    required
                                                />
                                            </Col>
                                            <Col md={12} className="mb-3">
                                                <input
                                                    placeholder="Card Number"
                                                    type="text"
                                                    name="card_number"
                                                    value={formData.card_number}
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    required
                                                />
                                            </Col>
                                            <Col md={6} className="mb-3">
                                                <input
                                                    placeholder="Expiry (MM/YY)"
                                                    type="text"
                                                    name="expiry"
                                                    value={formData.expiry}
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    required
                                                />
                                            </Col>
                                            <Col md={6} className="mb-3">
                                                <input
                                                    placeholder="CVV"
                                                    type="text"
                                                    name="cvv"
                                                    value={formData.cvv}
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    required
                                                />
                                            </Col>
                                            <Col md={12}>
                                                <button
                                                    type="submit"
                                                    className="blue-btn btn-style round-btn btn btn-link"
                                                    disabled={loading}
                                                >
                                                    {loading ? 'Saving...' : 'Save Payment Method'}
                                                </button>
                                            </Col>
                                        </Row>
                                    </form>
                                </Modal.Body>
                            </Modal>
                        </Container>
                    </Card.Body>
                </Card>
            </div>
        </>
    );
};

export default PaymentMethods;
