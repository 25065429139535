import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container } from 'react-bootstrap';
import Loading from '../../components/Loading';
import { FaCartShopping } from "react-icons/fa6";
import { FaBoxArchive } from "react-icons/fa6";
import { IoCheckbox } from "react-icons/io5";
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

const OrderTracking = () => {
    const [loading, setLoading] = useState(true);
    const [orderStatus, setOrderStatus] = useState('');
    const { id } = useParams();
    let userInfo = JSON.parse(localStorage.getItem('user-info'));

    useEffect(() => {
        const fetchOrders = async () => {
            try {
                let baseUrl = process.env.REACT_APP_BASE_URL;
                const response = await axios.get(baseUrl + `/api/single-orders/${id}`);
                setOrderStatus(response.data.status);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching orders:', error);
                setLoading(false);
            }
        };
        fetchOrders();
    }, [userInfo.id, id]);

    if (loading) {
        return <><Loading /></>;
    }
    return (
        <>
            <div className=' mt-5 mb-5 pt-5 pb-5'>
                <Container>
                    <div className='padding'>
                        {orderStatus ? (
                            <ol className="progtrckr" data-progtrckr-steps="5">
                                <li className='progtrckr-done'> <i><FaCartShopping /></i>  Order</li>
                                <li className={orderStatus === 'processing' || orderStatus === 'completed' ? 'progtrckr-done' : 'progtrckr-todo'}><i><FaBoxArchive /></i>{orderStatus === 'processing' || orderStatus === 'completed' ? 'Processing' : 'Processing'}</li>
                                <li className={orderStatus === 'completed' ? 'progtrckr-done' : 'progtrckr-todo'}> <i><IoCheckbox /></i>{orderStatus === 'completed' ? 'Completed' : 'Completed'}</li>
                            </ol>
                        ) : (
                            <div className="container">
                                <div className='page-not-found order_id'>
                                    <h2>404</h2>
                                    <h3>We couldn't find any information for the order with ID: {id}</h3>
                                    <p>
                                        Please make sure you have entered the correct order ID.
                                        <br />
                                        If you need further assistance, please contact our customer support at <strong>rijoxavier81@gmail.com</strong>.
                                    </p>
                                    <button className='custom_btn'> <Link to="/order-search">Go Back</Link></button>
                                </div>
                            </div>
                        )}
                    </div>
                </Container>
            </div>
        </>
    );
};

export default OrderTracking;
