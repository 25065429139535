import React, { useState, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Img from '../images/latest-banner.png'
import { useDispatch, useSelector } from 'react-redux';
import { fetchSettings } from '../Redux/actions/settingsActions';
import { Link } from 'react-router-dom';

const Collections = () => {
    // ******* CMS START ******* 
    const dispatch = useDispatch();
    const { settings, loading, error } = useSelector(state => state.settings);
    useEffect(() => {
        dispatch(fetchSettings());
    }, [dispatch]);
    const section_title_2 = settings.find(setting => setting.name === 'section_title_2');
    const section_sub_title_2 = settings.find(setting => setting.name === 'section_sub_title_2');
    const section_2_sub_des = settings.find(setting => setting.name === 'section_2_sub_des');
    const section_2_btn_txt = settings.find(setting => setting.name === 'section_2_btn_txt');
    const section_2_btn_l = settings.find(setting => setting.name === 'section_2_btn_l');
    // For image 
    const section_2_img = settings.find(setting => setting.name === 'section_2_img');
    const baseImageUrl = process.env.REACT_APP_BASE_URL + '/public/uploads/media/';
    const imageUrl = section_2_img?.value ? `${baseImageUrl}${section_2_img.value}` : '';
    // ******* CMS END ******* 
    // ******* IMAGE HOVER ANIMATION START ******* 
    const [offset, setOffset] = useState({ x: 0, y: 0 });
    const handleMouseMove = (event) => {
        const rect = event.currentTarget.getBoundingClientRect();
        const posX = event.clientX - (rect.left + rect.width / 2);
        const posY = event.clientY - (rect.top + rect.height / 2);
        const rotateX = (posY / rect.height) * 15;
        const rotateY = -(posX / rect.width) * 15;
        setOffset({
            x: posX * 0.012, y: posY * 0.012,
            rotateX, rotateY,
        });
    };
    const resetOffset = () => {
        setOffset({ x: 0, y: 0, rotateX: 0, rotateY: 0 });
    };
    // ******* IMAGE HOVER ANIMATION END ******* 
    return (
        <>
            <section>
                <div className="latest-col">
                    <Container>
                        <Row>
                            <Col md={6} sm={12} xs={12}>
                                <div className="content-side">
                                    <span>{section_title_2?.value}</span>
                                    <h2>{section_sub_title_2?.value}</h2>
                                    <div dangerouslySetInnerHTML={{ __html: section_2_sub_des?.value }} />
                                    <Link className="white-btn btn-style" to={section_2_btn_l?.value}>{section_2_btn_txt?.value}</Link>
                                </div>
                            </Col>
                            <Col md={6} sm={12} xs={12}>
                                <div className="img-holder">
                                    <img
                                        src={imageUrl}
                                        alt="BOOK Img"
                                        onMouseMove={handleMouseMove}
                                        onMouseLeave={resetOffset}
                                        style={{
                                            willChange: 'transform',
                                            transition: 'transform 1000ms cubic-bezier(0.03, 0.98, 0.52, 0.99)',
                                            transform: `perspective(800px) rotateX(${offset.rotateX}deg) rotateY(${offset.rotateY}deg) scale3d(1, 1, 1) translate(${offset.x}%, ${offset.y}%)`,
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
        </>
    )
}

export default Collections