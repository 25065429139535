import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import SectionHeader from '../../components/SectionHeader'

import HeroImg from "../../images/hero-img.png"

import { Col, Container, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { fetchSettings } from '../../Redux/actions/settingsActions';


const About = () => {


    // ************ CMS START ************
    const dispatch = useDispatch();
    const { settings, loading, error } = useSelector(state => state.settings);

    useEffect(() => {
        dispatch(fetchSettings());
    }, [dispatch]);
    const about_header_title = settings.find(setting => setting.name === 'about_header_title');
    const about_title = settings.find(setting => setting.name === 'about_title');
    const about_sub_title = settings.find(setting => setting.name === 'about_sub_title');
    // IMAGE
    const about_us_image_one = settings.find(setting => setting.name === 'about_us_image_one'); 
    const baseImageUrl = process.env.REACT_APP_BASE_URL + '/public/uploads/media/';
    const imageUrl = about_us_image_one?.value ? `${baseImageUrl}${about_us_image_one.value}` : '';
    // ************ CMS END ************
    // ******* IMAGE HOVER ANIMATION START ******* 
    const [offset, setOffset] = useState({ x: 0, y: 0 });
    const handleMouseMove = (event) => {
        const rect = event.currentTarget.getBoundingClientRect();
        const posX = event.clientX - (rect.left + rect.width / 2);
        const posY = event.clientY - (rect.top + rect.height / 2);
        const rotateX = (posY / rect.height) * 15;
        const rotateY = -(posX / rect.width) * 15;
        setOffset({
            x: posX * 0.012, y: posY * 0.012,
            rotateX, rotateY,
        });
    };
    const resetOffset = () => {
        setOffset({ x: 0, y: 0, rotateX: 0, rotateY: 0 });
    };
    // ******* IMAGE HOVER ANIMATION START ******* 

    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <>
            <section>
                <SectionHeader title={about_header_title?.value} PageLink={about_header_title?.value}></SectionHeader>
                <div className="in-page-container pt-5 pb-5">
                    <div className="about-section top__about">
                        <Container>
                            <div className='inner__about'>
                                <div className="about-img img-holder float_about-img">
                                    <img
                                        src={imageUrl}
                                        alt="Hero Img"
                                        onMouseMove={handleMouseMove}
                                        onMouseLeave={resetOffset}
                                        style={{
                                            willChange: 'transform',
                                            transition: 'transform 1000ms cubic-bezier(0.03, 0.98, 0.52, 0.99)',
                                            transform: `perspective(800px) rotateX(${offset.rotateX}deg) rotateY(${offset.rotateY}deg) scale3d(1, 1, 1) translate(${offset.x}%, ${offset.y}%)`,
                                        }}
                                    />
                                </div>
                                <div>
                                    <div class="about-text">
                                        <h2>{about_title?.value}</h2>
                                        <div dangerouslySetInnerHTML={{ __html: about_sub_title?.value }} /></div>
                                </div>
                            </div>
                            {/* <Row className='inner__about'>
                                <Col md={5}>
                                    <div className="about-img img-holder">
                                        <img
                                            src={imageUrl}
                                            alt="Hero Img"
                                            onMouseMove={handleMouseMove}
                                            onMouseLeave={resetOffset}
                                            style={{
                                                willChange: 'transform',
                                                transition: 'transform 1000ms cubic-bezier(0.03, 0.98, 0.52, 0.99)',
                                                transform: `perspective(800px) rotateX(${offset.rotateX}deg) rotateY(${offset.rotateY}deg) scale3d(1, 1, 1) translate(${offset.x}%, ${offset.y}%)`,
                                            }}
                                        />
                                    </div>
                                </Col>
                                <div className="col-md-6"><div class="about-text">
                                    <h2>{about_title?.value}</h2>
                                    <div dangerouslySetInnerHTML={{ __html: about_sub_title?.value }} /></div>
                                </div>
                            </Row> */}
                        </Container>
                    </div>
                </div>
            </section>

        </>
    )
}

export default About