import React, { useEffect, useState, useCallback } from 'react';
import SectionHeader from '../../components/SectionHeader';
import HeaderImage from '../../images/section-herder-img.jpg';
import NoImage from '../../images/NoImage.jpg';
import Img from '../../images/best-seller04.jpg';
import { MdDelete } from "react-icons/md";
import { Col, Container, Row, Table } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { DLT, ADD, REMOVE } from '../../Redux/actions/CartAction';

const Cart = () => {
    const [data, setData] = useState([]);
    const [price, setPrice] = useState(0);

    const { id } = useParams();
    const history = useNavigate();
    const dispatch = useDispatch();
    const variation = useSelector(state => state.variation.variation || []);
    console.log('Selected Variation:', variation);
    const getdata = useSelector((state) => state.cartreducer.carts);
    console.log('cart asdasdasd getdata', getdata);

    const compare = useCallback(() => {
        let comparedata = getdata.filter((e) => e.id === id);
        setData(comparedata);
    }, [getdata, id]);

    const send = (e) => {
        dispatch(ADD(e));
    }

    const dlt = (id) => {
        dispatch(DLT(id));
        history("/cart");
    }

    const remove = (item) => {
        dispatch(REMOVE(item));
    }

    const total = useCallback(() => {
        let totalPrice = 0;
        getdata.forEach((ele) => {
            totalPrice += ele.price * ele.qnty;
        });
        setPrice(totalPrice);
    }, [getdata]);

    useEffect(() => {
        compare();
        total();
    }, [id, getdata, compare, total]);

    return (
        <>
            <section>
                <SectionHeader title='Cart' image={HeaderImage} PageLink='Cart'></SectionHeader>
                <div className="in-page-container pt-5 pb-5">
                    <div className="main__cart">
                        <Container>
                            <Row className='justify-content-end'>
                                <Col md={12}>
                                    <div className="inner__cart table-responsive">
                                        <Table bordered hover>
                                            <thead>
                                                <tr>
                                                    <th className="pd-btn"></th>
                                                    <th className="pt-image">Book Cover</th>
                                                    <th className="Product">Product Name</th>
                                                    <th className="pname-quan">Quantity</th>
                                                    <th className="pname-total">Total</th>
                                                </tr>
                                            </thead>
                                            {getdata.map((ele, index) => {
                                                // for image url set ***************** 
                                                let imagestwo = ele.imagestwo
                                                    .replace(/[\\[\]"]/g, '')
                                                    .replace('/storage/', '/storage/app/');
                                                const imageUrls = imagestwo.split(',');
                                                return (
                                                    <tbody key={index}>
                                                        <tr>
                                                            <td className='cart_body_details_dlt' onClick={() => dlt(ele.id)}>
                                                                <MdDelete />
                                                            </td>
                                                            <td className="cbook-image">
                                                                {ele.imagestwo ? (
                                                                    <Link to={`/SingleProduct/${ele.id}`}>
                                                                        <img src={imageUrls[0]} alt="" />
                                                                    </Link>
                                                                ) : (
                                                                    <img src={NoImage} alt="" />
                                                                )}
                                                            </td>

                                                            <td>
                                                                <p>
                                                                    <strong>{ele.slug}</strong>
                                                                </p>
                                                                {/* Displaying variation name */}
                                                                <span>
                                                                    {ele.variation}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <div className='cart-qnty'>
                                                                    <div className="cart-qua-btns">
                                                                        <button className="dec-btn blue-btn btn btn-primary" onClick={ele.qnty <= 1 ? () => dlt(ele.id) : () => remove(ele)}>-</button>
                                                                        <div><input type="text" readonly="" id="formQuantity" className="form-control" value={ele.qnty} /></div>
                                                                        <button className="dec-btn blue-btn btn btn-primary" onClick={() => send(ele)}>+</button>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="book-total-value">{ele.price}</td>
                                                        </tr>
                                                    </tbody>
                                                )
                                            }
                                            )}
                                        </Table>
                                    </div>
                                </Col>
                                <Col md={5}>
                                    <div className="total__tab_section">
                                        <div className="total__inner">
                                            <span>Total</span>
                                            <div className="total__price"><p>$ {price.toFixed(2)}</p></div>
                                        </div>
                                        <div className="total__textarea">
                                            <strong>Order instructions</strong>
                                            {/* <form className="mt-3">
                                                <div className="form-floating">
                                                    <textarea placeholder="Leave a comment here" id="floatingTextarea2" className="form-control" style={{ height: '150px' }}></textarea>
                                                    <label for="floatingTextarea2">Comments</label>
                                                </div>
                                            </form> */}
                                            <p>Tax included and shipping calculated at checkout</p>
                                            <div className="bottom__total">
                                                <Row>
                                                    <Col md={6}>
                                                        <Link className="blue-btn btn-style" to="/checkout">CheckOut</Link>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Link className="blue-btn btn-style" to="/shop">Continue Shopping</Link>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Cart;