import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import SectionHeader from '../../components/SectionHeader'

const Contact = () => {
    return (
        <>
            <section>
                <SectionHeader title='Contact Us' PageLink='Contact us'></SectionHeader>
                <div className="in-page-container pt-5 pb-5">
                    <div className="contact-section">

                        <Container>
                            <Row>
                                <Col md={6}>

                                    <div className="inner__contact">
                                        <form>
                                            <div className="mb-3">
                                                <input placeholder="Name" type="text" id="formName" className="form-control" />
                                            </div>
                                            <div className="mb-3">
                                                <input placeholder="Email" type="email" id="formEmail" className="form-control" />
                                            </div>
                                            <div className="mb-3">
                                                <input placeholder="Phone Number" type="text" id="formPhone" className="form-control" />
                                            </div>
                                            <div className="mb-3">
                                                <textarea placeholder="Comment" rows="4" id="formMessage" className="form-control"></textarea>
                                            </div>
                                            <button type="submit" className="blue-btn btn-style ">Submit</button>
                                        </form>
                                    </div>

                                </Col>
                                <Col md={6}>
                                    <div className="map__contact">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26544691.59357086!2d-116.97448852186452!3d35.693771799648786!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited%20States!5e0!3m2!1sen!2s!4v1693336126799!5m2!1sen!2s" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                    </div>
                                </Col>
                            </Row>

                        </Container>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Contact