import { FaStar } from "react-icons/fa";
import { IoMdStar } from "react-icons/io";
import { FaUser } from "react-icons/fa6";
import { IoCalendarOutline } from "react-icons/io5";

export const Products = [
    {
        id: 1,
        icon: <IoMdStar />,
        name: "Place your title here",
        price: "$120.00",
        discountPrice: "$99.00 ",
        review:'05 Review',
        image: require("./images/best-seller01.jpg")
    },
    {
        id: 2,
        icon: <IoMdStar />,
        name: "Place your title here",
        price: "$120.00",
        discountPrice: "$99.00 ",
        review:'05 Review',
        image: require("./images/best-seller02.jpg")
    },
    {
        id: 3,
        icon: <IoMdStar />,
        name: "Place your title here",
        price: "$120.00",
        discountPrice: "$99.00 ",
        review:'05 Review',
        image: require("./images/best-seller03.jpg")
    },
    {
        id: 4,
        icon: <IoMdStar />,
        name: "Place your title here",
        price: "$120.00",
        discountPrice: "$99.00 ",
        review:'05 Review',
        image: require("./images/best-seller04.jpg")
    },
    {
        id: 5,
        icon: <IoMdStar />,
        name: "Place your title here",
        price: "$120.00",
        discountPrice: "$99.00 ",
        review:'05 Review',
        image: require("./images/best-seller05.jpg")
    },
    {
        id: 6,
        icon: <IoMdStar />,
        name: "Place your title here",
        price: "$120.00",
        discountPrice: "$99.00 ",
        review:'05 Review',
        image: require("./images/best-seller06.png")
        // image: require("./images/best-seller06.jpg")
    },
    {
        id: 7,
        icon: <IoMdStar />,
        name: "Place your title here",
        price: "$120.00",
        discountPrice: "$99.00 ",
        review:'05 Review',
        image: require("./images/best-seller01.jpg")
    },
    {
        id: 8,
        icon: <IoMdStar />,
        name: "Place your title here",
        price: "$120.00",
        discountPrice: "$99.00 ",
        review:'05 Review',
        image: require("./images/best-seller08.png")
    },
    {
        id: 9,
        icon: <IoMdStar />,
        name: "Place your title here",
        price: "$120.00",
        discountPrice: "$99.00 ",
        review:'05 Review',
        image: require("./images/best-seller09.png")
    },
    {
        id: 10,
        icon: <IoMdStar />,
        name: "Place your title here",
        price: "$120.00",
        discountPrice: "$99.00 ",
        review:'05 Review',
        image: require("./images/best-seller10.png")
    },
    {
        id: 5,
        icon: <IoMdStar />,
        name: "Place your title here",
        price: "$120.00",
        discountPrice: "$99.00 ",
        review:'05 Review',
        image: require("./images/best-seller05.jpg")
    },
    {
        id: 6,
        icon: <IoMdStar />,
        name: "Place your title here",
        price: "$120.00",
        discountPrice: "$99.00 ",
        review:'05 Review',
        image: require("./images/best-seller06.png")
    },
]
export const shop = [
    {
        id: 1,
        icon: <FaStar />,
        name: "B",
        price: "$26.00",
        discountPrice: "$4.00",
        // image: require("./images/Layer-52.jpg")
    },
    {
        id: 2,
        icon: <FaStar />,
        name: "D",
        price: "$44.00",
        discountPrice: "$4.00",
        // image: require("./images/Layer-53.jpg")
    },
    {
        id: 3,
        icon: <FaStar />,
        name: "A",
        price: "$15.00",
        discountPrice: "$4.00",
        // image: require("./images/Layer-54.jpg")
    },
    {
        id: 4,
        icon: <FaStar />,
        name: "C",
        price: "$37.00",
        discountPrice: "$4.00",
        // image: require("./images/Layer-52.jpg")
    },
    {
        id: 5,
        icon: <FaStar />,
        name: "Ba",
        price: "$24.00",
        discountPrice: "$4.00",
        // image: require("./images/Layer-53.jpg")
    },
    
]

export const testimonial = [
    {
        id: 1,
        icon: <FaStar />,
        name: "Lorem Ipsum",
        description: "Some quick example text to build on the card title and make up the bulk of the card's content.",
        image: require("./images/testimonial.jpg")
    },
    {
        id: 2,
        icon: <FaStar />,
        name: "Lorem Ipsum",
        description: "Some quick example text to build on the card title and make up the bulk of the card's content.",
        image: require("./images/testimonial.jpg")
    },
    {
        id: 3,
        icon: <FaStar />,
        name: "Lorem Ipsum",
        description: "Some quick example text to build on the card title and make up the bulk of the card's content.",
        image: require("./images/testimonial.jpg")
    },
    {
        id: 4,
        icon: <FaStar />,
        name: "Lorem Ipsum",
        description: "Some quick example text to build on the card title and make up the bulk of the card's content.",
        image: require("./images/testimonial.jpg")
    },
    {
        id: 5,
        icon: <FaStar />,
        name: "Lorem Ipsum",
        description: "Some quick example text to build on the card title and make up the bulk of the card's content.",
        image: require("./images/testimonial.jpg")
    },
    {
        id: 6,
        icon: <FaStar />,
        name: "Lorem Ipsum",
        description: "Some quick example text to build on the card title and make up the bulk of the card's content.",
        image: require("./images/testimonial.jpg")
    },
    {
        id: 7,
        icon: <FaStar />,
        name: "Lorem Ipsum",
        description: "Some quick example text to build on the card title and make up the bulk of the card's content.",
        image: require("./images/testimonial.jpg")
    },
    {
        id: 8,
        icon: <FaStar />,
        name: "Lorem Ipsum",
        description: "Some quick example text to build on the card title and make up the bulk of the card's content.",
        image: require("./images/testimonial.jpg")
    },
    {
        id: 9,
        icon: <FaStar />,
        name: "Lorem Ipsum",
        description: "Some quick example text to build on the card title and make up the bulk of the card's content.",
        image: require("./images/testimonial.jpg")
    },
    {
        id: 10,
        icon: <FaStar />,
        name: "Lorem Ipsum",
        description: "Some quick example text to build on the card title and make up the bulk of the card's content.",
        image: require("./images/testimonial.jpg")
    },
    {
        id: 11,
        icon: <FaStar />,
        name: "Lorem Ipsum",
        description: "Some quick example text to build on the card title and make up the bulk of the card's content.",
        image: require("./images/testimonial.jpg")
    },
    {
        id: 12,
        icon: <FaStar />,
        name: "Lorem Ipsum",
        description: "Some quick example text to build on the card title and make up the bulk of the card's content.",
        image: require("./images/testimonial.jpg")
    }
]

export const blogs = [
    {
        id: 1,
        dateIcon: <IoCalendarOutline />,
        adminIcon: <FaUser />,
        description: "Lorem ipsum, dolor sit amet consectetur elit.",
        image: require("./images/blogs-img.jpg")
    },
    {
        id: 2,
        dateIcon: <IoCalendarOutline />,
        adminIcon: <FaUser />,
        description: "Lorem ipsum, dolor sit amet consectetur elit.",
        image: require("./images/blogs-img.jpg")
    },
    {
        id: 3,
        dateIcon: <IoCalendarOutline />,
        adminIcon: <FaUser />,
        description: "Lorem ipsum, dolor sit amet consectetur elit.",
        image: require("./images/blogs-img.jpg")
    },
    {
        id: 4,
        dateIcon: <IoCalendarOutline />,
        adminIcon: <FaUser />,
        description: "Lorem ipsum, dolor sit amet consectetur elit.",
        image: require("./images/blogs-img.jpg")
    },
    {
        id: 5,
        dateIcon: <IoCalendarOutline />,
        adminIcon: <FaUser />,
        description: "Lorem ipsum, dolor sit amet consectetur elit.",
        image: require("./images/blogs-img.jpg")
    },
    {
        id: 6,
        dateIcon: <IoCalendarOutline />,
        adminIcon: <FaUser />,
        description: "Lorem ipsum, dolor sit amet consectetur elit.",
        image: require("./images/blogs-img.jpg")
    },
    {
        id: 7,
        dateIcon: <IoCalendarOutline />,
        adminIcon: <FaUser />,
        description: "Lorem ipsum, dolor sit amet consectetur elit.",
        image: require("./images/blogs-img.jpg")
    },
    {
        id: 8,
        dateIcon: <IoCalendarOutline />,
        adminIcon: <FaUser />,
        description: "Lorem ipsum, dolor sit amet consectetur elit.",
        image: require("./images/blogs-img.jpg")
    },
    {
        id: 9,
        dateIcon: <IoCalendarOutline />,
        adminIcon: <FaUser />,
        description: "Lorem ipsum, dolor sit amet consectetur elit.",
        image: require("./images/blogs-img.jpg")
    },
    {
        id: 10,
        dateIcon: <IoCalendarOutline />,
        adminIcon: <FaUser />,
        description: "Lorem ipsum, dolor sit amet consectetur elit.",
        image: require("./images/blogs-img.jpg")
    },
    {
        id: 11,
        dateIcon: <IoCalendarOutline />,
        adminIcon: <FaUser />,
        description: "Lorem ipsum, dolor sit amet consectetur elit.",
        image: require("./images/blogs-img.jpg")
    },
    {
        id: 12,
        dateIcon: <IoCalendarOutline />,
        adminIcon: <FaUser />,
        description: "Lorem ipsum, dolor sit amet consectetur elit.",
        image: require("./images/blogs-img.jpg")
    }
]