import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { loginSuccess, loginFailure } from '../../Redux/actions/authActions';
import SectionHeader from '../../components/SectionHeader';

const Login = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleLogin = async (e) => {
    e.preventDefault();
    if (!formData.email || !formData.password) {
      Swal.fire('Please fill out all fields.');
      return;
    }
    try {
      let baseUrl = process.env.REACT_APP_BASE_URL;
      const response = await axios.post(
        baseUrl + '/api/login',
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        });
      const data = response.data;
      if (data.error) {
        dispatch(loginFailure(data.error));
        Swal.fire(data.error);
      } else {
        dispatch(loginSuccess(data.user));
        localStorage.setItem('user-info', JSON.stringify(data.user));
        Swal.fire('You have successfully login');
        navigate('/');
      }
    } catch (error) {
      console.error("Error:", error);
      dispatch(loginFailure('An error occurred. Please try again later.'));
    }};
  return (
    <>
      <section>
        <SectionHeader title='Login' PageLink='Login'></SectionHeader>
        <div className="login-form pt-5 pb-5">
          <Container>
            <Row className="justify-content-center">
              <Col xl={6} lg={8} md={10}>
                <form onSubmit={handleLogin}>
                  <div className="mb-3">
                    <label className="form-label" for="formBasicEmail">Email address</label>
                    <input placeholder="Enter Your Email" type="email" name="email" value={formData.email} onChange={handleChange} id="formBasicEmail" className="form-control" />
                  </div>
                  <div className="mb-3">
                    <label className="form-label" for="formBasicPassword">Password</label>
                    <input placeholder="Password" type="password" name="password" value={formData.password} onChange={handleChange} id="formBasicPassword" className="form-control" />
                  </div>
                  <button type="submit" className="blue-btn btn-style round-btn btn btn-link">Login</button>
                </form>
                <div className="or-option"><span>-- OR --</span><p>Click here to <Link to="/register">Create Your Account</Link></p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
};

export default Login;
