import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import SectionHeader from '../../components/SectionHeader'
import imageUrl from '../../images/bookcoverpro.png'


const OurStory = () => {

    return (
        <>
            <section>
                <SectionHeader title="Our Story" PageLink="Our Story"></SectionHeader>
                <div className="in-page-container pt-5 pb-5">
                    <div className="about-section top__about">
                        <Container>
                            <Row className='inner__about'>
                                <Col md={5}>
                                    <div className="about-img img-holder">
                                        <img
                                            src={imageUrl}
                                            alt="Hero Img"
                                        />
                                    </div>
                                </Col>
                                <Col className="col-md-6">
                                    <div class="about-text">
                                        <h2>Our Story</h2>
                                        <p>
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum commodi quisquam impedit. Officiis atque excepturi nihil ducimus suscipit recusandae odit. Fugiat molestiae molestias repellendus aspernatur ea exercitationem, odio autem cupiditate, obcaecati neque sint? A ipsum,
                                            <br /><br />
                                            alias repellendus dignissimos, aspernatur dolores facere, cupiditate adipisci eaque corporis Sit totam rem pariatur perferendis molestiae dolores! Corporis necessitatibus mollitia vitae, saepe eius quis, velit Sit totam rem pariatur perferendis molestiae dolores! Corporis necessitatibus mollitia vitae, saepe eius quis, velitvoluptates reiciendis? Voluptatibus vitae facilis saepe soluta illo, est necessitatibus! Repellendus saepe voluptates modi beatae necessitatibus.
                                            <br /><br />
                                            Sit totam rem pariatur perferendis molestiae dolores! Corporis necessitatibus mollitia vitae, saepe eius quis, velit, ullam laboriosam ut a consequuntur exercitationem harum rem aspernatur illo assumenda dicta tempore? Repellat saepe optio totam tenetur fuga ratione dolorem veritatis assumenda quod.
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </section>
        </>
    )
}

export default OurStory