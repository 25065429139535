import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom'

const SectionHeader = ({ title, image, PageLink }) => {
    useEffect(() => {
        AOS.init();
    }, []);
    return (
        <>
            <header className="inner-banner" data-aos="zoom-in-up" data-aos-duration="3500" data-aos-easing="linear">
                <div className="container">
                    <h1 className="ib-title" data-aos="fade-right" data-aos-duration="1000" data-aos-easing="linear">{title}</h1>
                    <ul className="breadcrumbs">
                        <li><Link to="/">Home</Link></li>
                        <li className="active-page">{PageLink}</li>
                    </ul>
                </div>
            </header>
            {/* <div className="header__container">
                    <div className="header__container-bg " data-aos="zoom-in-up" data-aos-duration="3500" data-aos-easing="linear">
                        <img src={image} alt="Header background" />
                    </div>
                    <div className="header__content container" data-aos="fade-right" data-aos-duration="1000" data-aos-easing="linear">
                        <h1>{title}</h1>
                        <span className='header_link'>
                            <NavLink to='/'>Home</NavLink>
                            /
                            <NavLink to='#' className='header_link_a'>{PageLink}</NavLink>
                        </span>
                    </div>
                </div> */}
        </>
    )
}

export default SectionHeader
