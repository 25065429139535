import axios from 'axios';

export const FETCH_SETTINGS_REQUEST = 'FETCH_SETTINGS_REQUEST';
export const FETCH_SETTINGS_SUCCESS = 'FETCH_SETTINGS_SUCCESS';
export const FETCH_SETTINGS_FAILURE = 'FETCH_SETTINGS_FAILURE';
export const FETCH_PRODUCT_REQUEST = 'FETCH_PRODUCT_REQUEST';
export const FETCH_PRODUCT_SUCCESS = 'FETCH_PRODUCT_SUCCESS';
export const FETCH_PRODUCT_FAILURE = 'FETCH_PRODUCT_FAILURE';

export const fetchSettings = () => {
  return async (dispatch) => {
    dispatch({ type: FETCH_SETTINGS_REQUEST });
    try {
      const response = await axios.get(process.env.REACT_APP_BASE_URL + '/api/theme-option');
      dispatch({
        type: FETCH_SETTINGS_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: FETCH_SETTINGS_FAILURE,
        payload: error.message
      });
    }
  };
};
export const fetchProductBy = () => {
  return async (dispatch) => {
    dispatch({ type: FETCH_PRODUCT_REQUEST });
    try {
      const response = await axios.get(process.env.REACT_APP_BASE_URL + '/api/all-trant');
      dispatch({
        type: FETCH_PRODUCT_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: FETCH_PRODUCT_FAILURE,
        payload: error.message
      });
    }
  };
};