import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Card, Table } from 'react-bootstrap'

const Addresses = () => {

    const [orders, setOrders] = useState([]);
    useEffect(() => {
        axios.get(process.env.REACT_APP_BASE_URL + '/api/orders')
            .then(response => {
                setOrders(response.data);
            })
            .catch(error => {
                console.error('Error fetching orders:', error);
            });
    }, []);

    return (
        <>
            <div className="dash-card about-bg">
                <Card className='dash-sub-card'>
                    <Card.Header as="h4" className="dash-card-header">My Addresses</Card.Header>
                    <Card.Body>
                        <Table striped bordered hover variant="light">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Address</th>
                                </tr>
                            </thead>
                            <tbody>
                            {orders.map(order => (
                                    <tr key={order.id}>
                                        <td>{order.id}</td>
                                        <td>{order.billing_address}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </div>
        </>
    )
}

export default Addresses