import {
  SINGLE_PRODUCT_REQUEST,
  SINGLE_PRODUCT_SUCCESS,
  SINGLE_PRODUCT_FAILURE,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAIL,
  ADD_PRODUCT_REQUEST,
  ADD_PRODUCT_SUCCESS,
  ADD_PRODUCT_FAILURE,
  PRODUCT_FETCH_REQUEST,
  PRODUCT_FETCH_SUCCESS,
  PRODUCT_FETCH_FAILURE,
  PRODUCT_VARIATION_REQUEST,
  PRODUCT_VARIATION_SUCCESS,
  PRODUCT_VARIATION_FAILURE
} from '../actions/productAction';

const initialState = {
  product: [],
  productStore: [],
  variation: [],
  variationLoading: false,
  variationError: null,
  loading: false,
  error: null,
  success: false,
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {

    // Handle single product fetch
    case SINGLE_PRODUCT_REQUEST:
      return { ...state, loading: true, error: null };
    case SINGLE_PRODUCT_SUCCESS:
      return { ...state, loading: false, product: action.payload };
    case SINGLE_PRODUCT_FAILURE:
      return { ...state, loading: false, error: action.payload };

    // Handle product update
    case UPDATE_PRODUCT_REQUEST:
      return { ...state, loading: true, error: null };
    case UPDATE_PRODUCT_SUCCESS:
      return { ...state, loading: false, success: true };
    case UPDATE_PRODUCT_FAIL:
      return { ...state, loading: false, error: action.payload };

    // Handle product store requests
    case ADD_PRODUCT_REQUEST:
      return { ...state, loading: true, error: null, success: false };
    case ADD_PRODUCT_SUCCESS:
      return { ...state, loading: false, product: action.payload, success: true };
    case ADD_PRODUCT_FAILURE:
      return { ...state, loading: false, error: action.payload, success: false };
    // Handle product detch requests
    case PRODUCT_FETCH_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case PRODUCT_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        product: action.payload || []
      };
    case PRODUCT_FETCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    // Handle product variation requests
    case PRODUCT_VARIATION_REQUEST:
      return {
        ...state,
        variationLoading: true,
        variationError: null
      };
    case PRODUCT_VARIATION_SUCCESS:
      return {
        ...state,
        variationLoading: false,
        variation: action.payload.data || []
      };
    case PRODUCT_VARIATION_FAILURE:
      return {
        ...state,
        variationLoading: false,
        variationError: action.payload,
        variation: []
      };

    default:
      return state;
  }
};

export default productReducer;
