import React from 'react'
import Menu from '@mui/material/Menu';
import { Col, Row, Table } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import NoImage from '../images/NoImage.jpg'
import { MdDelete } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import { MdRemoveShoppingCart } from "react-icons/md";


const CartBody = ({ anchorEl, open, handleClose, getdata, dlt, price }) => {
    return (
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            {getdata.length ? (
                <div className='cart_body_details'>
                    <Table>
                        <thead>
                            <tr>
                                <th>Photo</th>
                                <th>Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                getdata.map((e) => {
                                    const cleanSlug = e.slug.replace(/-/g, " ");
                                    // for image url set ***************** 
                                    let imagestwo = e.imagestwo
                                        .replace(/[\\[\]"]/g, '')
                                        .replace('/storage/', '/storage/app/');
                                    const imageUrls = imagestwo.split(',');
                                    return (
                                        <>
                                            <tr>
                                                <td>
                                                    {e.imagestwo ? (
                                                        <NavLink to={`/single-product/${e.id}`}>
                                                            <img src={imageUrls[0]} className="cart_body_details_img" alt="Product img" />
                                                        </NavLink>
                                                    ) : (
                                                        <NavLink to={`/single-product/${e.id}`}>
                                                            <img src={NoImage} className="cart_body_details_img" alt="Product img" />
                                                        </NavLink>
                                                    )}
                                                </td>
                                                <td>
                                                    <p>{cleanSlug}</p>
                                                    <p>Price : ${Number(e.price).toFixed(2)}</p>
                                                    <p>Quantity : {e.qnty}</p>
                                                </td>

                                                <td className='cart_body_details_dlt' onClick={() => dlt(e.id)}>
                                                    <MdDelete />
                                                </td>
                                            </tr>
                                        </>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                    <Row className='align-items-center'>
                        <Col lg='4' md={12} sm={12}>
                            <strong>Total :$ {price}</strong>
                        </Col>
                        <Col lg='3' md={6} sm={6} xs={6}>
                            <Link className="blue-btn btn-style" to="/cart">Cart</Link>
                        </Col>
                        <Col lg='5' md={6} sm={6} xs={6}>
                            <Link className="blue-btn btn-style" to="/checkOut">Check Out</Link>
                        </Col>
                    </Row>
                </div>
            ) : (
                <div className='cart_body'>
                    <i
                        onClick={handleClose}
                        style={{ position: "absolute", top: 2, right: 20, fontSize: 23, cursor: "pointer" }}><IoClose /></i>
                    <p>Your carts is empty</p>
                    <div className="emptycart_img">
                        <MdRemoveShoppingCart />
                    </div>
                </div>
            )}
        </Menu>
    );
};

export default CartBody;
