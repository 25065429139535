import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import SectionHeader from '../../components/SectionHeader';
import NoImage from "../../images/NoImage.jpg";
import HeaderImage from '../../images/section-herder-img.jpg';
import { Card, Container } from 'react-bootstrap';
import { FaUser } from "react-icons/fa6";
import { IoCalendarOutline } from "react-icons/io5";
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';

const Blogs = () => {
    const [blog, setBlog] = useState([]);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const blogType = queryParams.get('type');

    let baseUrl = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        axios.get(baseUrl + '/api/blog-show')
            .then(response => {
                if (blogType) {
                    const filteredBlogs = response.data.filter(b => b.type === blogType);
                    setBlog(filteredBlogs);
                } else {
                    setBlog(response.data);
                }
            })
            .catch(error => {
                console.error('There was an error fetching the data!', error);
            });
    }, [blogType, baseUrl]);

    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <>
            <SectionHeader title='Blogs' image={HeaderImage} PageLink='Blogs' />
            <section className="blog-bg">
                <Container className='blog-post'>
                    <div className="blog-posts" data-aos="zoom-in-up" data-aos-duration="2000" data-aos-easing="linear">
                        {
                            blog.map(({ id, image, type, date, title, description }) => {
                                return (
                                    <Card className='blog_card' key={id}>
                                        {image ? (
                                            <Card.Img className="blog_card_img" variant="top"
                                                src={`${baseUrl}/public/${image}`}
                                            />
                                        ) : (
                                            <Card.Img className="blog_card_img" variant="top" src={NoImage} alt="Product img" />
                                        )}
                                        <Card.Body className='blog_text'>
                                            <div className="date_admin">
                                                <Link to="#">
                                                    <p><span><IoCalendarOutline /></span>{date}</p>
                                                </Link>
                                                <Link to="#">
                                                    <p><span><FaUser /></span> Admin</p>
                                                </Link>
                                            </div>
                                            <p className="bolg_text">
                                                <h4>{type}</h4>
                                                <h4>{title}</h4>
                                                <div dangerouslySetInnerHTML={{ __html: description }} />
                                            </p>
                                        </Card.Body>
                                    </Card>
                                )
                            })
                        }
                    </div>
                </Container>
            </section>
        </>
    )
}

export default Blogs;
