import { FETCH_SETTINGS_REQUEST, FETCH_SETTINGS_SUCCESS, FETCH_SETTINGS_FAILURE ,FETCH_PRODUCT_REQUEST , FETCH_PRODUCT_SUCCESS, FETCH_PRODUCT_FAILURE} from '../actions/settingsActions';
const initialState = {
  settings: [],
  products: [],
  loading: false,
  error: null,
};
export const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SETTINGS_REQUEST:
    case FETCH_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        settings: action.payload,
      };
      case FETCH_PRODUCT_SUCCESS:
        return {
          ...state,
          loading: false,
          products: action.payload,
      };
    case FETCH_SETTINGS_FAILURE:
    case FETCH_PRODUCT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
export default settingsReducer;






// import { FETCH_SETTINGS_REQUEST, FETCH_SETTINGS_SUCCESS, FETCH_SETTINGS_FAILURE } from '../actions/settingsActions';

// const initialState = {
//   settings: [],
//   loading: false,
//   error: null
// };

// const settingsReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case FETCH_SETTINGS_REQUEST:
//       return {
//         ...state,
//         loading: true,
//         error: null
//       };
//     case FETCH_SETTINGS_SUCCESS:
//       return {
//         ...state,
//         loading: false,
//         settings: action.payload
//       };
//     case FETCH_SETTINGS_FAILURE:
//       return {
//         ...state,
//         loading: false,
//         error: action.payload
//       };
//     default:
//       return state;
//   }
// };

// export default settingsReducer;
