import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import Loading from '../../components/Loading';

const AccountSetting = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        newPassword: '',
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [userId, setUserId] = useState(null);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.id) {
            setUserId(user.id);
            setFormData({ name: user.name, email: user.email });            
        } else {
            setError('User not found or not logged in.');
        }
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setSuccess('');

        if (!userId) {
            setError('User ID is undefined');
            setLoading(false);
            return;
        }

        try {
            let baseUrl = process.env.REACT_APP_BASE_URL;
            const response = await axios.put(`${baseUrl}/api/update-user/${userId}`, formData);
            setSuccess('User updated successfully!');
            console.log('Response:', response.data);
        } catch (err) {
            setError('Failed to update user. Please try again.');
            console.error('Error:', err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div className="dash-card about-bg">
                <Card className='dash-sub-card'>
                    <Card.Header as="h4" className="dash-card-header">Account Setting</Card.Header>
                    <Card.Body>
                        {loading && <Loading />}
                        {error && <div className="alert alert-danger">{error}</div>}
                        {success && <div className="alert alert-success">{success}</div>}
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <input
                                    placeholder="Enter Your Name"
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <input
                                    placeholder="Enter Your Email"
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className="form-control"
                                />
                            </div>
                            <div className="mb-3">
                                <input
                                    placeholder="New Password"
                                    type="password"
                                    name="newPassword"
                                    value={formData.newPassword}
                                    onChange={handleChange}
                                    className="form-control"
                                />
                            </div>
                            <button
                                type="submit"
                                className="blue-btn btn-style round-btn btn btn-link"
                                disabled={loading}
                            >
                                Update Account
                            </button>
                        </form>
                    </Card.Body>
                </Card>
            </div>
        </>
    );
};

export default AccountSetting;
