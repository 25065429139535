import React, { useEffect, useState } from 'react'
import SectionHeader from '../../components/SectionHeader'
import { Badge, Container, Table } from 'react-bootstrap'
import Loading from '../../components/Loading';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { fetchProductShow } from '../../Redux/actions/productAction';

const PublisherDashboard = () => {
    const [showProduct, setShowProduct] = useState([]);
    const { loading, product } = useSelector((state) => state.product);
    const navigate = useNavigate();
    const dispatch = useDispatch();


    useEffect(() => {
        const isLoggedIn = localStorage.getItem('user');
        if (!isLoggedIn) {
            navigate('/login');
        }
    }, [navigate]);

    useEffect(() => {
        dispatch(fetchProductShow());
    }, [dispatch]);

    useEffect(() => {
        const loggedInUser = JSON.parse(localStorage.getItem('user'));
        const userId = loggedInUser ? loggedInUser.id : null;

        if (Array.isArray(product) && userId) {
            const userProducts = product.filter(prod => prod.user_id === userId);
            setShowProduct(userProducts);
        }
    }, [product]);
    return (
        <>
            <section>
                <SectionHeader title="Publisher Dashboard" PageLink="Publisher Dashboard"></SectionHeader>
                <Container>
                    <div className=" pt-5 pb-5 mx-auto" >
                        {loading && <Loading />}

                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Image</th>
                                    <th>Product Name</th>
                                    <th>Description</th>
                                    <th>Category</th>
                                    <th>Price</th>
                                    <th>State</th>
                                    <th>Edit/Update</th>
                                </tr>
                            </thead>
                            <tbody>
                                {showProduct.length === 0 ? (
                                    <tr>
                                        <td colSpan={6} className="text-center">No products available.</td>
                                    </tr>
                                ) : (
                                    showProduct.map((product, index) => (
                                        <tr key={product.id}>
                                            <td>{index + 1}</td>
                                            <td>
                                                <img src={`${process.env.REACT_APP_BASE_URL}/storage/app/${product.imagestwo}`} alt={product.name} style={{ width: '50px', height: '50px' }} />
                                            </td>
                                            <td>{product.slug}</td>
                                            <td>{product.description}</td>
                                            <td>{product.category}</td>
                                            <td>{product.price}</td>
                                            <td>
                                                {product.is_active === 0 ? (
                                                    <Badge bg="danger">Inactive</Badge>
                                                ) : (
                                                    <Badge bg="success">Active</Badge>
                                                )}
                                            </td>
                                            <td>
                                                <Link to={(`/author-tools-resources/${product.id}`)} className="btn btn-warning">Edit</Link>
                                                {/* <button onClick={() => navigate(`author-tools-resources/${product.id}`)} className="btn btn-warning">Edit</button> */}
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </Table>
                    </div>
                </Container>
            </section >
        </>
    )
}

export default PublisherDashboard