import { SORT_BY_PRICE, SET_ITEMS_PER_PAGE, SELECT_CATEGORY  } from '../actions/filterActions';

const initialState = {
  sortBy: '',
  itemsPerPage: 30,
  selectedCategory: '' 
};

const filterReducers = (state = initialState, action) => {
  switch (action.type) {
    case SORT_BY_PRICE:
      return {
        ...state,
        sortBy: action.payload
      }; 
    case SET_ITEMS_PER_PAGE:
      return {
        ...state,
        itemsPerPage: action.payload
      };
      case SELECT_CATEGORY:
      return {
        ...state,
        selectedCategory: action.payload
      };
    default:
      return state;
  }
};

export default filterReducers;