import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Slider from "react-slick";
// import AOS from 'aos';
import 'aos/dist/aos.css';
import SectionHeader from '../../components/SectionHeader'
import HeaderImage from '../../images/section-herder-img.jpg'
import { Col, Container, Nav, Row, Tab, Table } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
// import { TbTruckDelivery } from "react-icons/tb";
// import { IoIosPricetag } from "react-icons/io";
import axios from 'axios';
import Loading from '../../components/Loading';
import { useDispatch, useSelector } from 'react-redux';
import { ADD } from '../../Redux/actions/CartAction';
import { variationById } from '../../Redux/actions/productAction';
import { Rating } from 'react-simple-star-rating'
import { Button } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProductReview from '../../UI/ProductReview';
// import { FaStar } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaCircleInfo } from "react-icons/fa6";
import { FaTruck } from "react-icons/fa";

const SingleProduct = () => {
    // const [count, setCount] = useState(1);
    const [product, setProduct] = useState(null);
    const [images, setImages] = useState([]);
    const [selectedVariation, setSelectedVariation] = useState(null);
    const [rating, setRating] = useState(0)
    const [comment, setComment] = useState('');
    const dispatch = useDispatch();
    const { id } = useParams();
    const variation = useSelector(state => state.variation.variation || []);
    const userId = useSelector(state => state.auth.userId);
    const CartNotification = () => toast.success("Product Has Been Updated");
    const handleRating = (rate) => {
        setRating(rate)
    }
    const isUserLoggedIn = !!userId;
    const handleCommentChange = (event) => {
        setComment(event.target.value);
    };

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                let baseUrl = process.env.REACT_APP_BASE_URL;
                const response = await axios.get(baseUrl + `/api/single-product/${id}`);
                if (response.data && response.data.status === "200") {
                    setProduct(response.data.data);
                    const cleanedUrl = response.data.data.imagestwo
                        .replace(/[\\[\]"]/g, '')
                        .split(',')
                        .map(url =>
                            url.includes('/storage/app/')
                                ? url.trim()
                                : url.replace('/storage/', '/storage/app/')
                        );
                    setImages(cleanedUrl);
                } else {
                    console.error('Error fetching product:', response.data.status);
                }
            } catch (error) {
                console.error('Error fetching product:', error);
            }
        };
        fetchProduct();
    }, [id]);
    const settings = {
        customPaging: function (i) {
            return (
                <Link className='small__images__bottom'>
                    <img src={images[i]} alt={`Thumbnail ${i + 1}`} />
                </Link>
            );
        },
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    };

    // variation start **************
    useEffect(() => {
        dispatch(variationById(id));
    }, [dispatch, id]);
    useEffect(() => {
        if (variation.length > 0) {
            setSelectedVariation(variation[0]);
        }
    }, [variation]);
    const handleVariationClick = (v) => {
        setSelectedVariation(v);
    };
    // variation END **************
    const handleSubmitReview = async (event) => {
        event.preventDefault();
        try {
            let baseUrl = process.env.REACT_APP_BASE_URL;
            const response = await axios.post(baseUrl + '/api/product-reviews', {
                user_id: userId,
                product_id: product.id,
                rating: rating,
                comment: comment,
                is_approved: 0
            });
            toast.success('Review submitted successfully');
        } catch (error) {
            if (error.response && error.response.status === 400) {
                toast.error('You have already submitted a review for this product.');
            } else {
                console.error('Error submitting review:', error);
            }
        }
    };

    // const handleChange = (event) => {
    //     const newValue = parseInt(event.target.value);
    //     setCount(isNaN(newValue) ? 0 : newValue);
    // };

    // const increment = () => {
    //     setCount(prevCount => prevCount + 1);
    // };

    // const decrement = () => {
    //     setCount(prevCount => (prevCount > 0 ? prevCount - 1 : prevCount));
    // };

    // const handleAddToCart = (event) => {
    //     for (let i = 0; i < count; i++) {
    //         dispatch(ADD(product));
    //     }
    //     CartNotification();
    // };

    const handleAddToCart = () => {
        let itemToAdd = { ...product };
        if (product.product_type === 'variable_product' && selectedVariation) {
            itemToAdd = {
                ...itemToAdd,
                price: selectedVariation.variation.name,
                variation: selectedVariation.name
            };
        }
        dispatch(ADD(itemToAdd));
        CartNotification();
    };
    if (!product) {
        return <div><Loading /></div>;
    }
    const formattedTitle = product.slug.replace(/-/g, ' ');
    return (
        <>
            <SectionHeader title='Product Details' image={HeaderImage} PageLink='Product Details'></SectionHeader>
            <section className="product-bg">
                <div className="in-page-container pt-4 pb-4">
                    <div className="product__detail__top">
                        <Container>
                            <Row>
                                <Col lg={5} md={6} sm={12}>
                                    <div className="product__section__img">
                                        <div className="thumbnail__image">
                                            {images.length > 0 && (
                                                <Slider {...settings}>
                                                    {images.map((img, index) => (
                                                        <div key={index} className='product__image feaut-img'>
                                                            <img src={img} alt={`Best Seller ${index + 1}`} />
                                                        </div>
                                                    ))}
                                                </Slider>
                                            )}
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={7} md={6} sm={12}>
                                    <div className="product__Section__text">
                                        <div class="ppbook-title mb-3">
                                            <h2>{formattedTitle}</h2>
                                            {/* <span>by <Link to="/">O. J. Mullen</Link></span> */}
                                        </div>
                                    </div>
                                    <div className="rating__pro__section mb-3">
                                        <ProductReview />
                                        {/* <ul className='rating__pro'>
                                            <FaStar />
                                            <FaStar />
                                            <FaStar />
                                            <FaStar />
                                            <FaStar />
                                        </ul>
                                        <div className="anchor">
                                            <Link to="#">2 reviews</Link>
                                        </div> */}
                                    </div>
                                    <div className="bppbook-type mb-4">
                                        {variation.length > 0 ? (
                                            <>
                                                {selectedVariation ? (
                                                    <>
                                                        <span>{selectedVariation.name}</span>
                                                        <h3>${selectedVariation.variation ? selectedVariation.variation.name : 'N/A'}</h3>
                                                    </>
                                                ) : (
                                                    <p>Please select a variation.</p>
                                                )}
                                                <Row>
                                                    {variation.map(v => (
                                                        <Col key={v.id} onClick={() => handleVariationClick(v)} style={{ cursor: 'pointer' }}>
                                                            <div className={`length-boxes ${selectedVariation && selectedVariation.id === v.id ? 'active' : ''}`}>
                                                                <strong>{v.name}</strong>
                                                                <p>{v.variation ? v.variation.name : 'N/A'}</p>
                                                            </div>
                                                        </Col>
                                                    ))}
                                                </Row>
                                            </>
                                        ) : (
                                            <>
                                                <span>Hardcover</span>
                                                <h3>${product.price}</h3>
                                            </>
                                        )}
                                    </div>
                                    <div class="imp-note mb-3">
                                        <p>Premium Members get an additional <strong>10% off</strong> AND collect stamps to save with <strong>Rewards.</strong> 10 stamps = $5 reward <Link to='#'>Learn more</Link></p>
                                    </div>
                                    <div className="product__social__icons">
                                        <strong>Share</strong>
                                        <div className="main__product__icons">
                                            <ul>
                                                <li><Link to="#"><FaFacebookF /></Link></li>
                                                <li><Link to="#"><FaInstagramSquare /></Link></li>
                                                <li><Link to="#"><FaTwitter /></Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="ship-ob">
                                        <Row className='align-items-center'>
                                            <div class="col-md-8">
                                                <div class="content-side">
                                                    <h6>SHIP THIS ITEM</h6>
                                                    <p>Qualifies for Free Shipping
                                                        <OverlayTrigger overlay={<Tooltip ><strong>X Y Z</strong></Tooltip>}>
                                                            <span variant="secondary"> <FaCircleInfo /></span>
                                                        </OverlayTrigger>
                                                    </p>
                                                    <ul>
                                                        <li>
                                                            <FaTruck />
                                                        </li>
                                                        <li>
                                                            <p>Choose Expedited Shipping at checkout for delivery by <strong>Monday, October 2</strong></p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <Col md={4}>
                                                <div class="btn-side">
                                                    <button>
                                                        <Link class="btn-style blue-btn" onClick={handleAddToCart}>Add To Cart</Link>
                                                        <ToastContainer />
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                <div className="description__Section">
                    <Container>
                        <Row>
                            <Col md={12}>
                                <Tab.Container defaultActiveKey="first">
                                    <Nav className='nav-tabs'>
                                        <Nav.Item>
                                            <Nav.Link eventKey="first">Overview</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="second">Product Details</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="third">Customer Reviews</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="first">
                                            <div className="pp-tab-content pp-content-pad">
                                                <div dangerouslySetInnerHTML={{ __html: product.description }} />
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="second">
                                            <div className="pp-content-pad">
                                                <Table striped bordered hover>
                                                    <tbody>
                                                        <tr>
                                                            <th>ISBN-13:</th>
                                                            <td>9781837515011</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Publisher:</th>
                                                            <td>Boldwood Books Ltd</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Publication date:</th>
                                                            <td>07/19/2023</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Pages:</th>
                                                            <td>344</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Product dimensions:</th>
                                                            <td>5.06(w) x 7.81(h) x 0.86(d)</td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="third">
                                            <div className="pp-content-pad ratings-tab">
                                                <Row>

                                                    <Col md={12}>
                                                        <div className='tab_review'>
                                                            <ProductReview />
                                                        </div>
                                                    </Col>
                                                    <Col md={12}>
                                                        <div className='review_main'>
                                                            <strong>Add A Review</strong>
                                                            <Row className="single_product_review">
                                                                <Col lg={12}>
                                                                    <div className='product_rating'>
                                                                        {!userId && (
                                                                            <p>You need to login for writing a review. <Link to='/login' className="btn-link">Login to your account</Link></p>
                                                                        )}
                                                                        <label>Your Rating <span className='red_star'> *</span></label>
                                                                        <Rating
                                                                            onClick={handleRating}
                                                                            ratingValue={rating}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col lg={12}>
                                                                    <div className="product_rating">
                                                                        <label>Write a comment <span className='red_star'> *</span></label>
                                                                        <textarea name="comment" rows="6" value={comment} onChange={handleCommentChange} required disabled={!isUserLoggedIn} />
                                                                    </div>
                                                                </Col>
                                                                <Col lg={12}>
                                                                    <Button className='mt-3 btn-style blue-btn' onClick={handleSubmitReview} disabled={!isUserLoggedIn}>
                                                                        Submit
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section >
        </>
    )
}

export default SingleProduct