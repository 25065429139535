import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import HeaderImage from '../../images/section-herder-img.jpg'
import Img1 from '../../images/technical-support.png';

import { Link } from 'react-router-dom';
import SectionHeader from '../../components/SectionHeader';

const TechnicalSupport = () => {
    useEffect(() => {
        AOS.init();
    }, []);
    return (
        <>
            <SectionHeader title='Technical Support' image={HeaderImage} PageLink='Technical Support'></SectionHeader>
            <section className='technical-support'>
                <div className="container">
                    <div className="book-cat">
                        <div className="bc-box" data-aos="zoom-in-up" data-aos-duration="1000" data-aos-easing="linear">
                            <div className="bc-image">
                                <Link to="#">
                                    <img src={Img1} alt="image" />
                                </Link>
                            </div>
                            <div className="bc-content">
                                <h3><Link to="#">Lorem</Link></h3>
                            </div>
                        </div>
                        <div className="bc-box" data-aos="zoom-in-up" data-aos-duration="1000" data-aos-easing="linear">
                            <div className="bc-image">
                                <Link to="#">
                                    <img src={Img1} alt="image" />
                                </Link>
                            </div>
                            <div className="bc-content">
                                <h3><Link to="#">Lorem</Link></h3>
                            </div>
                        </div>
                        <div className="bc-box" data-aos="zoom-in-up" data-aos-duration="1000" data-aos-easing="linear">
                            <div className="bc-image">
                                <Link to="#">
                                    <img src={Img1} alt="image" />
                                </Link>
                            </div>
                            <div className="bc-content">
                                <h3><Link to="#">Lorem</Link></h3>
                            </div>
                        </div>
                        <div className="bc-box" data-aos="zoom-in-up" data-aos-duration="1000" data-aos-easing="linear">
                            <div className="bc-image">
                                <Link to="#">
                                    <img src={Img1} alt="image" />
                                </Link>
                            </div>
                            <div className="bc-content">
                                <h3><Link to="#">Lorem</Link></h3>
                            </div>
                        </div>
                        <div className="bc-box" data-aos="zoom-in-up" data-aos-duration="1000" data-aos-easing="linear">
                            <div className="bc-image">
                                <Link to="#">
                                    <img src={Img1} alt="image" />
                                </Link>
                            </div>
                            <div className="bc-content">
                                <h3><Link to="#">Lorem</Link></h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default TechnicalSupport