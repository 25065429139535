import React, { useEffect, useState } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Slider from 'react-slick';
import { Container } from 'react-bootstrap';
import { FaStar } from "react-icons/fa";
import Img from '../images/men.png'
import Img2 from '../images/men1.png'
import Img3 from '../images/men3.png'
import NoImage from "../images/NoImage.jpg"
import { useDispatch, useSelector } from 'react-redux';
import { fetchSettings } from '../Redux/actions/settingsActions';
import axios from 'axios';

const Testimonials = () => {
    // ******* START API  ******* 
    const [testimonial, setTestimonial] = useState([]);
    let baseUrl = process.env.REACT_APP_BASE_URL;
    let imgbaseUrl = baseUrl + "/public/";
    useEffect(() => {
        axios.get(baseUrl + '/api/testimonials-show')
            .then(response => {
                setTestimonial(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the data!', error);
            });
    }, []);
    useEffect(() => {
        AOS.init();
    }, []);
    // ******* END API  ******* 
    // ******* CMS START ******* 
    const dispatch = useDispatch();
    const { settings, loading, error } = useSelector(state => state.settings);
    useEffect(() => {
        dispatch(fetchSettings());
    }, [dispatch]);
    const section_heading_4 = settings.find(setting => setting.name === 'section_heading_4');
    // ******* CMS END ******* 
    var setting = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,

                }
            }
        ]
    }
    return (
        <>
            <section>
                <div className="clients-rev">
                    <Container>
                        <div className="head__reviews">
                            <h2 className="head-sec">{section_heading_4?.value}</h2>
                            <Slider {...setting}>
                                {testimonial.map(({ id, title, image, description }) => {
                                    return (
                                        <div className="top-reviews" key={id}>
                                            <div className="main__reviews">
                                                {image ? (
                                                    <img
                                                        src={`${imgbaseUrl}${image}`}
                                                    alt='testimonial img' />
                                                ) : (
                                                    <img src={NoImage} alt="img" />
                                                )}
                                            </div>
                                            <div className="box__back">
                                                <div className="reviews__stars">
                                                    <ul>
                                                        <li><FaStar /></li>
                                                        <li><FaStar /></li>
                                                        <li><FaStar /></li>
                                                        <li><FaStar /></li>
                                                        <li><FaStar /></li>
                                                    </ul>
                                                </div>
                                                <div className="reviews__text">
                                                    <div dangerouslySetInnerHTML={{ __html: description }} />
                                                    <strong>{title}</strong>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                }
                            </Slider>
                        </div>
                    </Container>
                </div>
            </section>
        </>
    )
}

export default Testimonials