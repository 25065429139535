import React, { useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import Paypal from '../images/paypal.png'
import Visa from '../images/visa.png'
import Round from '../images/round.png'
import Discover from '../images/discover.png'
import Vector from '../images/Vector.png'
import { FaFacebookSquare, FaInstagramSquare } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSettings } from '../Redux/actions/settingsActions';


const Footer = () => {


  // ************ CMS START************
  const dispatch = useDispatch();
  const { settings } = useSelector(state => state.settings);
  useEffect(() => {
    dispatch(fetchSettings());
  }, [dispatch]);
  const footer_title = settings.find(setting => setting.name === 'footer_title');
  const footer_text = settings.find(setting => setting.name === 'footer_text');
  const Address_text = settings.find(setting => setting.name === 'Address_text');
  const Address_link = settings.find(setting => setting.name === 'Address_link');
  const phone_text = settings.find(setting => setting.name === 'phone_text');
  const phone_link = settings.find(setting => setting.name === 'phone_link');
  const email_text = settings.find(setting => setting.name === 'email_text');
  const email_link = settings.find(setting => setting.name === 'email_link');
  const Copyright = settings.find(setting => setting.name === 'Copyright');
  const footer_logo = settings.find(setting => setting.name === 'footer_logo');
  const baseImageUrl = process.env.REACT_APP_BASE_URL + '/public/uploads/media/';
  const Logo = footer_logo?.value ? `${baseImageUrl}${footer_logo.value}` : '';
  const fb_link = settings.find(setting => setting.name === 'fb_link');
  const in_link = settings.find(setting => setting.name === 'in_link');
  // ************ CMS END************

  return (
    <>
      <footer>
        <div className="main__footer">
          <div className="top-footer">
            <Container>
              <div className="inner__footer">
                <Row className='justify-content-center'>
                  <Col lg={5} md={6} xs={12}>
                    <h2>{footer_title?.value}</h2>
                    <strong>{footer_text?.value}</strong>
                  </Col>
                  <Col lg={6} md={6} xs={12}>
                    <div className="form_sec">
                      <form>
                        <div className="mb-3 input-group">
                          <input placeholder="Your Email" className="form-control" />
                          <button type="button" className="blue-btn btn-style btn">Subscribe</button>
                        </div>
                      </form>
                    </div>
                  </Col>
                </Row>
                <div className="footer__content">
                  <Row className='justify-content-center'>
                    <Col lg={2} md={2} sm={4} xs={6}>
                      <div className="footer__content__text">
                        <strong> Quick Links</strong>
                        <ul>
                          <li><Link to="/about"><MdOutlineKeyboardArrowRight /> About Us</Link></li>
                          <li><Link to="/contact"><MdOutlineKeyboardArrowRight /> Contact Us</Link></li>
                          <li><Link to="/testimonials"><MdOutlineKeyboardArrowRight /> Testimonials</Link></li>
                          <li><Link to="/wishlist"><MdOutlineKeyboardArrowRight /> Wishlist</Link></li>
                        </ul>
                      </div>
                    </Col>
                    <Col lg={2} md={2} sm={4} xs={6}>
                      <div className="footer__content__text">
                        <strong> Pages</strong>
                        <ul>
                          <li><Link to="/cart"><MdOutlineKeyboardArrowRight /> Cart</Link></li>
                          <li><Link to="/checkout"><MdOutlineKeyboardArrowRight /> Checkout</Link></li>
                          <li><Link to="/login"><MdOutlineKeyboardArrowRight /> Login</Link></li>
                          <li><Link to="/register"><MdOutlineKeyboardArrowRight /> Register</Link></li>
                        </ul>
                      </div>
                    </Col>
                    <Col lg={2} md={2} sm={4} xs={6}>
                      <div className="footer__content__text">
                        <strong>Information</strong>
                        <ul>
                          <li><Link to="#"><MdOutlineKeyboardArrowRight /> Lorem Ipsum Dolor</Link></li>
                          <li><Link to="#"><MdOutlineKeyboardArrowRight /> Lorem Ipsum Dolor</Link></li>
                          <li><Link to="#"><MdOutlineKeyboardArrowRight /> Lorem Ipsum Dolor</Link></li>
                          <li><Link to="#"><MdOutlineKeyboardArrowRight /> Lorem Ipsum Dolor</Link></li>
                        </ul>
                      </div>
                    </Col>
                    <Col lg={2} md={2} sm={4} xs={6}>
                      <div className="footer__content__text">
                        <strong>Other Links</strong>
                        <ul>
                          <li><Link to="/privacy"><MdOutlineKeyboardArrowRight /> Privacy Policy</Link></li>
                          <li><Link to="/refund"><MdOutlineKeyboardArrowRight /> Refund Policy</Link></li>
                          <li><Link to="/termsConditions"><MdOutlineKeyboardArrowRight /> Terms & Conditions</Link></li>
                        </ul>
                      </div>
                    </Col>
                    <Col lg={3} md={4} sm={8} xs={12}>
                      <div className="footer__content__text">
                        <strong>Contact Information</strong>
                        <ul>
                          <li><Link to={Address_link?.value}>Address: {Address_text?.value}</Link></li>
                          <li><Link to={phone_link?.value}>Phones: {phone_link?.value}</Link></li>
                          <li><Link to={email_link?.value}>E-mail: {email_text?.value}</Link></li>
                        </ul>
                      </div>
                    </Col>

                  </Row>
                </div>
              </div>
            </Container>
          </div>
          <div className="footer__bottom">
            <Container>
              <Row className="justify-content-center align-items-center">
                <Col lg={3} md={3} sm={4} xs={12}>
                  <div className="footer__icons">
                    <Link to={fb_link?.value} target="_blank"><FaFacebookSquare /></Link>
                    <Link to={in_link?.value} target="_blank"><FaInstagramSquare /></Link>
                  </div>
                </Col>
                <Col lg={4} md={5} sm={4} xs={12}>
                  <div className="bottom__image">
                    <div className="price__images">
                      <img src={Paypal} alt="" />
                      <img className="visa-img" src={Visa} alt="" />
                      <img className="round-img" src={Round} alt="" />
                      <img className="discover-img" src={Discover} alt="" />
                      <img className="american-img" src={Vector} alt="" />
                    </div>
                  </div>
                </Col>
                <Col lg={4} md={4} sm={4} xs={12}>
                  <div className="copy_sec">
                    <p>

                  {Copyright?.value}
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </footer>

    </>

  )
}

export default Footer