import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import SectionHeader from '../../components/SectionHeader'
import { useDispatch, useSelector } from 'react-redux';
import { fetchSettings } from '../../Redux/actions/settingsActions';

const PrivacyPolicy = () => {
    // ************ CMS START ************
    const dispatch = useDispatch();
    const { settings } = useSelector(state => state.settings);
    useEffect(() => {
        dispatch(fetchSettings());
    }, [dispatch]);
    const privacy_header_title = settings.find(setting => setting.name === 'privacy_header_title');
    const privacy_title = settings.find(setting => setting.name === 'privacy_title');
    const privacy_sub_title = settings.find(setting => setting.name === 'privacy_sub_title');
    // ************ CMS END ************
    return (
        <>
            <section>
                <SectionHeader title={privacy_header_title?.value} PageLink={privacy_header_title?.value}></SectionHeader>
                <div className="in-page-container pt-5 pb-4">
                    <Container>
                        <div className="main__privacy">
                            <div className="privacy__text">
                                <h3>{privacy_title?.value}</h3>
                                <div dangerouslySetInnerHTML={{ __html: privacy_sub_title?.value }} />
                            </div>
                        </div>
                    </Container>
                </div>
            </section>
        </>
    )
}

export default PrivacyPolicy