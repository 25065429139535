// import React, { useEffect, useState } from 'react'
// import { Alert, Col, Container, Row } from 'react-bootstrap'
// import SectionHeader from '../../components/SectionHeader'
// import Loading from '../../components/Loading';
// import { useParams, useNavigate, Link } from 'react-router-dom';
// import { useDispatch, useSelector } from "react-redux";
// import { fetchCategory } from "../../Redux/actions/categoryAction";
// import { InputLabel, TextField, Autocomplete } from "@mui/material";
// import { fetchSingleProduct, updateProducts } from '../../Redux/actions/productAction';

// const AuthorToolsResources = () => {
//     const [selectedCategories, setSelectedCategories] = useState([]);
//     const [showAlert, setShowAlert] = useState(false);
//     const [alertMessage, setAlertMessage] = useState("");
//     const [alertVariant, setAlertVariant] = useState("success");
//     const { id } = useParams();
//     const { product } = useSelector(state => state.product);
//     const navigate = useNavigate();
//     const dispatch = useDispatch();
//     const { category, loading, error, success } = useSelector((state) => state.category);
//     const [formData, setFormData] = useState({
//         slug: "",
//         description: "",
//         category: "",
//         price: "",
//         special_price: "",
//         image: null,
//     });
//     useEffect(() => {
//         const isLoggedIn = localStorage.getItem('user');
//         if (!isLoggedIn) {
//             navigate('/login');
//         }
//     }, [navigate]);

//     useEffect(() => {
//         dispatch(fetchSingleProduct(id));
//     }, [dispatch, id]);

//     useEffect(() => {
//         if (product) {
//             console.log('Fetched product:', product);
//             setFormData({
//                 slug: product.slug,
//                 description: product.description,
//                 category: product.category,
//                 price: product.price,
//                 special_price: product.special_price,
//                 image: null

//             });
//             const categoryToSelect = category.find(cat => cat.slug === product.category);
//             if (categoryToSelect) {
//                 setSelectedCategories([categoryToSelect]);
//             }
//         }
//     }, [product, category]);
//     const handleChange = (e) => {
//         const { name, value, type, files } = e.target;
//         if (type === "file") {
//             setFormData({ ...formData, image: files[0] });
//         } else {
//             setFormData({ ...formData, [name]: value });
//         }
//     };

//     const handleSubmit = (e) => {
//         e.preventDefault();

//         const updateData = new FormData();
//         updateData.append("slug", formData.slug);
//         updateData.append("description", formData.description);
//         updateData.append("category", formData.category);
//         updateData.append("price", formData.price);
//         updateData.append("special_price", formData.special_price);

//         if (formData.image) {
//             updateData.append("image", formData.image);
//         }
//         dispatch(updateProducts(id, updateData))
//             .then(response => {
//                 setAlertMessage("Product updated successfully!");
//                 setAlertVariant("success");
//                 setShowAlert(true);
//                 setTimeout(() => {
//                     navigate('/publisher-dashboard');
//                 }, 2000);
//             })
//             .catch(err => {
//                 setAlertMessage("Failed to update product. Please try again.");
//                 setAlertVariant("danger");
//                 setShowAlert(true);
//             });
//     };

//     // category fetch code START

//     const handleCategoryChange = (event, newValue) => {
//         setSelectedCategories(newValue);
//         setFormData((prevState) => ({
//             ...prevState,
//             category: newValue ? newValue.slug : "",
//         }));
//     };
//     // category fetch code END

//     return (
//         <>
//             <section>
//                 <SectionHeader title="Author Tools & Resources" PageLink="Author Tools & Resources"></SectionHeader>
//                 <Container>
//                     <div className="sellwith-main">
//                         {loading && <Loading />}
//                         {showAlert && <Alert variant={alertVariant} onClose={() => setShowAlert(false)} dismissible>{alertMessage}</Alert>}

//                         <form onSubmit={handleSubmit}>
//                             <Row>
//                                 <div className="sell-card-header">
//                                     <h4>Product Update</h4>
//                                 </div>
//                                 <Col md={12}>
//                                     <div>
//                                         <label className="form-label" htmlFor="Name">Name</label>
//                                         <input type="text" name="slug" value={formData.slug} id="Name" onChange={handleChange} className="form-control" />
//                                     </div>
//                                 </Col>
//                                 <Col md={12} className='pt-3'>
//                                     <div>
//                                         <label className="form-label" htmlFor="Description">Description</label>
//                                         <input type="text" name="description" value={formData.description} id="Description" onChange={handleChange} className="form-control" />
//                                     </div>
//                                 </Col>
//                                 <Col md={12} className="pt-3">
//                                     <InputLabel>Category</InputLabel>
//                                     <Autocomplete
//                                         options={category.flatMap((cat) =>
//                                             [{ label: cat.slug, slug: cat.slug, isParent: true }].concat(
//                                                 (cat.children || []).map((child) => ({
//                                                     label: `${child.slug}`,
//                                                     slug: child.slug,
//                                                     isParent: false
//                                                 }))
//                                             )
//                                         )}
//                                         value={selectedCategories}
//                                         onChange={handleCategoryChange}
//                                         disableCloseOnSelect
//                                         loading={loading}
//                                         renderInput={(params) => (
//                                             <TextField
//                                                 {...params}
//                                                 variant="outlined"
//                                                 placeholder="Choose categories"
//                                                 error={Boolean(error)}
//                                                 helperText={error ? "Failed to load categories" : ""}
//                                             />
//                                         )}
//                                         getOptionLabel={(option) => option.label}
//                                         isOptionEqualToValue={(option, value) => option.slug === value.slug}
//                                     />
//                                 </Col>
//                                 <Col md={6} className='pt-3'>
//                                     <div>
//                                         <label className="form-label" htmlFor="Price">Price (USD)</label>
//                                         <input type="number" name="price" value={formData.price} id="Price" onChange={handleChange} className="form-control" />
//                                     </div>
//                                 </Col>
//                                 <Col md={6} className='pt-3'>
//                                     <div>
//                                         <label className="form-label" htmlFor="SpecialPrice">Special Price (USD)</label>
//                                         <input type="number" name="special_price" value={formData.special_price} id="SpecialPrice" onChange={handleChange} className="form-control" />
//                                     </div>
//                                 </Col>
//                                 <Col md={12} className='pt-3'>
//                                     <div>
//                                         <label className="form-label" htmlFor="image">Product Image</label>
//                                         <input type="file" name="image" onChange={handleChange} accept="image/*" className="form-control" />
//                                     </div>
//                                 </Col>
//                                 <Col md={12} className='pt-3'>
//                                     <div className='d-flex gap-3'>
//                                         <button type="submit" className="btn btn-primary">Update Product</button>
//                                         <Link to="/publisher-dashboard" className="btn btn-warning">cancel</Link>
//                                     </div>
//                                 </Col>
//                             </Row>
//                         </form>
//                     </div>
//                 </Container>
//             </section >
//         </>
//     )
// }

// export default AuthorToolsResources
import React, { useEffect, useState } from 'react';
import { Alert, Col, Container, Row } from 'react-bootstrap';
import SectionHeader from '../../components/SectionHeader';
import Loading from '../../components/Loading';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { fetchCategory } from "../../Redux/actions/categoryAction";
import { InputLabel, TextField, Autocomplete } from "@mui/material";
import { fetchSingleProduct, updateProducts } from '../../Redux/actions/productAction';

const AuthorToolsResources = () => {
    const [selectedCategories, setSelectedCategories] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertVariant, setAlertVariant] = useState("success");
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { product } = useSelector(state => state.product);
    const { category, loading, error } = useSelector(state => state.category);

    const [formData, setFormData] = useState({
        slug: "",
        description: "",
        category: "",
        price: "",
        special_price: "",
        image: null,
    });

    // Check if user is logged in
    useEffect(() => {
        const isLoggedIn = localStorage.getItem('user');
        if (!isLoggedIn) {
            navigate('/login');
        }
    }, [navigate]);

    // Fetch product and category details
    useEffect(() => {
        dispatch(fetchSingleProduct(id));
        dispatch(fetchCategory());
    }, [dispatch, id]);

    useEffect(() => {
        if (product && product.data && category.length > 0) {
            setFormData({
                slug: product.data.slug || "",
                description: product.data.description || "",
                category: product.data.category || "",
                price: product.data.price || "",
                special_price: product.data.special_price || "",
                image: null,
            });

            // Match category for Autocomplete initial value
            const categoryToSelect = category.flatMap(cat =>
                [{ label: cat.slug, slug: cat.slug }].concat(
                    (cat.children || []).map(child => ({
                        label: child.slug,
                        slug: child.slug
                    }))
                )
            ).find(catOption => catOption.slug === product.data.category);

            if (categoryToSelect) {
                setSelectedCategories(categoryToSelect);
            }
        }
    }, [product, category]);

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === "file" ? files[0] : value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const updateData = new FormData();
        updateData.append("slug", formData.slug);
        updateData.append("description", formData.description);
        updateData.append("category", formData.category);
        updateData.append("price", formData.price);
        updateData.append("special_price", formData.special_price);

        if (formData.image) {
            updateData.append("image", formData.image);
        }

        dispatch(updateProducts(id, updateData))
            .then(() => {
                setAlertMessage("Product updated successfully!");
                setAlertVariant("success");
                setShowAlert(true);
                setTimeout(() => {
                    navigate('/publisher-dashboard');
                }, 2000);
            })
            .catch(() => {
                setAlertMessage("Failed to update product. Please try again.");
                setAlertVariant("danger");
                setShowAlert(true);
            });
    };

    const handleCategoryChange = (event, newValue) => {
        setSelectedCategories(newValue);
        setFormData((prevState) => ({
            ...prevState,
            category: newValue ? newValue.slug : "",
        }));
    };

    return (
        <>
            <section>
                <SectionHeader title="Author Tools & Resources" PageLink="Author Tools & Resources" />
                <Container>
                    <div className="sellwith-main">
                        {loading && <Loading />}
                        {showAlert && <Alert variant={alertVariant} onClose={() => setShowAlert(false)} dismissible>{alertMessage}</Alert>}

                        {loading ? <Loading /> : product && (
                            <form onSubmit={handleSubmit}>
                                <Row>
                                    <div className="sell-card-header">
                                        <h4>Product Update</h4>
                                    </div>
                                    <Col md={12}>
                                        <div>
                                            <label className="form-label" htmlFor="Name">Name</label>
                                            <input type="text" name="slug" value={formData.slug} id="Name" onChange={handleChange} className="form-control" />
                                        </div>
                                    </Col>

                                    <Col md={12} className='pt-3'>
                                        <div>
                                            <label className="form-label" htmlFor="Description">Description</label>
                                            <textarea name="description" value={formData.description} id="Description" onChange={handleChange} className="form-control"  rows="4" />
                                        </div>
                                    </Col>
                                    <Col md={12} className="pt-3">
                                        <InputLabel>Category</InputLabel>
                                        <Autocomplete
                                            options={category.flatMap(cat =>
                                                [{ label: cat.slug, slug: cat.slug }].concat(
                                                    (cat.children || []).map(child => ({
                                                        label: child.slug,
                                                        slug: child.slug
                                                    }))
                                                )
                                            )}
                                            value={selectedCategories}
                                            onChange={handleCategoryChange}
                                            getOptionLabel={(option) => option.label}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    placeholder="Choose categories"
                                                    error={Boolean(error)}
                                                    helperText={error ? "Failed to load categories" : ""}
                                                />
                                            )}
                                            isOptionEqualToValue={(option, value) => option.slug === value.slug}
                                        />
                                    </Col>

                                    <Col md={6} className='pt-3'>
                                        <div>
                                            <label className="form-label" htmlFor="Price">Price (USD)</label>
                                            <input type="number" name="price" value={formData.price} id="Price" onChange={handleChange} className="form-control" />
                                        </div>
                                    </Col>
                                    <Col md={6} className='pt-3'>
                                        <div>
                                            <label className="form-label" htmlFor="SpecialPrice">Special Price (USD)</label>
                                            <input type="number" name="special_price" value={formData.special_price} id="SpecialPrice" onChange={handleChange} className="form-control" />
                                        </div>
                                    </Col>
                                    <Col md={12} className='pt-3'>
                                        <div>
                                            <label className="form-label" htmlFor="image">Product Image</label>
                                            <input type="file" name="image" onChange={handleChange} accept="image/*" className="form-control" />
                                        </div>
                                    </Col>
                                    <Col md={12} className='pt-3'>
                                        <div className='d-flex gap-3'>
                                            <button type="submit" className="btn btn-primary">Update Product</button>
                                            <Link to="/publisher-dashboard" className="btn btn-warning">Cancel</Link>
                                        </div>
                                    </Col>
                                </Row>
                            </form>
                        )}
                    </div>
                </Container>
            </section>
        </>
    );
};

export default AuthorToolsResources;
