import axios from 'axios';

export const FETCH_CATEGORY_REQUEST = 'FETCH_CATEGORY_REQUEST';
export const FETCH_CATEGORY_SUCCESS = 'FETCH_CATEGORY_SUCCESS';
export const FETCH_CATEGORY_FAILURE = 'FETCH_CATEGORY_FAILURE';

export const fetchCategory = () => {
    return async (dispatch) => {
        dispatch({ type: FETCH_CATEGORY_REQUEST });
        try {
            const response = await axios.get(process.env.REACT_APP_BASE_URL + '/api/show-category');
            dispatch({
                type: FETCH_CATEGORY_SUCCESS,
                payload: response.data
            });
        } catch (error) {
            dispatch({
                type: FETCH_CATEGORY_FAILURE,
                payload: error.message
            });
        }
    };
};

