const loadCartFromLocalStorage = () => {
    try {
        const serializedCart = localStorage.getItem('cart');
        if (serializedCart) {
            return JSON.parse(serializedCart);
        }
    } catch (err) {
        console.error("Failed to load cart from localStorage", err);
    }
    return [];
};

const saveCartToLocalStorage = (cart) => {
    try {
        const serializedCart = JSON.stringify(cart);
        localStorage.setItem('cart', serializedCart);
    } catch (err) {
        console.error("Failed to save cart to localStorage", err);
    }
};


const INIT_STATE = {
    carts: loadCartFromLocalStorage()
};

export const cartreducer = (state = INIT_STATE, action) => {
    let updatedCart;
    switch (action.type) {
        case "ADD_CART":
            const IteamIndex = state.carts.findIndex(
                (item) => item.id === action.payload.id && item.variation === action.payload.variation
            );

            if (IteamIndex !== -1) {
                state.carts[IteamIndex].qnty += 1;
                updatedCart = [...state.carts];
            } else {
                const temp = { ...action.payload, qnty: 1 };
                updatedCart = [...state.carts, temp];
            }
            saveCartToLocalStorage(updatedCart);
            return {
                ...state,
                carts: updatedCart
            };

        case "RMV_CART":
            const data = state.carts.filter((el) => el.id !== action.payload);
            saveCartToLocalStorage(data);
            return {
                ...state,
                carts: data
            };

        case "RMV_ONE":
            const itemIndex = state.carts.findIndex((item) => item.id === action.payload.id);
            if (state.carts[itemIndex].qnty > 1) {
                updatedCart = state.carts.map((item, index) => {
                    if (index === itemIndex) {
                        return {
                            ...item,
                            qnty: item.qnty - 1
                        };
                    }
                    return item;
                });
            } else {
                updatedCart = state.carts.filter((item) => item.id !== action.payload.id);
            }
            saveCartToLocalStorage(updatedCart);
            return {
                ...state,
                carts: updatedCart
            };

        default:
            return state;
    }
};
