import React, { useState, useEffect } from 'react'
import { Col, Container, Form, Row, Table } from 'react-bootstrap'
import SectionHeader from '../../components/SectionHeader'
import HeaderImage from '../../images/section-herder-img.jpg'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Swal from 'sweetalert2';
import axios from 'axios'
import PaymentOptions from '../paymentOptions/PaymentOptions'

const CheckOut = () => {
    // State for user information
    const [firstName, setFirstName] = useState('');
    const [checkouts, setCheckouts] = useState('');
    const [email, setEmail] = useState('');
    const [contact, setContact] = useState('');
    const [Address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [state, setState] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [price, setPrice] = useState(0);
    const [shipping, setShipping] = useState(0); // State for shipping cost
    const [total, setTotal] = useState(0); // State for total amount

    // Fetch user details from Redux store
    const { settings, loading, error } = useSelector((state) => state.settings);
    const userDetails = useSelector(state => state.auth.user);
    useEffect(() => {
        if (userDetails) {
            setFirstName(userDetails.name);
            setEmail(userDetails.email);
            setContact(userDetails.phone);
        }
        // console.log(userDetails);
    }, [userDetails]);

    const customerId = useSelector(state => state.auth.userId);
    const getdata = useSelector((state) => state.cartreducer.carts);
    useEffect(() => {
        calculateTotal();
    }, [getdata, shipping]);

    const calculateTotal = () => {
        let totalPrice = 0;
        getdata.forEach((item) => {
            totalPrice += item.price * item.qnty;
        });
        setPrice(totalPrice);

        // Calculate shipping cost
        const shippingCost = free_shipping_minimum_amount?.value || 0;
        setShipping(shippingCost);

        // Calculate total including shipping
        const totalAmount = totalPrice + parseFloat(shippingCost);
        setTotal(totalAmount);
    };

    const placeOrder = async () => {
        // Check if any required field is empty
        const fields = {
            "First Name": firstName,
            "Checkouts": checkouts,
            "Email": email,
            "Contact": contact,
            "Address": Address,
            "City": city,
            "Country": country,
            "State": state,
            "ZIP Code": zipCode,
            "Payment Method": localStorage.getItem('selectedPaymentMethod'),
        };
        // Find all empty fields
        const emptyFields = Object.keys(fields).filter(key => !fields[key]);
        // Check if there are any empty fields
        if (emptyFields.length > 0) {
            Swal.fire({
                icon: 'error',
                title: 'Failed',
                text: `Please fill in all required fields:   ${emptyFields.join(', ')}`
            });
            return;
        }
        // Check if email is valid
        if (!email.includes('@')) {
            Swal.fire({
                icon: 'error',
                title: 'Failed',
                text: 'Please enter a valid email address.'
            });
            return;
        }

        // Proceed with placing the order
        // let orderItems = JSON.parse(localStorage.getItem('cart'));
        // let paymentMethod = localStorage.getItem('selectedPaymentMethod') ?? 'cashOnDelivery';
        let products = getdata.map((item) => ({
            product_id: item.id,
            qty: item.qnty,
            unit_price: item.price,
            product_attributes: item.attributes || "",
        }));
        let data = {
            firstName,
            email,
            contact,
            country,
            state,
            city,
            zipCode,
            Address,
            price,
            shipping_cost: shipping,
            customer_id: customerId,
            products,
        };
        console.log("Order data:", data);
        try {
            let baseUrl = process.env.REACT_APP_BASE_URL;
            const response = await axios.post(baseUrl + '/api/checkout', data);
            console.log('Order placed successfully:', response.data);
            Swal.fire('Order placed successfully!');
        } catch (error) {
            console.error("Error:", error);
            if (error.response) {
                console.log("Response data:", error.response.data);
                console.log("Response status:", error.response.status);
                console.log("Response headers:", error.response.headers);
            } else if (error.request) {
                // Request was made but no response was received
                console.log("Request data:", error.request);
            } else {
                console.log('Error message:', error.message);
            }
            Swal.fire('An error occurred. Please try again later.');
        }
    };
    const free_shipping_minimum_amount = settings.find(
        (setting) => setting.name === "free_shipping_minimum_amount"
    );
    return (
        <>
            <SectionHeader title='Check Out' image={HeaderImage} PageLink='Check Out'></SectionHeader>
            <div className="in-page-container pt-5 pb-5">
                <div className="top__check__sec">
                    <Container>
                        <Row>
                            <Col md={7}>
                                <div className="form__checkout">
                                    <Row className='mb-3'>
                                        <Form.Group as={Col} controlId="formGridEmail">
                                            <Form.Label>First Name <span className='red_star'> *</span></Form.Label>
                                            <Form.Control type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                        </Form.Group>
                                        {/* <Form.Group as={Col} md={6} controlId="formGridEmail">
                                            <Form.Label>Last Name <span className='red_star'> *</span></Form.Label>
                                            <Form.Control type="text" value="" />
                                        </Form.Group> */}
                                    </Row>
                                    <Row className='mb-3'>
                                        <Form.Group as={Col} controlId="formGridPassword">
                                            <Form.Label>Email <span className='red_star'> *</span></Form.Label>
                                            <Form.Control type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                        </Form.Group>
                                    </Row>
                                    <Row className='mb-3'>
                                        <Form.Group as={Col} controlId="formGridPassword">
                                            <Form.Label>Contact <span className='red_star'> *</span></Form.Label>
                                            <Form.Control type="text" value={contact} onChange={(e) => setContact(e.target.value)} />
                                        </Form.Group>
                                    </Row>
                                    {/* <Row className='mb-3'>
                                        <Form.Group as={Col} controlId="formGridPassword">
                                            <Form.Label>Company name (optional) <span className='red_star'> *</span></Form.Label>
                                            <Form.Control type="text" value="" />
                                        </Form.Group>
                                    </Row> */}
                                    <Row className="mb-3">
                                        <Col md={12}>
                                            <Form.Group as={Col} controlId="formGridPassword">
                                                <Form.Label>Street Address <span className='red_star'> *</span></Form.Label>
                                                <Form.Control type="Address" value={Address} onChange={(e) => setAddress(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col md={12}>
                                            <Form.Group as={Col} controlId="formGridPassword">
                                                <Form.Label>Town / City <span className='red_star'> *</span></Form.Label>
                                                <Form.Control type="text" value={city} onChange={(e) => setCity(e.target.value)} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="formGridPassword">
                                            <Form.Label>Country / Region <span className='red_star'> *</span></Form.Label>
                                            <Form.Control type="text" value={country} onChange={(e) => setCountry(e.target.value)} />
                                        </Form.Group>

                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="formGridPassword">
                                            <Form.Label>State <span className='red_star'> *</span></Form.Label>
                                            <Form.Control type="text" value={state} onChange={(e) => setState(e.target.value)} />
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} controlId="formGridPassword">
                                            <Form.Label>ZIP Code <span className='red_star'> *</span></Form.Label>
                                            <Form.Control type="number" value={zipCode} onChange={(e) => setZipCode(e.target.value)} />
                                        </Form.Group>
                                    </Row>
                                </div>
                            </Col>
                            <Col md={5}>
                                <div className="check__table sticky-top">
                                    <h2>Your Order</h2>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Products</th>
                                                <th>Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th>Sub Total</th>
                                                <td>$ {price}</td>
                                            </tr>
                                            <tr>
                                                <th>Total:</th>
                                                <th>$ {price}</th>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    <div className='single-widget'>
                                        <h4>SHIPPING METHODS</h4>
                                        <PaymentOptions />

                                    </div>
                                    <div class="bottom__check mt-3">
                                        <h2>Note</h2>
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam maiores quo fugiat ipsam nobis, porro deserunt commodi iste fugit suscipit eveniet molestias sit repellendus officiis cupiditate. Quae doloribus nobis quidem!</p>
                                        {/* <form class=""> */}
                                        <div class="mb-3">
                                            <div class="form-check">
                                                <input type="checkbox" checked={checkouts} onChange={(e) => setCheckouts(e.target.checked)} id="default-checkbox" class="form-check-input" />
                                                <label title="" for="default-checkbox" class="form-check-label">I have read and agree to the website terms and conditions * checkbox</label>
                                            </div>
                                        </div>
                                        <div class="order-btns">
                                            <Row>
                                                <Col md={6} xs={6}>
                                                    <div class="bottom__check__btn">
                                                        <button className='blue-btn btn-style' onClick={placeOrder}>Place Order</button>
                                                    </div>
                                                </Col>
                                                <Col md={6} xs={6}>
                                                    <div class="bottom__check__btn">
                                                        <Link class="blue-btn btn-style" to="/shop">Continue Shopping</Link>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        {/* </form> */}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            {/* 
            <Row>
                <Col md={7}>
                    <div className="check_out_inputs">
                        <Row className="check_out_input">
                            <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>First Name <span className='red_star'> *</span></Form.Label>
                                <Form.Control type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                            </Form.Group>
                        </Row>
                        <Row className="check_out_input">
                            <Form.Group as={Col} controlId="formGridPassword">
                                <Form.Label>Email <span className='red_star'> *</span></Form.Label>
                                <Form.Control type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridPassword">
                                <Form.Label>Contact <span className='red_star'> *</span></Form.Label>
                                <Form.Control type="text" value={contact} onChange={(e) => setContact(e.target.value)} />
                            </Form.Group>
                        </Row>
                        <Row className="check_out_input">
                            <Form.Group as={Col} controlId="formGridPassword">
                                <Form.Label>Country <span className='red_star'> *</span></Form.Label>
                                <Form.Control type="text" value={country} onChange={(e) => setCountry(e.target.value)} />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridPassword">
                                <Form.Label>State <span className='red_star'> *</span></Form.Label>
                                <Form.Control type="text" value={state} onChange={(e) => setState(e.target.value)} />
                            </Form.Group>
                        </Row>
                        <Row className="check_out_input">
                            <Form.Group as={Col} controlId="formGridPassword">
                                <Form.Label>City <span className='red_star'> *</span></Form.Label>
                                <Form.Control type="text" value={city} onChange={(e) => setCity(e.target.value)} />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formGridPassword">
                                <Form.Label>ZIP Code <span className='red_star'> *</span></Form.Label>
                                <Form.Control type="number" value={zipCode} onChange={(e) => setZipCode(e.target.value)} />
                            </Form.Group>
                        </Row>
                        <Row className="check_out_input">
                            <Col md={12}>
                                <Form.Group as={Col} controlId="formGridPassword">
                                    <Form.Label>Address <span className='red_star'> *</span></Form.Label>
                                    <Form.Control type="address" value={Address} onChange={(e) => setAddress(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col md={5}>
                    <div className="check_out_table_2 sticky-top">
                        <h4>Your Order</h4>
                        <div className="content">
                            <ul>
                                <li>Sub Total <span>$ {price}</span></li>
                                <li className='last'>Total <span id='totall'>$ {price}</span></li>
                            </ul>
                        </div>
                        <div className='single-widget'>
                            <h4>SHIPPING METHODS</h4>
                            <PaymentOptions />
                            <div className='bottom__total'>
                                <button className='proceed_to_checkout_btn' onClick={placeOrder}>Proceed to Payment</button>
                            </div>
                            <div className='bottom_total'>
                                <button className='proceed_to_checkout_btn'>
                                    <Link to="/shop" >Continue Shopping</Link>
                                </button>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row> */}
        </>
    )
}
export default CheckOut