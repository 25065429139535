import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { IoMdStar } from "react-icons/io";
import { Products } from '../../data';
import SectionHeader from '../../components/SectionHeader';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';


const Wishlist = () => {
    const navigate = useNavigate();
    const userInfo = useSelector(state => state.auth.user);
    useEffect(() => {

        if (!userInfo) {
            navigate('/login');
        }
    }, [userInfo, navigate]);
    if (!userInfo) {
        return null;
    }
    return (
        <section>
            <SectionHeader title='Wishlist' PageLink='wishlist' />
            <div className="main__wishlist pt-5 pb-5">
                <Container>
                    <Row>
                        {Products.map((product) => (
                            <Col lg={3} md={4} sm={6} className='mb-3' key={product.id}>
                                <div className="new-box">
                                    <div className="img-holder">
                                        <Link to={"/single-product/" + product.id}>
                                            <img src={product.image} alt="images" />
                                        </Link>
                                    </div>
                                    <div className="rate-area">
                                        <ul>
                                            <li><IoMdStar /></li>
                                            <li><IoMdStar /></li>
                                            <li><IoMdStar /></li>
                                            <li><IoMdStar /></li>
                                            <li><IoMdStar /></li>
                                        </ul>
                                        <span>(05 Review)</span>
                                    </div>
                                    <Link className="cat-title" to={"/single-product/" + product.id}>
                                        <strong>{product.name}</strong>
                                    </Link>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                    <div className="new-prices">
                                        <span>$99.00</span><del>{product.price}</del>
                                    </div>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </div>
        </section>
    );
};

export default Wishlist;
