import axios from 'axios';

export const ADD_PRODUCT_REQUEST = 'ADD_PRODUCT_REQUEST';
export const ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS';
export const ADD_PRODUCT_FAILURE = 'ADD_PRODUCT_FAILURE';

export const PRODUCT_FETCH_REQUEST = 'PRODUCT_FETCH_REQUEST';
export const PRODUCT_FETCH_SUCCESS = 'PRODUCT_FETCH_SUCCESS';
export const PRODUCT_FETCH_FAILURE = 'PRODUCT_FETCH_FAILURE';

export const PRODUCT_VARIATION_REQUEST = 'PRODUCT_VARIATION_REQUEST';
export const PRODUCT_VARIATION_SUCCESS = 'PRODUCT_VARIATION_SUCCESS';
export const PRODUCT_VARIATION_FAILURE = 'PRODUCT_VARIATION_FAILURE';

export const UPDATE_PRODUCT_REQUEST = 'UPDATE_PRODUCT_REQUEST';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_FAIL = 'UPDATE_PRODUCT_FAIL';

export const SINGLE_PRODUCT_REQUEST = 'SINGLE_PRODUCT_REQUEST';
export const SINGLE_PRODUCT_SUCCESS = 'SINGLE_PRODUCT_SUCCESS';
export const SINGLE_PRODUCT_FAILURE = 'SINGLE_PRODUCT_FAILURE'; 

// Fetching to update the products
export const updateProducts = (id, productData) => async (dispatch) => {
  try {
      dispatch({ type: UPDATE_PRODUCT_REQUEST });
      let baseUrl = process.env.REACT_APP_BASE_URL;
      const response = await axios.post(`${baseUrl}/api/update-product/${id}`, productData, {
          headers: {
              'Content-Type': 'multipart/form-data',
          },
      });
      dispatch({ type: UPDATE_PRODUCT_SUCCESS, payload: response.data });
  } catch (error) {
      dispatch({
          type: UPDATE_PRODUCT_FAIL,
          payload: error.response ? error.response.data.message : error.message,
      });
  }
};

// Fetch single product by ID
export const fetchSingleProduct = (id) => async (dispatch) => {
  dispatch({ type: 'SINGLE_PRODUCT_REQUEST' });
  try {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/single-product/${id}`);
    dispatch({ type: 'SINGLE_PRODUCT_SUCCESS', payload: response.data });
  } catch (error) {
    dispatch({ type: 'SINGLE_PRODUCT_FAILURE', payload: error.response?.data?.message || error.message });
  }
};

// Update product by ID
export const updateProduct = (id, productData) => async (dispatch) => {
  dispatch({ type: 'UPDATE_PRODUCT_REQUEST' });
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/update-product/${id}`, productData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    dispatch({ type: 'UPDATE_PRODUCT_SUCCESS', payload: response.data });
  } catch (error) {
    dispatch({ type: 'UPDATE_PRODUCT_FAIL', payload: error.response?.data?.message || error.message });
  }
};

// Fetch products to store
export const addProduct = (productData) => async (dispatch) => {
  dispatch({ type: ADD_PRODUCT_REQUEST });
  try {
      let baseUrl = process.env.REACT_APP_BASE_URL;
      const response = await axios.post(`${baseUrl}/api/product-store`, productData, {
          headers: {
              'Content-Type': 'multipart/form-data',
          },
      });
      dispatch({ type: ADD_PRODUCT_SUCCESS, payload: response.data });
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'An unexpected error occurred.';
      dispatch({ type: ADD_PRODUCT_FAILURE, payload: errorMessage });
  }
};
// Fetch products to show
export const fetchProductShow = () => {
  return async (dispatch) => {
    dispatch({ type: PRODUCT_FETCH_REQUEST });
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/show-products`);
      dispatch({
        type: PRODUCT_FETCH_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: PRODUCT_FETCH_FAILURE,
        payload: error.response?.data?.message || error.message,
      });
    }
  };
};
// Fetch product variation by ID
export const variationById = (id) => {
  return async (dispatch) => {
    dispatch({ type: PRODUCT_VARIATION_REQUEST });
    try {

      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/product_variation/${id}`);
      if (response.status === 200) {
        dispatch({
          type: PRODUCT_VARIATION_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: PRODUCT_VARIATION_FAILURE,
          payload: 'No data found for this product variation',
        });
      }
    } catch (error) {
      dispatch({
        type: PRODUCT_VARIATION_FAILURE,
        payload: error.response?.data?.message || error.message,
      });
    }
  };
};

