import React from 'react'
import SectionHeader from '../../components/SectionHeader'
import { Container } from 'react-bootstrap'

const MarketingPromotionServices = () => {
    return (
        <>
            <section>
                <SectionHeader title="Marketing & Promotion Services" PageLink="Marketing & Promotion Services"></SectionHeader>
                <Container>
                    <div className="page-not-found">
                        <h2>Coming soon</h2>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default MarketingPromotionServices