import React, { useState } from 'react';
import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const OrderSearch = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();

    const handleTrackOrder = (event) => {
        setSearchQuery(event.target.value);
        console.log('Order ID 2:', searchQuery);
    };

    const handleSearch = () => {
        navigate(`/single-orders/${searchQuery}`);
        console.log('searchQuery Order ID:', searchQuery);
    };
    return (
        <>
            <Container>
                <Row>
                    <Col>
                        <form onSubmit={handleSearch}>
                            <div className="order_tracking">
                                <InputGroup className="order_tracking_inputs">
                                    <Form.Control
                                        type='number'
                                        placeholder="Enter Your Order ID"
                                        value={searchQuery}
                                        onChange={handleTrackOrder}
                                    />
                                    <Button className='blue-btn btn-style' id="button-addon2" type='submit'>
                                        Track Order
                                    </Button>
                                </InputGroup>
                            </div>
                        </form>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default OrderSearch;
