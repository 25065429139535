import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import SectionHeader from '../../components/SectionHeader'
import NoImage from "../../images/NoImage.jpg"
import HeaderImage from '../../images/section-herder-img.jpg'
import { FaStar } from "react-icons/fa";
import Card from 'react-bootstrap/Card';
import { Col, Container, Row } from 'react-bootstrap';
import axios from 'axios';
import Loading from '../../components/Loading';

const Testimonials = () => {
    const [testimonial, setTestimonial] = useState([]);
    let baseUrl = process.env.REACT_APP_BASE_URL;
    let imgbaseUrl = baseUrl + "/public/";
    useEffect(() => {
        axios.get(baseUrl + '/api/testimonials-show')
            .then(response => {
                setTestimonial(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the data!', error);
            });
    }, []);
    useEffect(() => {
        AOS.init();
    }, []);
    return (
        <>
            <section>
                <SectionHeader title='Testimonials' PageLink='Testimonials'></SectionHeader>
                <div className="in-page-container pt-4 pb-4">
                    <div className="testimonials__section" >
                        <Container>
                            <Row>
                                {
                                    testimonial.map(({ id, title, image, description }) => {
                                        return (
                                            <Col lg={4} md={6}  data-aos="zoom-in-up" data-aos-duration="2000" data-aos-easing="linear">
                                                <div className="skew__reviews">
                                                    <div className="top-reviews">
                                                        <div className="main__reviews">
                                                            {image ? (
                                                                <img
                                                                    src={`${imgbaseUrl}${image}`}
                                                                />
                                                            ) : (
                                                                <img src={NoImage} alt="img" />
                                                            )}
                                                        </div>
                                                        <div className="box__back">
                                                            <div className="reviews__stars">
                                                                <ul>
                                                                    <li><FaStar /></li>
                                                                    <li><FaStar /></li>
                                                                    <li><FaStar /></li>
                                                                    <li><FaStar /></li>
                                                                    <li><FaStar /></li>
                                                                </ul>
                                                            </div>
                                                            <div className="reviews__text">
                                                                <div dangerouslySetInnerHTML={{ __html: description }} />
                                                                <strong>{title}</strong>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        )
                                    })
                                 }
                            </Row>
                        </Container>
                    </div>
                </div>
            </section >
        </>
    )
}

export default Testimonials