import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      // Time's up! You could set all values to 0 or handle expiration.
      timeLeft = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer); // Cleanup the interval on component unmount
  }, [targetDate]);

  return (
    <div>
      <ul className="days-counter">
        <li className="days">
          <strong>{timeLeft.days}</strong><span>Days</span>
        </li>
        <li className="hours">
          <strong>{timeLeft.hours}</strong><span>hrs</span>
        </li>
        <li className="minutes">
          <strong>{timeLeft.minutes}</strong><span>mins</span>
        </li>
        <li className="seconds">
          <strong>{timeLeft.seconds}</strong><span>Secs</span>
        </li>
      </ul>
    </div>
  );
};

export default CountdownTimer;
