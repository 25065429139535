import React, { useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from "react-router-dom";
import { FaRegClock } from "react-icons/fa6";
import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import HeaderNav from './HeaderNav';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSettings } from '../Redux/actions/settingsActions';


const Header = () => {
  // ************ CMS START ************
  const dispatch = useDispatch();
  const { settings } = useSelector(state => state.settings);
  useEffect(() => {
    dispatch(fetchSettings());
  }, [dispatch]);
  const top_header_left_text = settings.find(setting => setting.name === 'top_header_left_text');
  const top_header_mid_text = settings.find(setting => setting.name === 'top_header_mid_text');
  const fb_link = settings.find(setting => setting.name === 'fb_link');
  const in_link = settings.find(setting => setting.name === 'in_link');
  // ************ CMS END ************
  return (
    <>
      <div className="top-bar">
        <Container>
          <Row class="align_items_center ">
            <Col md={4} sm={4} xs={4}>
              <div className="left-side">
                <ul>
                  <li><FaRegClock /></li>
                  <li>{top_header_left_text?.value}</li>
                </ul>
              </div>
            </Col>
            <Col md={4} sm={4} xs={4}>
              <div className="mid-area">
                <p>
                  {top_header_mid_text?.value}&nbsp;
                  <Link to="#">Find Out More</Link>
                </p>
              </div>
            </Col>
            <Col md={4} sm={4} xs={4}>
              <div className="right-side">
                <ul>
                  {/* <li className='google_translate'><GoogleTranslate /> </li> */}
                  <li>Social Media : </li>
                  <li><Link to={fb_link?.value} target="_blank"><FaFacebookSquare /></Link></li>
                  <li><Link to={in_link?.value} target="_blank"><FaInstagramSquare /></Link></li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <HeaderNav />
    </>
  )
}

export default Header