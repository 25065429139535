import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { FaStar } from "react-icons/fa";
import { FaStarHalfStroke } from "react-icons/fa6";
import { FaRegStar } from "react-icons/fa6";
import { useParams } from 'react-router';
import { Col, ProgressBar, Row } from 'react-bootstrap';

const ProductReview = () => {
    const [reviews, setReviews] = useState([]);
    const [users, setUsers] = useState([]);
    const [averageRating, setAverageRating] = useState(0);
    const { id } = useParams();

    let baseUrl = process.env.REACT_APP_BASE_URL;
    useEffect(() => {
        const fetchReview = async () => {
            try {
                const response = await axios.get(`${baseUrl}/api/product-reviews/${id}`);
                setReviews(response.data.reviews);
                setAverageRating(response.data.average_rating);
            } catch (error) {
                console.error('Error fetching product review:', error);
            }
        };
        fetchReview();
    }, [id, baseUrl]);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await axios.get(`${baseUrl}/api/show-users`);
                setUsers(response.data);
                console.log('fetching User:', response.data);
            } catch (error) {
                console.error('Error fetching user:', error);
            }
        };
        fetchUser();
    }, [baseUrl]);

    // START ProgressBar ***********
    const ratingCounts = reviews.reduce((counts, review) => {
        counts[review.rating] = (counts[review.rating] || 0) + 1;
        return counts;
    }, {});
    const ratings = [
        { stars: 5, votes: ratingCounts[5] || 0 },
        { stars: 4, votes: ratingCounts[4] || 0 },
        { stars: 3, votes: ratingCounts[3] || 0 },
        { stars: 2, votes: ratingCounts[2] || 0 },
        { stars: 1, votes: ratingCounts[1] || 0 },
    ];
    const totalVotes = reviews.length;
    // END ProgressBar ***********
    const renderStars = (rating) => {
        const starComponents = [];
        const wholeStars = Math.floor(rating);
        const decimalPart = rating - wholeStars;
        // Whole stars
        for (let i = 0; i < wholeStars; i++) {
            starComponents.push(<FaStar key={i} />);
        }
        // Half-filled star
        if (decimalPart >= 0.5) {
            starComponents.push(<FaStarHalfStroke key="half" />);
        }
        // Empty stars
        const remainingStars = 5 - Math.ceil(rating);
        for (let i = 0; i < remainingStars; i++) {
            starComponents.push(<FaRegStar key={`empty-${i}`} style={{ color: "#818286" }} />);
        }
        return starComponents;
    };

    // Helper function to get the user name by user_id
    const getUserNameById = (userId) => {
        const user = users.find(user => user.id === userId);
        return user ? user.name : "Unknown User";
    };


    return (
        <>
            <div>
                <div className='rating__pro'>
                    {averageRating ? (
                        renderStars(averageRating)
                    ) : (
                        Array.from({ length: 5 }, (_, index) => <FaRegStar key={index} />)
                    )}
                    <div className='reviews_length'>
                        <span>{reviews.length} reviews</span>
                    </div>
                </div>
                <div className='review_progress_bar mt-5'>
                    <Row>
                        <Col md={6}>
                            <div className="">
                                <strong>Rating Snapshot</strong>
                                <p>Select a row below to filter reviews.</p>
                                {ratings.map((rating, index) => (
                                    <div className="rate-bars" key={index}>
                                        <div className="rate-box">
                                            <div className="rate-num">{rating.stars} <FaStar /></div>
                                            <div className="rate-progress">
                                                <ProgressBar
                                                    animated
                                                    now={(rating.votes / totalVotes) * 100}
                                                />
                                            </div>
                                            <div className="rate-votes">{rating.votes}</div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Col>
                        <Col md={6}>
                            <strong>Review Comments</strong>
                            <div className="review_comment_main">
                                {reviews.map((review, index) => (
                                    <>
                                        <div className="review_comment_section" key={index}>
                                            <strong>{getUserNameById(review.user_id)}</strong>

                                            <p className='mt-1 mb-3'>{new Date(review.created_at).toLocaleDateString()}</p>
                                            <p>{renderStars(review.rating)}</p>
                                            <p>{review.comment}</p>
                                        </div>
                                    </>
                                ))}
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}

export default ProductReview