import React, { useCallback, useEffect, useState } from 'react';
// import Slider from "react-slick";
// import Logo from "../images/logo.png"
import User from "../images/user.png"
// import SliderImg from "../images/slider-img.jpg"
// import SliderImg2 from "../images/slider-img2.png"
import { Navbar, NavDropdown, Container, Row, Col, Form, InputGroup } from 'react-bootstrap';
import { NavLink, Link, useNavigate } from 'react-router-dom';
import { IoCallSharp } from "react-icons/io5";
import { IoSearch } from "react-icons/io5";
import { FaRegHeart } from "react-icons/fa";
import { GrUserManager } from "react-icons/gr";
import { FaCartShopping } from "react-icons/fa6";
import { RiArrowRightSLine, RiLoginBoxLine } from "react-icons/ri";
// import { CiStar } from "react-icons/ci";
import { fetchSettings } from '../Redux/actions/settingsActions';

import { useDispatch, useSelector } from 'react-redux';
import { DLT } from '../Redux/actions/CartAction';
import Badge from '@mui/material/Badge';
import CartBody from './CartBody';

const HeaderNav = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();
    const [price, setPrice] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch = useDispatch();
    const getdata = useSelector((state) => state.cartreducer.carts);
    // const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
    const isLoggedIn = useSelector(state => state.auth.user);

    // ************ CMS START ************
    const { settings } = useSelector(state => state.settings);
    useEffect(() => {
        dispatch(fetchSettings());
    }, [dispatch]);
    const phone_link = settings.find(setting => setting.name === 'phone_link');
    const phone_text = settings.find(setting => setting.name === 'phone_text');
    // For image 
    const mid_header_logo = settings.find(setting => setting.name === 'mid_header_logo');
    const baseImageUrl = process.env.REACT_APP_BASE_URL + '/public/uploads/media/';
    const imageUrl = mid_header_logo?.value ? `${baseImageUrl}${mid_header_logo.value}` : '';
    // ************ CMS END ************

    // var settingss = {
    //     dots: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     arrows: false,
    // };
    const handleInputChange = (event) => {
        setSearchQuery(event.target.value);
    };
    const handleSearch = (event) => {
        event.preventDefault();
        navigate(`/shop?search=${searchQuery}`);
    };
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const dlt = (id) => {
        dispatch(DLT(id))
    }
    const total = useCallback(() => {
        let totalPrice = 0;
        getdata.forEach((ele, k) => {
            totalPrice = ele.price * ele.qnty + totalPrice;
        });
        setPrice(totalPrice);
    }, [getdata]);
    useEffect(() => {
        total();
    }, [total])

    return (
        <>
            <div className="mid-bar">
                <Container>
                    <Row className='align-items-center'>
                        <Col lg={3} md={2} sm={2} xs={4} className='d-flex justify-content-center'>
                            <Link to="/" className='logo'>
                                <img src={imageUrl} alt="" />
                            </Link>
                        </Col>
                        <Col lg={9} md={10} sm={10} xs={8}>
                            <div className="right-side">
                                <div className="h-searchbox">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Search entire store here..." />
                                        <button type="button" id="search-bar" className="btn btn-outline-secondary"><IoSearch /></button>
                                    </div>
                                </div>
                                {/* <form onSubmit={handleSearch} className='searchbar'>
                                    <InputGroup className="mb-3 ">
                                        <Form.Control
                                            placeholder="Search All Products"
                                            value={searchQuery}
                                            onChange={handleInputChange}
                                        />
                                        <button type='submit'>sdf</button>
                                    </InputGroup>
                                </form> */}
                                <div className="h-callinfo">
                                    <ul>
                                        <li className="icon-area"><span><IoCallSharp /></span></li>
                                        <li>
                                            <span>Call Us For More Info </span><br />
                                            <Link to={phone_link?.value}> {phone_text?.value}</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="h-wishlist">
                                    <ul>
                                        {/* <li className="icon-area"><span><FaRegHeart /></span></li> */}
                                        <li><Link to="/wishlist"><i className='header_input_icon'><FaRegHeart /></i></Link></li>
                                    </ul>
                                </div>
                                <div className="h-cart my_account">
                                    {isLoggedIn ? (
                                        <NavLink to='dashboard/overview'>
                                            <i className='header_input_icon'><GrUserManager /></i>
                                        </NavLink>
                                    ) : (
                                        <NavLink to='/login'>
                                            <i className='header_input_icon'><RiLoginBoxLine /></i>
                                        </NavLink>
                                    )}
                                </div>
                                <div className="h-cart">
                                    <NavLink to='#'>
                                        <Badge badgeContent={getdata.length} color="primary"
                                            id="basic-button"
                                            aria-controls={open ? 'basic-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                            onClick={handleClick}
                                        >
                                            <i className='header_input_icon' ><FaCartShopping /></i>
                                        </Badge>
                                    </NavLink>
                                </div>
                                {/* <div className="h-user">
                                    <NavDropdown title={
                                        <div className="pull-left">
                                            <img className="thumbnail-image" src={User} alt="user pic" />
                                        </div>
                                    } id="basic-nav-dropdown">
                                        <NavDropdown.Item as={Link} to="#">Profile</NavDropdown.Item>
                                        <NavDropdown.Item as={Link} to="#">Settings</NavDropdown.Item>
                                        <NavDropdown.Item as={Link} to="#">Logout</NavDropdown.Item>
                                    </NavDropdown>
                                </div> */}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <CartBody anchorEl={anchorEl} open={open} handleClose={handleClose} getdata={getdata} dlt={dlt} price={price} />
            <div className="nav-bar">
                <Navbar expand="lg" className="nav_bar" data-bs-theme="light">
                    <Container>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <div className='navbar-nav' >
                                <NavLink to="/"
                                    className='simple_nav nav-link'>Home
                                </NavLink>
                                <div className='nav-item dropdown' >
                                    <NavLink to="#"
                                        data-mdb-button-init
                                        type="button"
                                        id="browse-books-nav-dropdown"
                                        data-mdb-toggle="dropdown"
                                        aria-expanded="false"
                                        data-mdb-ripple-init data-mdb-dropdown-init
                                        className='dropdown-toggle nav-link'>Browse Books
                                    </NavLink>
                                    <div className="dropdown-menu" data-bs-popper="static" aria-labelledby="browse-books-nav-dropdown">
                                        <Row>
                                            <Col md={12}>
                                                <h4 className="nav-heading">
                                                    <Link to="#">
                                                        Browse books <RiArrowRightSLine />
                                                    </Link>
                                                </h4>
                                            </Col>
                                            <Col md={12}>
                                                <Row>
                                                    <Col md={3}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>Ebooks</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/shop?search=Fiction">Fiction</Link></li>
                                                                <li><Link to="/shop?search=Non-Fiction">Non-Fiction</Link></li>
                                                                <li><Link to="/shop?search=Bestsellers">Bestsellers</Link></li>
                                                                <li><Link to="/shop?search=New Releases">New Releases</Link></li>
                                                                <li><Link to="/shop?search=Classics">Classics</Link></li>
                                                                <li><Link to="/shop?search=Children's Books">Children's Books</Link></li>
                                                                <li><Link to="/shop?search=Young Adult">Young Adult</Link></li>
                                                                <li><Link to="/shop?search=Science Fiction & Fantasy">Science Fiction & Fantasy</Link></li>
                                                                <li><Link to="/shop?search=Romance">Romance</Link></li>
                                                                <li><Link to="/shop?search=Mystery & Thriller">Mystery & Thriller</Link></li>
                                                                <li><Link to="/shop?search=Biographies & Memoirs">Biographies & Memoirs</Link></li>
                                                                <li><Link to="/shop?search=Self-Help">Self-Help</Link></li>
                                                                <li><Link to="/shop?search=Business & Finance">Business & Finance</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={3}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>Audiobooks</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/shop?search=Audiobooks-Fiction">Fiction</Link></li>
                                                                <li><Link to="/shop?search=Audiobooks-Non-Fiction">Non-Fiction</Link></li>
                                                                <li><Link to="/shop?search=Audiobooks-Bestsellers">Bestsellers</Link></li>
                                                                <li><Link to="/shop?search=Audiobooks-New Releases">New Releases</Link></li>
                                                                <li><Link to="/shop?search=Audiobooks-Short Stories">Short Stories</Link></li>
                                                                <li><Link to="/shop?search=Audiobooks-Podcasts & Interviews">Podcasts & Interviews</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={3}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>- Languages</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/shop?search=">Multilingual Options</Link></li>
                                                                <li><Link to="/shop?search=">Language Learning</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={3}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>Genres</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/shop?search=">Explore All Genres</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className='nav-item dropdown' >
                                    <NavLink to="#"
                                        data-mdb-button-init
                                        type="button"
                                        id="discover-nav-dropdown"
                                        data-mdb-toggle="dropdown"
                                        aria-expanded="false"
                                        data-mdb-ripple-init data-mdb-dropdown-init
                                        className='dropdown-toggle nav-link'>Discover
                                    </NavLink>
                                    <div className="dropdown-menu" data-bs-popper="static" aria-labelledby="discover-nav-dropdown">
                                        <Row>
                                            <Col md={12}>
                                                <h4 className="nav-heading">
                                                    <Link to="#">
                                                        Discover <RiArrowRightSLine />
                                                    </Link>
                                                </h4>
                                            </Col>
                                            <Col md={12}>
                                                <Row>
                                                    <Col md={3}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>Curated Collections</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/shop?search=Editor’s Picks">Editor’s Picks</Link></li>
                                                                <li><Link to="/shop?search=Award Winners">Award Winners</Link></li>
                                                                <li><Link to="/shop?search=Indie Authors">Indie Authors</Link></li>
                                                                <li><Link to="/shop?search=Trending Now">Trending Now</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={2}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>Author Spotlight</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/shop?search=Author Spotlight">Author Spotlight</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={2}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>Publisher’s Corner</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/shop?search=Publisher’s Corner">Publisher’s Corner</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={2}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>Special Editions</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/shop?search=Special Editions">Special Editions</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={2}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>Exclusive Content</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/shop?search=Exclusive Content">Exclusive Content</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className='nav-item dropdown' >
                                    <NavLink to="#"
                                        data-mdb-button-init
                                        type="button"
                                        id="discover-nav-dropdown"
                                        data-mdb-toggle="dropdown"
                                        aria-expanded="false"
                                        data-mdb-ripple-init data-mdb-dropdown-init
                                        className='dropdown-toggle nav-link'>Community
                                    </NavLink>
                                    <div className="dropdown-menu" data-bs-popper="static" aria-labelledby="community-nav-dropdown">
                                        <Row>
                                            <Col md={12}>
                                                <h4 className="nav-heading">
                                                    <Link to="#">
                                                        Community <RiArrowRightSLine />
                                                    </Link>
                                                </h4>
                                            </Col>
                                            <Col md={12}>
                                                <Row>
                                                    <Col md={2}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>Book Clubs</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/shop?search=Start a Club">Start a Club</Link></li>
                                                                <li><Link to="/shop?search=Join a Club">Join a Club</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={2}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>Reader Reviews</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/shop?search=Reader Reviews">Reader Reviews</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={2}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>Discussion Forums</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/shop?search=Discussion Forums">Discussion Forums</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={2}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>Author Events</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/shop?search=Author Events">Author Events</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={2}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>Interviews & Podcasts</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/shop?search=Interviews & Podcasts">Interviews & Podcasts</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className='nav-item dropdown' >
                                    <NavLink to="#"
                                        data-mdb-button-init
                                        type="button"
                                        id="authors-nav-dropdown"
                                        data-mdb-toggle="dropdown"
                                        aria-expanded="false"
                                        data-mdb-ripple-init data-mdb-dropdown-init
                                        className='dropdown-toggle nav-link'>For Authors & Publishers
                                    </NavLink>
                                    <div className="dropdown-menu" data-bs-popper="static" aria-labelledby="authors-nav-dropdown">
                                        <Row>
                                            <Col md={12}>
                                                <h4 className="nav-heading">
                                                    <Link to="#">
                                                        For Authors & Publishers <RiArrowRightSLine />
                                                    </Link>
                                                </h4>
                                            </Col>
                                            <Col md={12}>
                                                <Row>
                                                    <Col md={2}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>Sell with Us</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/sell-with-us">Sell with Us</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={2}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>Author Tools & Resources</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/author-tools-resources">Author Tools & Resources</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={2}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>Publisher Dashboard</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/publisher-dashboard">Publisher Dashboard</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={2}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>Royalties & Payments</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/user-payment">Royalties & Payments</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={3}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>Marketing & Promotion Services</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/marketing-promotion-services">Marketing & Promotion Services</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className='nav-item dropdown' >
                                    <NavLink to="about"
                                        data-mdb-button-init
                                        type="button"
                                        id="about-nav-dropdown"
                                        data-mdb-toggle="dropdown"
                                        aria-expanded="false"
                                        data-mdb-ripple-init data-mdb-dropdown-init
                                        className='dropdown-toggle nav-link'>About Us
                                    </NavLink>
                                    <div className="dropdown-menu" data-bs-popper="static" aria-labelledby="about-nav-dropdown">
                                        <Row>
                                            <Col md={12}>
                                                <h4 className="nav-heading">
                                                    <Link to="#">
                                                        About us <RiArrowRightSLine />
                                                    </Link>
                                                </h4>
                                            </Col>
                                            <Col md={12}>
                                                <Row>
                                                    <Col md={2}>
                                                        <div className="menu-box">
                                                            <ul>
                                                                <li><Link to="/our-story">Our Story</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={2}>
                                                        <div className="menu-box">
                                                            <ul>
                                                                <li><Link to="/mission-vision">Mission & Vision</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={2}>
                                                        <div className="menu-box">
                                                            <ul>
                                                                <li><Link to="/press-media">Press & Media</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={2}>
                                                        <div className="menu-box">
                                                            <ul>
                                                                <li><Link to="/">Careers</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={2}>
                                                        <div className="menu-box">
                                                            <ul>
                                                                <li><Link to="/privacy">Privacy Policy</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={2}>
                                                        <div className="menu-box">
                                                            <ul>
                                                                <li><Link to="/termsConditions">Terms & Conditions</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className='nav-item dropdown' >
                                    <NavLink to="blogs"
                                        data-mdb-button-init
                                        type="button"
                                        id="blog-nav-dropdown"
                                        data-mdb-toggle="dropdown"
                                        aria-expanded="false"
                                        data-mdb-ripple-init data-mdb-dropdown-init
                                        className='dropdown-toggle nav-link'>Blog
                                    </NavLink>
                                    <div className="dropdown-menu" data-bs-popper="static" aria-labelledby="blog-nav-dropdown">
                                        <Row>
                                            <Col md={12}>
                                                <h4 className="nav-heading">
                                                    <Link to="#">
                                                        Blog<RiArrowRightSLine />
                                                    </Link>
                                                </h4>
                                            </Col>
                                            <Col md={12}>
                                                <Row>
                                                    <Col md={2}>
                                                        <div className="menu-box">
                                                            <ul>
                                                                <li><Link to="/blogs?type=Reading Lists">Reading Lists</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={2}>
                                                        <div className="menu-box">
                                                            <ul>
                                                                <li><Link to="/blogs?type=Book Reviews">Book Reviews</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={2}>
                                                        <div className="menu-box">
                                                            <ul>
                                                                <li><Link to="/blogs?type=Industry News">Industry News</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={2}>
                                                        <div className="menu-box">
                                                            <ul>
                                                                <li><Link to="/blogs?type=Tips for Readers">Tips for Readers</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={3}>
                                                        <div className="menu-box">
                                                            <ul>
                                                                <li><Link to="/blogs?type=Digital Reading Guides">Digital Reading Guides</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className='nav-item dropdown' >
                                    <NavLink to="#"
                                        data-mdb-button-init
                                        type="button"
                                        id="support-nav-dropdown"
                                        data-mdb-toggle="dropdown"
                                        aria-expanded="false"
                                        data-mdb-ripple-init data-mdb-dropdown-init
                                        className='dropdown-toggle nav-link'>Support
                                    </NavLink>
                                    <div className="dropdown-menu" data-bs-popper="static" aria-labelledby="support-nav-dropdown">
                                        <Row>
                                            <Col md={12}>
                                                <h4 className="nav-heading">
                                                    <Link to="#">
                                                        Support<RiArrowRightSLine />
                                                    </Link>
                                                </h4>
                                            </Col>
                                            <Col md={12}>
                                                <Row>
                                                    <Col md={2}>
                                                        <div className="menu-box">
                                                            <ul>
                                                                <li><Link to="/customer-service">Customer Service</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={2}>
                                                        <div className="menu-box">
                                                            <ul>
                                                                <li><Link to="/faqs">FAQs</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={2}>
                                                        <div className="menu-box">
                                                            <ul>
                                                                <li><Link to="/technical-support">Technical Support</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={2}>
                                                        <div className="menu-box">
                                                            <ul>
                                                                <li><Link to="/order-search">Order Tracking</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={2}>
                                                        <div className="menu-box">

                                                            <ul>
                                                                <li><Link to="/refund">Return & Refund Policy</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={2}>
                                                        <div className="menu-box">
                                                            <ul>
                                                                <li><Link to="/contact">Contact Us</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>

                                {/* <div className='nav-item dropdown' >
                                    <NavLink to="#"
                                        data-mdb-button-init
                                        type="button"
                                        id="non-fiction-nav-dropdown"
                                        data-mdb-toggle="dropdown"
                                        aria-expanded="false"
                                        data-mdb-ripple-init data-mdb-dropdown-init
                                        className='dropdown-toggle nav-link'>Nonfiction
                                    </NavLink>
                                    <div className="dropdown-menu" data-bs-popper="static" aria-labelledby="non-fiction-nav-dropdown">
                                        <Row>
                                            <Col md={12}>
                                                <h4 className="nav-heading">
                                                    <Link to="#">
                                                        Nonfiction <RiArrowRightSLine />
                                                    </Link>
                                                </h4>
                                            </Col>
                                            <Col md={10}>
                                                <Row>
                                                    <Col md={3}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6><CiStar /> Bestsellers</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/">B&amp;N Top 100</Link></li>
                                                                <li><Link to="/">NY Times Bestsellers</Link></li>
                                                                <li><Link to="/">30% Off Bestsellers</Link></li>
                                                                <li><Link to="/">All Bestsellers</Link></li>
                                                            </ul>
                                                        </div>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>Customer Favorites</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/">New Releases</Link></li>
                                                                <li><Link to="/">Coming Soon</Link></li>
                                                                <li><Link to="/">Signed Books</Link></li>
                                                            </ul>
                                                        </div>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>We Recommend</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/">B&N Monthly Picks</Link></li>
                                                                <li><Link to="/">B&N Book Club</Link></li>
                                                                <li><Link to="/">B&N Book Club</Link></li>
                                                                <li><Link to="/">B&N Bookseller Picks</Link></li>
                                                                <li><Link to="/">B&N Discover Picks</Link></li>
                                                                <li><Link to="/">B&N Book of the Year</Link></li>
                                                                <li><Link to="/">B&N Discover Prize</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={3}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>TOP SUBJECTS</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/">Bibles & Christian Living</Link></li>
                                                                <li><Link to="/">Biography</Link></li>
                                                                <li><Link to="/">Cookbooks, Food & Wine</Link></li>
                                                                <li><Link to="/">General Fiction</Link></li>
                                                                <li><Link to="/">Historical Fiction</Link></li>
                                                                <li><Link to="/">History</Link></li>
                                                                <li><Link to="/">Horror</Link></li>
                                                                <li><Link to="/">Manga</Link></li>
                                                                <li><Link to="/">Mystery & Crime</Link></li>
                                                                <li><Link to="/">Religion</Link></li>
                                                                <li><Link to="/">Romance</Link></li>
                                                                <li><Link to="/">Sci-Fi & Fantasy</Link></li>
                                                                <li><Link to="/">Self-Help & Relationships</Link></li>
                                                                <li><Link to="/">See All Subjects</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={3}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>Browse</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/">B&N Exclusives</Link></li>
                                                                <li><Link to="/">Best Books of the Year</Link></li>
                                                                <li><Link to="/">Black Voices</Link></li>
                                                                <li><Link to="/">Buy 1, Get 1 50% Off</Link></li>
                                                                <li><Link to="/">Book Awards</Link></li>
                                                                <li><Link to="/">Book Club Picks</Link></li>
                                                                <li><Link to="/">BookTok</Link></li>
                                                                <li><Link to="/">Boxed Sets</Link></li>
                                                                <li><Link to="/">Banned Books</Link></li>
                                                                <li><Link to="/">Books in Foreign Languages</Link></li>
                                                                <li><Link to="/">Mental Health Awareness</Link></li>
                                                                <li><Link to="/">Things to Do at Home</Link></li>
                                                                <li><Link to="/">Up to 30% Off Pre-Orders</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={3}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>DEPARTMENTS</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/">Audiobooks Store</Link></li>
                                                                <li><Link to="/">Audiobooks Subscriptions</Link></li>
                                                                <li><Link to="/">Paperback Store</Link></li>
                                                                <li><Link to="/">Libros en español</Link></li>
                                                                <li><Link to="/">Book Annex</Link></li>
                                                                <li><Link to="/">eBooks & NOOK</Link></li>
                                                                <li><Link to="/">Indie Books</Link></li>
                                                                <li><Link to="/">Large Print Books</Link></li>
                                                                <li><Link to="/">Books on CD</Link></li>
                                                                <li><Link to="/">Newsstand</Link></li>
                                                                <li><Link to="/">Newsstand</Link></li>
                                                                <li><Link to="/">Gift Cards</Link></li>
                                                                <li><Link to="/">Reading Accessories</Link></li>
                                                                <li><Link to="/">B&N Apps</Link></li>
                                                                <li><Link to="/">B&N Press Blog</Link></li>
                                                                <li><Link to="/">B&N Reads - Our Books Blog</Link></li>
                                                                <li><Link to="/">Poured Over - Our Podcast</Link></li>
                                                                <li><Link to="/">Poured Over - Our Podcast</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={2}>
                                                <Row>
                                                    <Col md={12}>
                                                        <Slider {...settingss}>
                                                            <div>
                                                                <div className='menu-simage'>
                                                                    <Link to="/"><img src={SliderImg} alt="" /></Link>
                                                                </div>
                                                                <div className="smenu-txt">
                                                                    <h4><Link to="/">Become a Member &amp; Save</Link>
                                                                    </h4><Link to="/">Find Out More</Link>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className='menu-simage'>
                                                                    <Link to="/"><img src={SliderImg2} alt="" /></Link>
                                                                </div>
                                                                <div className="smenu-txt">
                                                                    <h4><Link to="/">Our September Monthly Picks</Link>
                                                                    </h4><Link to="/">Explore Now</Link>
                                                                </div>
                                                            </div>
                                                        </Slider>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className='nav-item dropdown' >
                                    <NavLink to="#"
                                        data-mdb-button-init
                                        type="button"
                                        id="ebooks-nav-dropdown"
                                        data-mdb-toggle="dropdown"
                                        aria-expanded="false"
                                        data-mdb-ripple-init data-mdb-dropdown-init
                                        className='dropdown-toggle nav-link'>eBooks
                                    </NavLink>
                                    <div className="dropdown-menu" data-bs-popper="static" aria-labelledby="ebooks-nav-dropdown">
                                        <Row>
                                            <Col md={12}>
                                                <h4 className="nav-heading">
                                                    <Link to="#">
                                                        eBooks <RiArrowRightSLine />
                                                    </Link>
                                                </h4>
                                            </Col>
                                            <Col md={10}>
                                                <Row>
                                                    <Col md={3}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6><CiStar /> Bestsellers</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/">B&amp;N Top 100</Link></li>
                                                                <li><Link to="/">NY Times Bestsellers</Link></li>
                                                                <li><Link to="/">30% Off Bestsellers</Link></li>
                                                                <li><Link to="/">All Bestsellers</Link></li>
                                                            </ul>
                                                        </div>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>Customer Favorites</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/">New Releases</Link></li>
                                                                <li><Link to="/">Coming Soon</Link></li>
                                                                <li><Link to="/">Signed Books</Link></li>
                                                            </ul>
                                                        </div>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>We Recommend</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/">B&N Monthly Picks</Link></li>
                                                                <li><Link to="/">B&N Book Club</Link></li>
                                                                <li><Link to="/">B&N Book Club</Link></li>
                                                                <li><Link to="/">B&N Bookseller Picks</Link></li>
                                                                <li><Link to="/">B&N Discover Picks</Link></li>
                                                                <li><Link to="/">B&N Book of the Year</Link></li>
                                                                <li><Link to="/">B&N Discover Prize</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={3}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>TOP SUBJECTS</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/">Bibles & Christian Living</Link></li>
                                                                <li><Link to="/">Biography</Link></li>
                                                                <li><Link to="/">Cookbooks, Food & Wine</Link></li>
                                                                <li><Link to="/">General Fiction</Link></li>
                                                                <li><Link to="/">Historical Fiction</Link></li>
                                                                <li><Link to="/">History</Link></li>
                                                                <li><Link to="/">Horror</Link></li>
                                                                <li><Link to="/">Manga</Link></li>
                                                                <li><Link to="/">Mystery & Crime</Link></li>
                                                                <li><Link to="/">Religion</Link></li>
                                                                <li><Link to="/">Romance</Link></li>
                                                                <li><Link to="/">Sci-Fi & Fantasy</Link></li>
                                                                <li><Link to="/">Self-Help & Relationships</Link></li>
                                                                <li><Link to="/">See All Subjects</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={3}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>Browse</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/">B&N Exclusives</Link></li>
                                                                <li><Link to="/">Best Books of the Year</Link></li>
                                                                <li><Link to="/">Black Voices</Link></li>
                                                                <li><Link to="/">Buy 1, Get 1 50% Off</Link></li>
                                                                <li><Link to="/">Book Awards</Link></li>
                                                                <li><Link to="/">Book Club Picks</Link></li>
                                                                <li><Link to="/">BookTok</Link></li>
                                                                <li><Link to="/">Boxed Sets</Link></li>
                                                                <li><Link to="/">Banned Books</Link></li>
                                                                <li><Link to="/">Books in Foreign Languages</Link></li>
                                                                <li><Link to="/">Mental Health Awareness</Link></li>
                                                                <li><Link to="/">Things to Do at Home</Link></li>
                                                                <li><Link to="/">Up to 30% Off Pre-Orders</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={3}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>DEPARTMENTS</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/">Audiobooks Store</Link></li>
                                                                <li><Link to="/">Audiobooks Subscriptions</Link></li>
                                                                <li><Link to="/">Paperback Store</Link></li>
                                                                <li><Link to="/">Libros en español</Link></li>
                                                                <li><Link to="/">Book Annex</Link></li>
                                                                <li><Link to="/">eBooks & NOOK</Link></li>
                                                                <li><Link to="/">Indie Books</Link></li>
                                                                <li><Link to="/">Large Print Books</Link></li>
                                                                <li><Link to="/">Books on CD</Link></li>
                                                                <li><Link to="/">Newsstand</Link></li>
                                                                <li><Link to="/">Newsstand</Link></li>
                                                                <li><Link to="/">Gift Cards</Link></li>
                                                                <li><Link to="/">Reading Accessories</Link></li>
                                                                <li><Link to="/">B&N Apps</Link></li>
                                                                <li><Link to="/">B&N Press Blog</Link></li>
                                                                <li><Link to="/">B&N Reads - Our Books Blog</Link></li>
                                                                <li><Link to="/">Poured Over - Our Podcast</Link></li>
                                                                <li><Link to="/">Poured Over - Our Podcast</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={2}>
                                                <Row>
                                                    <Col md={12}>
                                                        <Slider {...settingss}>
                                                            <div>
                                                                <div className='menu-simage'>
                                                                    <Link to="/"><img src={SliderImg} alt="" /></Link>
                                                                </div>
                                                                <div className="smenu-txt">
                                                                    <h4><Link to="/">Become a Member &amp; Save</Link>
                                                                    </h4><Link to="/">Find Out More</Link>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className='menu-simage'>
                                                                    <Link to="/"><img src={SliderImg2} alt="" /></Link>
                                                                </div>
                                                                <div className="smenu-txt">
                                                                    <h4><Link to="/">Our September Monthly Picks</Link>
                                                                    </h4><Link to="/">Explore Now</Link>
                                                                </div>
                                                            </div>
                                                        </Slider>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className='nav-item dropdown' >
                                    <NavLink to="#"
                                        data-mdb-button-init
                                        type="button"
                                        id="audiobooks-nav-dropdown"
                                        data-mdb-toggle="dropdown"
                                        aria-expanded="false"
                                        data-mdb-ripple-init data-mdb-dropdown-init
                                        className='dropdown-toggle nav-link'>Audiobooks
                                    </NavLink>
                                    <div className="dropdown-menu" data-bs-popper="static" aria-labelledby="audiobooks-nav-dropdown">
                                        <Row>
                                            <Col md={12}>
                                                <h4 className="nav-heading">
                                                    <Link to="#">
                                                        Audiobooks <RiArrowRightSLine />
                                                    </Link>
                                                </h4>
                                            </Col>
                                            <Col md={10}>
                                                <Row>
                                                    <Col md={3}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6><CiStar /> Bestsellers</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/">B&amp;N Top 100</Link></li>
                                                                <li><Link to="/">NY Times Bestsellers</Link></li>
                                                                <li><Link to="/">30% Off Bestsellers</Link></li>
                                                                <li><Link to="/">All Bestsellers</Link></li>
                                                            </ul>
                                                        </div>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>Customer Favorites</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/">New Releases</Link></li>
                                                                <li><Link to="/">Coming Soon</Link></li>
                                                                <li><Link to="/">Signed Books</Link></li>
                                                            </ul>
                                                        </div>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>We Recommend</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/">B&N Monthly Picks</Link></li>
                                                                <li><Link to="/">B&N Book Club</Link></li>
                                                                <li><Link to="/">B&N Book Club</Link></li>
                                                                <li><Link to="/">B&N Bookseller Picks</Link></li>
                                                                <li><Link to="/">B&N Discover Picks</Link></li>
                                                                <li><Link to="/">B&N Book of the Year</Link></li>
                                                                <li><Link to="/">B&N Discover Prize</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={3}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>TOP SUBJECTS</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/">Bibles & Christian Living</Link></li>
                                                                <li><Link to="/">Biography</Link></li>
                                                                <li><Link to="/">Cookbooks, Food & Wine</Link></li>
                                                                <li><Link to="/">General Fiction</Link></li>
                                                                <li><Link to="/">Historical Fiction</Link></li>
                                                                <li><Link to="/">History</Link></li>
                                                                <li><Link to="/">Horror</Link></li>
                                                                <li><Link to="/">Manga</Link></li>
                                                                <li><Link to="/">Mystery & Crime</Link></li>
                                                                <li><Link to="/">Religion</Link></li>
                                                                <li><Link to="/">Romance</Link></li>
                                                                <li><Link to="/">Sci-Fi & Fantasy</Link></li>
                                                                <li><Link to="/">Self-Help & Relationships</Link></li>
                                                                <li><Link to="/">See All Subjects</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={3}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>Browse</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/">B&N Exclusives</Link></li>
                                                                <li><Link to="/">Best Books of the Year</Link></li>
                                                                <li><Link to="/">Black Voices</Link></li>
                                                                <li><Link to="/">Buy 1, Get 1 50% Off</Link></li>
                                                                <li><Link to="/">Book Awards</Link></li>
                                                                <li><Link to="/">Book Club Picks</Link></li>
                                                                <li><Link to="/">BookTok</Link></li>
                                                                <li><Link to="/">Boxed Sets</Link></li>
                                                                <li><Link to="/">Banned Books</Link></li>
                                                                <li><Link to="/">Books in Foreign Languages</Link></li>
                                                                <li><Link to="/">Mental Health Awareness</Link></li>
                                                                <li><Link to="/">Things to Do at Home</Link></li>
                                                                <li><Link to="/">Up to 30% Off Pre-Orders</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={3}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>DEPARTMENTS</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/">Audiobooks Store</Link></li>
                                                                <li><Link to="/">Audiobooks Subscriptions</Link></li>
                                                                <li><Link to="/">Paperback Store</Link></li>
                                                                <li><Link to="/">Libros en español</Link></li>
                                                                <li><Link to="/">Book Annex</Link></li>
                                                                <li><Link to="/">eBooks & NOOK</Link></li>
                                                                <li><Link to="/">Indie Books</Link></li>
                                                                <li><Link to="/">Large Print Books</Link></li>
                                                                <li><Link to="/">Books on CD</Link></li>
                                                                <li><Link to="/">Newsstand</Link></li>
                                                                <li><Link to="/">Newsstand</Link></li>
                                                                <li><Link to="/">Gift Cards</Link></li>
                                                                <li><Link to="/">Reading Accessories</Link></li>
                                                                <li><Link to="/">B&N Apps</Link></li>
                                                                <li><Link to="/">B&N Press Blog</Link></li>
                                                                <li><Link to="/">B&N Reads - Our Books Blog</Link></li>
                                                                <li><Link to="/">Poured Over - Our Podcast</Link></li>
                                                                <li><Link to="/">Poured Over - Our Podcast</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={2}>
                                                <Row>
                                                    <Col md={12}>
                                                        <Slider {...settingss}>
                                                            <div>
                                                                <div className='menu-simage'>
                                                                    <Link to="/"><img src={SliderImg} alt="" /></Link>
                                                                </div>
                                                                <div className="smenu-txt">
                                                                    <h4><Link to="/">Become a Member &amp; Save</Link>
                                                                    </h4><Link to="/">Find Out More</Link>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className='menu-simage'>
                                                                    <Link to="/"><img src={SliderImg2} alt="" /></Link>
                                                                </div>
                                                                <div className="smenu-txt">
                                                                    <h4><Link to="/">Our September Monthly Picks</Link>
                                                                    </h4><Link to="/">Explore Now</Link>
                                                                </div>
                                                            </div>
                                                        </Slider>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className='nav-item dropdown' >
                                    <NavLink to="#"
                                        data-mdb-button-init
                                        type="button"
                                        id="teens-nav-dropdown"
                                        data-mdb-toggle="dropdown"
                                        aria-expanded="false"
                                        data-mdb-ripple-init data-mdb-dropdown-init
                                        className='dropdown-toggle nav-link'>Teens & YA
                                    </NavLink>
                                    <div className="dropdown-menu" data-bs-popper="static" aria-labelledby="teens-nav-dropdown">
                                        <Row>
                                            <Col md={12}>
                                                <h4 className="nav-heading">
                                                    <Link to="#">
                                                        Teens & YA <RiArrowRightSLine />
                                                    </Link>
                                                </h4>
                                            </Col>
                                            <Col md={10}>
                                                <Row>
                                                    <Col md={3}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6><CiStar /> Bestsellers</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/">B&amp;N Top 100</Link></li>
                                                                <li><Link to="/">NY Times Bestsellers</Link></li>
                                                                <li><Link to="/">30% Off Bestsellers</Link></li>
                                                                <li><Link to="/">All Bestsellers</Link></li>
                                                            </ul>
                                                        </div>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>Customer Favorites</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/">New Releases</Link></li>
                                                                <li><Link to="/">Coming Soon</Link></li>
                                                                <li><Link to="/">Signed Books</Link></li>
                                                            </ul>
                                                        </div>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>We Recommend</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/">B&N Monthly Picks</Link></li>
                                                                <li><Link to="/">B&N Book Club</Link></li>
                                                                <li><Link to="/">B&N Book Club</Link></li>
                                                                <li><Link to="/">B&N Bookseller Picks</Link></li>
                                                                <li><Link to="/">B&N Discover Picks</Link></li>
                                                                <li><Link to="/">B&N Book of the Year</Link></li>
                                                                <li><Link to="/">B&N Discover Prize</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={3}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>TOP SUBJECTS</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/">Bibles & Christian Living</Link></li>
                                                                <li><Link to="/">Biography</Link></li>
                                                                <li><Link to="/">Cookbooks, Food & Wine</Link></li>
                                                                <li><Link to="/">General Fiction</Link></li>
                                                                <li><Link to="/">Historical Fiction</Link></li>
                                                                <li><Link to="/">History</Link></li>
                                                                <li><Link to="/">Horror</Link></li>
                                                                <li><Link to="/">Manga</Link></li>
                                                                <li><Link to="/">Mystery & Crime</Link></li>
                                                                <li><Link to="/">Religion</Link></li>
                                                                <li><Link to="/">Romance</Link></li>
                                                                <li><Link to="/">Sci-Fi & Fantasy</Link></li>
                                                                <li><Link to="/">Self-Help & Relationships</Link></li>
                                                                <li><Link to="/">See All Subjects</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={3}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>Browse</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/">B&N Exclusives</Link></li>
                                                                <li><Link to="/">Best Books of the Year</Link></li>
                                                                <li><Link to="/">Black Voices</Link></li>
                                                                <li><Link to="/">Buy 1, Get 1 50% Off</Link></li>
                                                                <li><Link to="/">Book Awards</Link></li>
                                                                <li><Link to="/">Book Club Picks</Link></li>
                                                                <li><Link to="/">BookTok</Link></li>
                                                                <li><Link to="/">Boxed Sets</Link></li>
                                                                <li><Link to="/">Banned Books</Link></li>
                                                                <li><Link to="/">Books in Foreign Languages</Link></li>
                                                                <li><Link to="/">Mental Health Awareness</Link></li>
                                                                <li><Link to="/">Things to Do at Home</Link></li>
                                                                <li><Link to="/">Up to 30% Off Pre-Orders</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={3}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>DEPARTMENTS</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/">Audiobooks Store</Link></li>
                                                                <li><Link to="/">Audiobooks Subscriptions</Link></li>
                                                                <li><Link to="/">Paperback Store</Link></li>
                                                                <li><Link to="/">Libros en español</Link></li>
                                                                <li><Link to="/">Book Annex</Link></li>
                                                                <li><Link to="/">eBooks & NOOK</Link></li>
                                                                <li><Link to="/">Indie Books</Link></li>
                                                                <li><Link to="/">Large Print Books</Link></li>
                                                                <li><Link to="/">Books on CD</Link></li>
                                                                <li><Link to="/">Newsstand</Link></li>
                                                                <li><Link to="/">Newsstand</Link></li>
                                                                <li><Link to="/">Gift Cards</Link></li>
                                                                <li><Link to="/">Reading Accessories</Link></li>
                                                                <li><Link to="/">B&N Apps</Link></li>
                                                                <li><Link to="/">B&N Press Blog</Link></li>
                                                                <li><Link to="/">B&N Reads - Our Books Blog</Link></li>
                                                                <li><Link to="/">Poured Over - Our Podcast</Link></li>
                                                                <li><Link to="/">Poured Over - Our Podcast</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={2}>
                                                <Row>
                                                    <Col md={12}>
                                                        <Slider {...settingss}>
                                                            <div>
                                                                <div className='menu-simage'>
                                                                    <Link to="/"><img src={SliderImg} alt="" /></Link>
                                                                </div>
                                                                <div className="smenu-txt">
                                                                    <h4><Link to="/">Become a Member &amp; Save</Link>
                                                                    </h4><Link to="/">Find Out More</Link>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className='menu-simage'>
                                                                    <Link to="/"><img src={SliderImg2} alt="" /></Link>
                                                                </div>
                                                                <div className="smenu-txt">
                                                                    <h4><Link to="/">Our September Monthly Picks</Link>
                                                                    </h4><Link to="/">Explore Now</Link>
                                                                </div>
                                                            </div>
                                                        </Slider>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className='nav-item dropdown' >
                                    <NavLink to="#"
                                        data-mdb-button-init
                                        type="button"
                                        id="kids-nav-dropdown"
                                        data-mdb-toggle="dropdown"
                                        aria-expanded="false"
                                        data-mdb-ripple-init data-mdb-dropdown-init
                                        className='dropdown-toggle nav-link'>Kids
                                    </NavLink>
                                    <div className="dropdown-menu" data-bs-popper="static" aria-labelledby="kids-nav-dropdown">
                                        <Row>
                                            <Col md={12}>
                                                <h4 className="nav-heading">
                                                    <Link to="#">
                                                        Kids <RiArrowRightSLine />
                                                    </Link>
                                                </h4>
                                            </Col>
                                            <Col md={10}>
                                                <Row>
                                                    <Col md={3}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6><CiStar /> Bestsellers</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/">B&amp;N Top 100</Link></li>
                                                                <li><Link to="/">NY Times Bestsellers</Link></li>
                                                                <li><Link to="/">30% Off Bestsellers</Link></li>
                                                                <li><Link to="/">All Bestsellers</Link></li>
                                                            </ul>
                                                        </div>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>Customer Favorites</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/">New Releases</Link></li>
                                                                <li><Link to="/">Coming Soon</Link></li>
                                                                <li><Link to="/">Signed Books</Link></li>
                                                            </ul>
                                                        </div>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>We Recommend</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/">B&N Monthly Picks</Link></li>
                                                                <li><Link to="/">B&N Book Club</Link></li>
                                                                <li><Link to="/">B&N Book Club</Link></li>
                                                                <li><Link to="/">B&N Bookseller Picks</Link></li>
                                                                <li><Link to="/">B&N Discover Picks</Link></li>
                                                                <li><Link to="/">B&N Book of the Year</Link></li>
                                                                <li><Link to="/">B&N Discover Prize</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={3}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>TOP SUBJECTS</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/">Bibles & Christian Living</Link></li>
                                                                <li><Link to="/">Biography</Link></li>
                                                                <li><Link to="/">Cookbooks, Food & Wine</Link></li>
                                                                <li><Link to="/">General Fiction</Link></li>
                                                                <li><Link to="/">Historical Fiction</Link></li>
                                                                <li><Link to="/">History</Link></li>
                                                                <li><Link to="/">Horror</Link></li>
                                                                <li><Link to="/">Manga</Link></li>
                                                                <li><Link to="/">Mystery & Crime</Link></li>
                                                                <li><Link to="/">Religion</Link></li>
                                                                <li><Link to="/">Romance</Link></li>
                                                                <li><Link to="/">Sci-Fi & Fantasy</Link></li>
                                                                <li><Link to="/">Self-Help & Relationships</Link></li>
                                                                <li><Link to="/">See All Subjects</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={3}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>Browse</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/">B&N Exclusives</Link></li>
                                                                <li><Link to="/">Best Books of the Year</Link></li>
                                                                <li><Link to="/">Black Voices</Link></li>
                                                                <li><Link to="/">Buy 1, Get 1 50% Off</Link></li>
                                                                <li><Link to="/">Book Awards</Link></li>
                                                                <li><Link to="/">Book Club Picks</Link></li>
                                                                <li><Link to="/">BookTok</Link></li>
                                                                <li><Link to="/">Boxed Sets</Link></li>
                                                                <li><Link to="/">Banned Books</Link></li>
                                                                <li><Link to="/">Books in Foreign Languages</Link></li>
                                                                <li><Link to="/">Mental Health Awareness</Link></li>
                                                                <li><Link to="/">Things to Do at Home</Link></li>
                                                                <li><Link to="/">Up to 30% Off Pre-Orders</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={3}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>DEPARTMENTS</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/">Audiobooks Store</Link></li>
                                                                <li><Link to="/">Audiobooks Subscriptions</Link></li>
                                                                <li><Link to="/">Paperback Store</Link></li>
                                                                <li><Link to="/">Libros en español</Link></li>
                                                                <li><Link to="/">Book Annex</Link></li>
                                                                <li><Link to="/">eBooks & NOOK</Link></li>
                                                                <li><Link to="/">Indie Books</Link></li>
                                                                <li><Link to="/">Large Print Books</Link></li>
                                                                <li><Link to="/">Books on CD</Link></li>
                                                                <li><Link to="/">Newsstand</Link></li>
                                                                <li><Link to="/">Newsstand</Link></li>
                                                                <li><Link to="/">Gift Cards</Link></li>
                                                                <li><Link to="/">Reading Accessories</Link></li>
                                                                <li><Link to="/">B&N Apps</Link></li>
                                                                <li><Link to="/">B&N Press Blog</Link></li>
                                                                <li><Link to="/">B&N Reads - Our Books Blog</Link></li>
                                                                <li><Link to="/">Poured Over - Our Podcast</Link></li>
                                                                <li><Link to="/">Poured Over - Our Podcast</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={2}>
                                                <Row>
                                                    <Col md={12}>
                                                        <Slider {...settingss}>
                                                            <div>
                                                                <div className='menu-simage'>
                                                                    <Link to="/"><img src={SliderImg} alt="" /></Link>
                                                                </div>
                                                                <div className="smenu-txt">
                                                                    <h4><Link to="/">Become a Member &amp; Save</Link>
                                                                    </h4><Link to="/">Find Out More</Link>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className='menu-simage'>
                                                                    <Link to="/"><img src={SliderImg2} alt="" /></Link>
                                                                </div>
                                                                <div className="smenu-txt">
                                                                    <h4><Link to="/">Our September Monthly Picks</Link>
                                                                    </h4><Link to="/">Explore Now</Link>
                                                                </div>
                                                            </div>
                                                        </Slider>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className='nav-item dropdown' >
                                    <NavLink to="#"
                                        data-mdb-button-init
                                        type="button"
                                        id="toys-games-nav-dropdown"
                                        data-mdb-toggle="dropdown"
                                        aria-expanded="false"
                                        data-mdb-ripple-init data-mdb-dropdown-init
                                        className='dropdown-toggle nav-link'>Toys & Games
                                    </NavLink>
                                    <div className="dropdown-menu" data-bs-popper="static" aria-labelledby="toys-games-nav-dropdown">
                                        <Row>
                                            <Col md={12}>
                                                <h4 className="nav-heading">
                                                    <Link to="#">
                                                        Toys & Games <RiArrowRightSLine />
                                                    </Link>
                                                </h4>
                                            </Col>
                                            <Col md={10}>
                                                <Row>
                                                    <Col md={3}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6><CiStar /> Bestsellers</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/">B&amp;N Top 100</Link></li>
                                                                <li><Link to="/">NY Times Bestsellers</Link></li>
                                                                <li><Link to="/">30% Off Bestsellers</Link></li>
                                                                <li><Link to="/">All Bestsellers</Link></li>
                                                            </ul>
                                                        </div>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>Customer Favorites</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/">New Releases</Link></li>
                                                                <li><Link to="/">Coming Soon</Link></li>
                                                                <li><Link to="/">Signed Books</Link></li>
                                                            </ul>
                                                        </div>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>We Recommend</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/">B&N Monthly Picks</Link></li>
                                                                <li><Link to="/">B&N Book Club</Link></li>
                                                                <li><Link to="/">B&N Book Club</Link></li>
                                                                <li><Link to="/">B&N Bookseller Picks</Link></li>
                                                                <li><Link to="/">B&N Discover Picks</Link></li>
                                                                <li><Link to="/">B&N Book of the Year</Link></li>
                                                                <li><Link to="/">B&N Discover Prize</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={3}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>TOP SUBJECTS</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/">Bibles & Christian Living</Link></li>
                                                                <li><Link to="/">Biography</Link></li>
                                                                <li><Link to="/">Cookbooks, Food & Wine</Link></li>
                                                                <li><Link to="/">General Fiction</Link></li>
                                                                <li><Link to="/">Historical Fiction</Link></li>
                                                                <li><Link to="/">History</Link></li>
                                                                <li><Link to="/">Horror</Link></li>
                                                                <li><Link to="/">Manga</Link></li>
                                                                <li><Link to="/">Mystery & Crime</Link></li>
                                                                <li><Link to="/">Religion</Link></li>
                                                                <li><Link to="/">Romance</Link></li>
                                                                <li><Link to="/">Sci-Fi & Fantasy</Link></li>
                                                                <li><Link to="/">Self-Help & Relationships</Link></li>
                                                                <li><Link to="/">See All Subjects</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={3}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>Browse</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/">B&N Exclusives</Link></li>
                                                                <li><Link to="/">Best Books of the Year</Link></li>
                                                                <li><Link to="/">Black Voices</Link></li>
                                                                <li><Link to="/">Buy 1, Get 1 50% Off</Link></li>
                                                                <li><Link to="/">Book Awards</Link></li>
                                                                <li><Link to="/">Book Club Picks</Link></li>
                                                                <li><Link to="/">BookTok</Link></li>
                                                                <li><Link to="/">Boxed Sets</Link></li>
                                                                <li><Link to="/">Banned Books</Link></li>
                                                                <li><Link to="/">Books in Foreign Languages</Link></li>
                                                                <li><Link to="/">Mental Health Awareness</Link></li>
                                                                <li><Link to="/">Things to Do at Home</Link></li>
                                                                <li><Link to="/">Up to 30% Off Pre-Orders</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={3}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>DEPARTMENTS</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/">Audiobooks Store</Link></li>
                                                                <li><Link to="/">Audiobooks Subscriptions</Link></li>
                                                                <li><Link to="/">Paperback Store</Link></li>
                                                                <li><Link to="/">Libros en español</Link></li>
                                                                <li><Link to="/">Book Annex</Link></li>
                                                                <li><Link to="/">eBooks & NOOK</Link></li>
                                                                <li><Link to="/">Indie Books</Link></li>
                                                                <li><Link to="/">Large Print Books</Link></li>
                                                                <li><Link to="/">Books on CD</Link></li>
                                                                <li><Link to="/">Newsstand</Link></li>
                                                                <li><Link to="/">Newsstand</Link></li>
                                                                <li><Link to="/">Gift Cards</Link></li>
                                                                <li><Link to="/">Reading Accessories</Link></li>
                                                                <li><Link to="/">B&N Apps</Link></li>
                                                                <li><Link to="/">B&N Press Blog</Link></li>
                                                                <li><Link to="/">B&N Reads - Our Books Blog</Link></li>
                                                                <li><Link to="/">Poured Over - Our Podcast</Link></li>
                                                                <li><Link to="/">Poured Over - Our Podcast</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={2}>
                                                <Row>
                                                    <Col md={12}>
                                                        <Slider {...settingss}>
                                                            <div>
                                                                <div className='menu-simage'>
                                                                    <Link to="/"><img src={SliderImg} alt="" /></Link>
                                                                </div>
                                                                <div className="smenu-txt">
                                                                    <h4><Link to="/">Become a Member &amp; Save</Link>
                                                                    </h4><Link to="/">Find Out More</Link>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className='menu-simage'>
                                                                    <Link to="/"><img src={SliderImg2} alt="" /></Link>
                                                                </div>
                                                                <div className="smenu-txt">
                                                                    <h4><Link to="/">Our September Monthly Picks</Link>
                                                                    </h4><Link to="/">Explore Now</Link>
                                                                </div>
                                                            </div>
                                                        </Slider>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className='nav-item dropdown' >
                                    <NavLink to="#"
                                        data-mdb-button-init
                                        type="button"
                                        id="stationery-nav-dropdown"
                                        data-mdb-toggle="dropdown"
                                        aria-expanded="false"
                                        data-mdb-ripple-init data-mdb-dropdown-init
                                        className='dropdown-toggle nav-link'>Stationery & Gifts
                                    </NavLink>
                                    <div className="dropdown-menu" data-bs-popper="static" aria-labelledby="stationery-nav-dropdown">
                                        <Row>
                                            <Col md={12}>
                                                <h4 className="nav-heading">
                                                    <Link to="#">
                                                        Stationery & Gifts <RiArrowRightSLine />
                                                    </Link>
                                                </h4>
                                            </Col>
                                            <Col md={10}>
                                                <Row>
                                                    <Col md={3}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6><CiStar /> Bestsellers</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/">B&amp;N Top 100</Link></li>
                                                                <li><Link to="/">NY Times Bestsellers</Link></li>
                                                                <li><Link to="/">30% Off Bestsellers</Link></li>
                                                                <li><Link to="/">All Bestsellers</Link></li>
                                                            </ul>
                                                        </div>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>Customer Favorites</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/">New Releases</Link></li>
                                                                <li><Link to="/">Coming Soon</Link></li>
                                                                <li><Link to="/">Signed Books</Link></li>
                                                            </ul>
                                                        </div>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>We Recommend</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/">B&N Monthly Picks</Link></li>
                                                                <li><Link to="/">B&N Book Club</Link></li>
                                                                <li><Link to="/">B&N Book Club</Link></li>
                                                                <li><Link to="/">B&N Bookseller Picks</Link></li>
                                                                <li><Link to="/">B&N Discover Picks</Link></li>
                                                                <li><Link to="/">B&N Book of the Year</Link></li>
                                                                <li><Link to="/">B&N Discover Prize</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={3}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>TOP SUBJECTS</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/">Bibles & Christian Living</Link></li>
                                                                <li><Link to="/">Biography</Link></li>
                                                                <li><Link to="/">Cookbooks, Food & Wine</Link></li>
                                                                <li><Link to="/">General Fiction</Link></li>
                                                                <li><Link to="/">Historical Fiction</Link></li>
                                                                <li><Link to="/">History</Link></li>
                                                                <li><Link to="/">Horror</Link></li>
                                                                <li><Link to="/">Manga</Link></li>
                                                                <li><Link to="/">Mystery & Crime</Link></li>
                                                                <li><Link to="/">Religion</Link></li>
                                                                <li><Link to="/">Romance</Link></li>
                                                                <li><Link to="/">Sci-Fi & Fantasy</Link></li>
                                                                <li><Link to="/">Self-Help & Relationships</Link></li>
                                                                <li><Link to="/">See All Subjects</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={3}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>Browse</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/">B&N Exclusives</Link></li>
                                                                <li><Link to="/">Best Books of the Year</Link></li>
                                                                <li><Link to="/">Black Voices</Link></li>
                                                                <li><Link to="/">Buy 1, Get 1 50% Off</Link></li>
                                                                <li><Link to="/">Book Awards</Link></li>
                                                                <li><Link to="/">Book Club Picks</Link></li>
                                                                <li><Link to="/">BookTok</Link></li>
                                                                <li><Link to="/">Boxed Sets</Link></li>
                                                                <li><Link to="/">Banned Books</Link></li>
                                                                <li><Link to="/">Books in Foreign Languages</Link></li>
                                                                <li><Link to="/">Mental Health Awareness</Link></li>
                                                                <li><Link to="/">Things to Do at Home</Link></li>
                                                                <li><Link to="/">Up to 30% Off Pre-Orders</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={3}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>DEPARTMENTS</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/">Audiobooks Store</Link></li>
                                                                <li><Link to="/">Audiobooks Subscriptions</Link></li>
                                                                <li><Link to="/">Paperback Store</Link></li>
                                                                <li><Link to="/">Libros en español</Link></li>
                                                                <li><Link to="/">Book Annex</Link></li>
                                                                <li><Link to="/">eBooks & NOOK</Link></li>
                                                                <li><Link to="/">Indie Books</Link></li>
                                                                <li><Link to="/">Large Print Books</Link></li>
                                                                <li><Link to="/">Books on CD</Link></li>
                                                                <li><Link to="/">Newsstand</Link></li>
                                                                <li><Link to="/">Newsstand</Link></li>
                                                                <li><Link to="/">Gift Cards</Link></li>
                                                                <li><Link to="/">Reading Accessories</Link></li>
                                                                <li><Link to="/">B&N Apps</Link></li>
                                                                <li><Link to="/">B&N Press Blog</Link></li>
                                                                <li><Link to="/">B&N Reads - Our Books Blog</Link></li>
                                                                <li><Link to="/">Poured Over - Our Podcast</Link></li>
                                                                <li><Link to="/">Poured Over - Our Podcast</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={2}>
                                                <Row>
                                                    <Col md={12}>
                                                        <Slider {...settingss}>
                                                            <div>
                                                                <div className='menu-simage'>
                                                                    <Link to="/"><img src={SliderImg} alt="" /></Link>
                                                                </div>
                                                                <div className="smenu-txt">
                                                                    <h4><Link to="/">Become a Member &amp; Save</Link>
                                                                    </h4><Link to="/">Find Out More</Link>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className='menu-simage'>
                                                                    <Link to="/"><img src={SliderImg2} alt="" /></Link>
                                                                </div>
                                                                <div className="smenu-txt">
                                                                    <h4><Link to="/">Our September Monthly Picks</Link>
                                                                    </h4><Link to="/">Explore Now</Link>
                                                                </div>
                                                            </div>
                                                        </Slider>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className='nav-item dropdown' >
                                    <NavLink to="#"
                                        data-mdb-button-init
                                        type="button"
                                        id="music-movies-nav-dropdown"
                                        data-mdb-toggle="dropdown"
                                        aria-expanded="false"
                                        data-mdb-ripple-init data-mdb-dropdown-init
                                        className='dropdown-toggle nav-link'>Music & Movies
                                    </NavLink>
                                    <div className="dropdown-menu" data-bs-popper="static" aria-labelledby="music-movies-nav-dropdown">
                                        <Row>
                                            <Col md={12}>
                                                <h4 className="nav-heading">
                                                    <Link to="#">
                                                        Music & Movies <RiArrowRightSLine />
                                                    </Link>
                                                </h4>
                                            </Col>
                                            <Col md={10}>
                                                <Row>
                                                    <Col md={3}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6><CiStar /> Bestsellers</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/">B&amp;N Top 100</Link></li>
                                                                <li><Link to="/">NY Times Bestsellers</Link></li>
                                                                <li><Link to="/">30% Off Bestsellers</Link></li>
                                                                <li><Link to="/">All Bestsellers</Link></li>
                                                            </ul>
                                                        </div>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>Customer Favorites</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/">New Releases</Link></li>
                                                                <li><Link to="/">Coming Soon</Link></li>
                                                                <li><Link to="/">Signed Books</Link></li>
                                                            </ul>
                                                        </div>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>We Recommend</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/">B&N Monthly Picks</Link></li>
                                                                <li><Link to="/">B&N Book Club</Link></li>
                                                                <li><Link to="/">B&N Book Club</Link></li>
                                                                <li><Link to="/">B&N Bookseller Picks</Link></li>
                                                                <li><Link to="/">B&N Discover Picks</Link></li>
                                                                <li><Link to="/">B&N Book of the Year</Link></li>
                                                                <li><Link to="/">B&N Discover Prize</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={3}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>TOP SUBJECTS</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/">Bibles & Christian Living</Link></li>
                                                                <li><Link to="/">Biography</Link></li>
                                                                <li><Link to="/">Cookbooks, Food & Wine</Link></li>
                                                                <li><Link to="/">General Fiction</Link></li>
                                                                <li><Link to="/">Historical Fiction</Link></li>
                                                                <li><Link to="/">History</Link></li>
                                                                <li><Link to="/">Horror</Link></li>
                                                                <li><Link to="/">Manga</Link></li>
                                                                <li><Link to="/">Mystery & Crime</Link></li>
                                                                <li><Link to="/">Religion</Link></li>
                                                                <li><Link to="/">Romance</Link></li>
                                                                <li><Link to="/">Sci-Fi & Fantasy</Link></li>
                                                                <li><Link to="/">Self-Help & Relationships</Link></li>
                                                                <li><Link to="/">See All Subjects</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={3}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>Browse</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/">B&N Exclusives</Link></li>
                                                                <li><Link to="/">Best Books of the Year</Link></li>
                                                                <li><Link to="/">Black Voices</Link></li>
                                                                <li><Link to="/">Buy 1, Get 1 50% Off</Link></li>
                                                                <li><Link to="/">Book Awards</Link></li>
                                                                <li><Link to="/">Book Club Picks</Link></li>
                                                                <li><Link to="/">BookTok</Link></li>
                                                                <li><Link to="/">Boxed Sets</Link></li>
                                                                <li><Link to="/">Banned Books</Link></li>
                                                                <li><Link to="/">Books in Foreign Languages</Link></li>
                                                                <li><Link to="/">Mental Health Awareness</Link></li>
                                                                <li><Link to="/">Things to Do at Home</Link></li>
                                                                <li><Link to="/">Up to 30% Off Pre-Orders</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={3}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>DEPARTMENTS</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/">Audiobooks Store</Link></li>
                                                                <li><Link to="/">Audiobooks Subscriptions</Link></li>
                                                                <li><Link to="/">Paperback Store</Link></li>
                                                                <li><Link to="/">Libros en español</Link></li>
                                                                <li><Link to="/">Book Annex</Link></li>
                                                                <li><Link to="/">eBooks & NOOK</Link></li>
                                                                <li><Link to="/">Indie Books</Link></li>
                                                                <li><Link to="/">Large Print Books</Link></li>
                                                                <li><Link to="/">Books on CD</Link></li>
                                                                <li><Link to="/">Newsstand</Link></li>
                                                                <li><Link to="/">Newsstand</Link></li>
                                                                <li><Link to="/">Gift Cards</Link></li>
                                                                <li><Link to="/">Reading Accessories</Link></li>
                                                                <li><Link to="/">B&N Apps</Link></li>
                                                                <li><Link to="/">B&N Press Blog</Link></li>
                                                                <li><Link to="/">B&N Reads - Our Books Blog</Link></li>
                                                                <li><Link to="/">Poured Over - Our Podcast</Link></li>
                                                                <li><Link to="/">Poured Over - Our Podcast</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={2}>
                                                <Row>
                                                    <Col md={12}>
                                                        <Slider {...settingss}>
                                                            <div>
                                                                <div className='menu-simage'>
                                                                    <Link to="/"><img src={SliderImg} alt="" /></Link>
                                                                </div>
                                                                <div className="smenu-txt">
                                                                    <h4><Link to="/">Become a Member &amp; Save</Link>
                                                                    </h4><Link to="/">Find Out More</Link>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className='menu-simage'>
                                                                    <Link to="/"><img src={SliderImg2} alt="" /></Link>
                                                                </div>
                                                                <div className="smenu-txt">
                                                                    <h4><Link to="/">Our September Monthly Picks</Link>
                                                                    </h4><Link to="/">Explore Now</Link>
                                                                </div>
                                                            </div>
                                                        </Slider>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </div> */}
                            </div>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div >
            {/* <Navbar expand="lg" className="nav_bar" data-bs-theme="dark">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <div className='row' >

                        <div className="header_input">
                            <form onSubmit={handleSearch} className='searchbar'>
                                <InputGroup className="mb-3 ">
                                    <Form.Control
                                        placeholder="Search All Products"
                                        value={searchQuery}
                                        onChange={handleInputChange}
                                    />
                                    <button type='submit'><CiSearch /></button>
                                </InputGroup>
                            </form>
                            {isLoggedIn ? (
                                <NavLink to='dashboard/overview'>
                                    <i className='header_input_icon'><GrUserManager /></i>
                                </NavLink>
                            ) : (
                                <NavLink to='/login'>
                                    <i className='header_input_icon'><RiLoginBoxLine /></i>
                                </NavLink>
                            )}
                            <NavLink to='#'>
                                <Badge badgeContent={getdata.length} color="primary"
                                    id="basic-button"
                                    aria-controls={open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}
                                >
                                    <i className='header_input_icon' ><CgShoppingBag /></i>
                                </Badge>
                            </NavLink>
                        </div>
                        <Nav className="nav_bar_a">
                            <NavLink to='/' className='nav_link'>Home</NavLink>
                            <NavLink to='about' className='nav_link'>About</NavLink>
                            <NavDropdown title="Shop" id="basic-nav-dropdown" className='navs_link'>
                                <NavDropdown.Item as={Link} to="/shop?search=face care" className='nav_dropdown'>Face Care</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/shop?search=hair care" className='nav_dropdown'>Hair Care</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/shop?search=body care" className='nav_dropdown'>Body Care</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/shop?search=eye Care" className='nav_dropdown'>Eye Care</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/shop?search=lip Care" className='nav_dropdown'>Lip Care</NavDropdown.Item>
                            </NavDropdown>
                            <NavLink to='testimonials' className='nav_link'>Testimonials</NavLink>
                        </Nav>
                    </div>
                </Navbar.Collapse>
            </Navbar> */}
        </>
    )
}

export default HeaderNav;
