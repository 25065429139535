import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Img from '../images/dealimg01.png'
import Img2 from '../images/dealimg02.png'
import { Link } from 'react-router-dom'
import { FaStar } from "react-icons/fa";
import CountdownTimer from './CountdownTimer'
import { useDispatch, useSelector } from 'react-redux';
import { fetchSettings, fetchProductBy } from '../Redux/actions/settingsActions';

const Deals = () => {
    // ******* CMS START ******* 
    const dispatch = useDispatch();
    const { settings, products, loading, error } = useSelector(state => state.settings);

    useEffect(() => {
        dispatch(fetchSettings());
        dispatch(fetchProductBy());
    }, [dispatch]);

    const Deals_week = settings.find(setting => setting.name === 'Deals_week');
    // console.log('Deals_week', Deals_week);
    const productId = Deals_week?.value;
    const productData = products.find(product => product.product_translation?.id.toString() === productId);

    const Deals_week_second = settings.find(setting => setting.name === 'Deals_week_second');
    const productId2 = Deals_week_second?.value;
    const productData2 = products.find(product => product.product_translation?.id.toString() === productId2);
    if (!Deals_week) return <p>Loading...</p>;
    if (!productData) return <p>Loading...</p>;
    // Deals of the week section End 
    // time section start 
    const target_date_heading = settings.find(setting => setting.name === 'target_date_heading');
    const target_date = settings.find(setting => setting.name === 'target_date');
    const target_date_second = settings.find(setting => setting.name === 'target_date_second');
    const targetDate = target_date?.value;
    const targetDate1 = target_date_second?.value;
    // const targetDate = '2024-12-31T00:00:00';
    // time section end 
    const section_heading_3 = settings.find(setting => setting.name === 'section_heading_3');
    // ******* CMS END ******* 
    const imagestwo = productData.product_translation.product.imagestwo;
    const imagestwo2 = productData2.product_translation.product.imagestwo;

    const cleanImageUrls = (imageString) => {
        return imageString
            .replace(/[\\[\]"]/g, '')
            .split(',')
            .map(url => url.trim())
            .map(url =>
                url.includes('/storage/app/')
                    ? url
                    : url.replace('/storage/', '/storage/app/')
            );
    };
    const cleanedUrls1 = cleanImageUrls(imagestwo);
    const cleanedUrls2 = cleanImageUrls(imagestwo2);
    return (
        <>
            <section>
                <div className="deal-week">
                    <Container>
                        <h2 className="head-sec">{section_heading_3?.value}</h2>
                        <Row>
                            <Col md={6} sm={12} >
                                <Row>
                                    <Col lg={5} md={6} sm={6} xs={12} className='deal-week-card'>
                                        <div className="img-holder  deals-img">
                                            <Link to={`/single-product/${productData2.product_translation.product_id}`}>
                                                {/* {cleanedUrls1[0] && (
                                                    <img className="card_img" src={cleanedUrls1[0]} />
                                                )} */}
                                                <img className="card_img" src={Img} />
                                            </Link>
                                        </div>
                                    </Col>
                                    <Col lg={7} md={6} sm={6} >

                                        <div className="content-area">
                                            <h3><Link to={"/single-product/" + 1}>{productData.product_translation.name}</Link></h3>
                                            <div className="rate-area">
                                                <ul>
                                                    <li><FaStar /></li>
                                                    <li><FaStar /></li>
                                                    <li><FaStar /></li>
                                                    <li><FaStar /></li>
                                                    <li><FaStar /></li>
                                                </ul>
                                                <span>Reviews (4)</span>
                                            </div>
                                            <div className="new-prices">
                                                <span>${productData.product_translation.product.price}</span><del>${productData.product_translation.product.special_price}</del>
                                            </div>
                                            <strong>{target_date_heading?.value}</strong>
                                            <CountdownTimer targetDate={targetDate} />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={6} sm={12}>
                                <Row>
                                    <Col lg={5} md={6} sm={6} className='deal-week-card'>
                                        <div className="img-holder  deals-img">
                                            <Link to={`/single-product/${productData2.product_translation.product_id}`}>
                                                {/* {cleanedUrls2[0] && (
                                                    <img className="card_img" src={cleanedUrls2[0]} />
                                                )} */}
                                                <img className="card_img" src={Img2} />

                                            </Link>
                                        </div>
                                    </Col>
                                    <Col lg={7} md={6} sm={6}>
                                        <div className="content-area">
                                            <h3><Link to={"/single-product/" + 1}>{productData2.product_translation.name}</Link></h3>
                                            <div className="rate-area">
                                                <ul>
                                                    <li><FaStar /></li>
                                                    <li><FaStar /></li>
                                                    <li><FaStar /></li>
                                                    <li><FaStar /></li>
                                                    <li><FaStar /></li>
                                                </ul>
                                                <span>Reviews (4)</span>
                                            </div>
                                            <div className="new-prices">
                                                <span>${productData2.product_translation.product.price}</span><del>${productData2.product_translation.product.special_price}</del>
                                            </div>
                                            <strong>{target_date_heading?.value}</strong>
                                            <CountdownTimer targetDate={targetDate1} />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                    </Container>
                </div>
            </section>
        </>
    )
}

export default Deals