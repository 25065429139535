import React from 'react'
import OurBrands from '../../components/OurBrands'
import HeroSlider from '../../components/HeroSlider'
import Category from '../../components/Category'
import BestSeller from '../../components/BestSeller'
import Testimonials from '../../components/Testimonials'
import BestSellerBook from '../../components/BestSellerBook'
import FeaturedProducts from '../../components/FeaturedProducts'
import Collections from '../../components/Collections'
import Deals from '../../components/Deals'

const Home = () => {
    return (
        <>
            <HeroSlider />
            <OurBrands />
            <Category />
            <BestSeller />
            <BestSellerBook />
            <FeaturedProducts />
            <Collections />
            <Deals />
            <Testimonials />
        </>
    )
}

export default Home