import React from 'react'
import { Link } from 'react-router-dom'

const PageNotFound = () => {
    return (
        <>
            <div className="container">
                <div className='page-not-found'>
                    <h2>404</h2>
                    <h3>UH OH! You're lost.</h3>
                    <p>
                        The page you are looking for does not exist. How you got here is a
                        mystery. But you can click the button below to go back to the
                        homepage.
                    </p>
                    <button className='btn-style blue-btn'> <Link to="/">Go To Home</Link></button>
                </div>
            </div>
        </>
    )
}

export default PageNotFound