import React, { useEffect, useState } from 'react';
import { FaArrowRight } from "react-icons/fa6";
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSettings } from '../Redux/actions/settingsActions';


const HeroSlider = () => {
  // ******* CMS START ******* 
  const dispatch = useDispatch();
  const { settings, loading, error } = useSelector(state => state.settings);
  useEffect(() => {
    dispatch(fetchSettings());
  }, [dispatch]);
  const hero_image_title = settings.find(setting => setting.name === 'hero_image_title');
  const hero_image_title_2 = settings.find(setting => setting.name === 'hero_image_title_2');
  const hero_image_des = settings.find(setting => setting.name === 'hero_image_des');
  const hero_image_btn_txt = settings.find(setting => setting.name === 'hero_image_btn_txt');
  const hero_image_btn_l = settings.find(setting => setting.name === 'hero_image_btn_l');
  // For image 
  const Hero_image = settings.find(setting => setting.name === 'Hero_image');
  const baseImageUrl = process.env.REACT_APP_BASE_URL + '/public/uploads/media/';
  const imageUrl = Hero_image?.value ? `${baseImageUrl}${Hero_image.value}` : ''; 
  // ******* CMS END ******* 

  // ******* IMAGE HOVER ANIMATION START ******* 
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const handleMouseMove = (event) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const posX = event.clientX - (rect.left + rect.width / 2);
    const posY = event.clientY - (rect.top + rect.height / 2);
    const rotateX = (posY / rect.height) * 15;
    const rotateY = -(posX / rect.width) * 15;
    setOffset({
      x: posX * 0.012, y: posY * 0.012,
      rotateX, rotateY,
    });
  };
  const resetOffset = () => {
    setOffset({ x: 0, y: 0, rotateX: 0, rotateY: 0 });
  };
  // ******* IMAGE HOVER ANIMATION END ******* 

  return (
    <>
      <div className="main-slider">
        <Container>
          <Row>
            <Col lg={7} md={6} sm={12}>
              <div className="slide-lside">
                <h5>{hero_image_title?.value}</h5>
                <h2>{hero_image_title_2?.value}</h2>
                <div dangerouslySetInnerHTML={{ __html: hero_image_des?.value }} />
                <Link className="btn-style blue-btn" to={hero_image_btn_l?.value}>{hero_image_btn_txt?.value} <FaArrowRight /></Link></div>
            </Col>
            <Col lg={5} md={6} sm={12}>
              <div className="slide-rside">
                <div className="parallax-container">
                  <img
                    src={imageUrl}
                    alt="Hero Img"
                    onMouseMove={handleMouseMove}
                    onMouseLeave={resetOffset}
                    style={{
                      willChange: 'transform',
                      transition: 'transform 1000ms cubic-bezier(0.03, 0.98, 0.52, 0.99)',
                      transform: `perspective(800px) rotateX(${offset.rotateX}deg) rotateY(${offset.rotateY}deg) scale3d(1, 1, 1) translate(${offset.x}%, ${offset.y}%)`,
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

    </>
  );
}

export default HeroSlider