import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";  
import Slider from 'react-slick';


import Img1 from '../images/audio-books.png';
import Img2 from '../images/best-seller.png';
import Img3 from '../images/new-releases.png';
import Img4 from '../images/signed-edition.png';
import Img5 from '../images/horror.png';

import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSettings } from '../Redux/actions/settingsActions';



function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}>
      <FaArrowRight />
    </div>
  );
}
function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}>
      <FaArrowLeft />
    </div>
  );
}
const OurBrands = () => {
  var setting = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,

        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };
  const dispatch = useDispatch();
  const { settings, loading, error } = useSelector(state => state.settings);
  useEffect(() => {
    dispatch(fetchSettings());
  }, [dispatch]);

  useEffect(() => {
    AOS.init();
  }, []);


  // ************ CMS ************
  const brands_title = settings.find(setting => setting.name === 'brands_title');
  const our_brands_image = settings.find(setting => setting.name === 'our_brands_image');
  const our_brands_image_sec = settings.find(setting => setting.name === 'our_brands_image_sec');
  const baseImageUrl = process.env.REACT_APP_BASE_URL + '/public/uploads/media/';
  const Logo = our_brands_image?.value ? `${baseImageUrl}${our_brands_image.value}` : ''; // Construct the full image URL
  const Logo2 = our_brands_image_sec?.value ? `${baseImageUrl}${our_brands_image_sec.value}` : ''; // Construct the full image URL

  return (
    <section>
      <div className="container">
        <div className="book-cat">
          <Slider {...setting}>
            <div className="bc-box">
              <div className="bc-image">
                <Link to="/products">
                  <img src={Img1} alt="image" />
                </Link>
              </div>
              <div className="bc-content">
                <h3><Link to="/products">AUDIO BOOKS</Link></h3>
              </div>
            </div>
            <div className="bc-box">
              <div className="bc-image">
                <Link to="/products">
                  <img src={Img2} alt="image" />
                </Link>
              </div>
              <div className="bc-content">
                <h3><Link to="/products">Best sellers</Link></h3>
              </div>
            </div>
            <div className="bc-box">
              <div className="bc-image">
                <Link to="/products">
                  <img src={Img3} alt="image" />
                </Link>
              </div>
              <div className="bc-content">
                <h3><Link to="/products">new releases</Link></h3>
              </div>
            </div>
            <div className="bc-box">
              <div className="bc-image">
                <Link to="/products">
                  <img src={Img4} alt="image" />
                </Link>
              </div>
              <div className="bc-content">
                <h3><Link to="/products">Signed edition</Link></h3>
              </div>
            </div>
            <div className="bc-box">
              <div className="bc-image">
                <Link to="/products">
                  <img src={Img5} alt="image" />
                </Link>
              </div>
              <div className="bc-content">
                <h3><Link to="/products">Horror</Link></h3>
              </div>
            </div>
          </Slider>
        </div>
        {/* <Slider {...setting}>
              <div className='slider_imge' >
                <Link to='/shop?search=2'>
                  <img src={Logo} alt=""
                    data-aos="fade-right" data-aos-duration="3000" data-aos-easing="linear"
                  />
                </Link>
              </div>
              <div className='slider_imge'>
                <Link to='shop?search=1'>
                  <img src={Logo2} alt=""
                    data-aos="fade-left" data-aos-duration="3000" data-aos-easing="linear"
                  />
                </Link>
              </div>
            </Slider> */}
      </div>
    </section>
  )
}

export default OurBrands