import { NavLink } from "react-router-dom";
import { FaUsersViewfinder } from "react-icons/fa6";
import { FaShoppingCart, FaHeart } from "react-icons/fa";
// import { IoLocation } from "react-icons/io5";
import { FaPowerOff } from "react-icons/fa6";
import { MdAccountBox, MdPayments } from "react-icons/md";
import { IoMdCloudDownload } from "react-icons/io";
import { IoLibrarySharp   } from "react-icons/io5";


import { FaBars } from "react-icons/fa";
import Logo from "../../../images/logo.png"
import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import SidebarMenu from "./SidebarMenu";

import { useDispatch } from 'react-redux';
import { logout } from '../../../Redux/actions/authActions';
const routes = [
    {
        path: "/",
        name: "overview",
        icon: <FaUsersViewfinder />,
        subRoutes: [
            {
                path: "overview",
                name: "overview",
            }
        ],
    },
    {
        path: "/",
        name: "My Library",
        icon: <IoLibrarySharp />,
        subRoutes: [
            {
                path: "library",
                name: "My Library ",
            }

        ],
    },
    {
        path: "/",
        name: "Wishlist",
        icon: <FaHeart />,
        subRoutes: [
            {
                path: "wishlist",
                name: "Wishlist ",
            }

        ],
    },
    {
        path: "/",
        name: "Order History",
        icon: <FaShoppingCart />,
        subRoutes: [
            {
                path: "orders",
                name: "My Orders ",
            }

        ],
    },
    {
        path: "/",
        name: "Account Settings",
        icon: <MdAccountBox  />,
        subRoutes: [
            {
                path: "account-setting",
                name: "Account Settings ",
            }

        ],
    },
    {
        path: "/",
        name: "Download Manager",
        icon: <IoMdCloudDownload />,
        subRoutes: [
            {
                path: "download-manager",
                name: "Download Manager ",
            }

        ],
    },
    {
        path: "/",
        name: "Payment Methods",
        icon: <MdPayments  />,
        subRoutes: [
            {
                path: "payment-methods",
                name: "Payment Methods ",
            }

        ],
    }
    // {
    //     path: "/",
    //     name: "My Addresses",
    //     icon: <IoLocation />,
    //     subRoutes: [
    //         {
    //             path: "addresses",
    //             name: "My Addresses ",
    //         }

    //     ],
    // }
];

const SideBar = ({ children }) => {

    const dispatch = useDispatch();
    const handleLogout = () => {
        dispatch(logout());
        localStorage.removeItem('user');
    };

    const [isOpen, setIsOpen] = useState(true);
    const toggle = () => setIsOpen(!isOpen);
    const inputAnimation = {
        hidden: {
            width: 0,
            padding: 0,
            transition: {
                duration: 0.2,
            },
        },
        show: {
            width: "140px",
            padding: "5px 15px",
            transition: {
                duration: 0.2,
            },
        },
    };

    const showAnimation = {
        hidden: {
            width: 0,
            opacity: 0,
            transition: {
                duration: 0.5,
            },
        },
        show: {
            opacity: 1,
            width: "auto",
            transition: {
                duration: 0.5,
            },
        },
    };

    return (
        <>
            <div className="main-container">
                <motion.div
                    animate={{
                        width: isOpen ? "280px" : "55px",
                        transition: {
                            duration: 0.5,
                            type: "spring",
                            damping: 10,
                        },
                    }}
                    className={`sidebar `}
                >
                    <div className="top_section">
                        <AnimatePresence>
                            {isOpen && (
                                <motion.h1 variants={showAnimation} initial="hidden" animate="show" exit="hidden" className="logo">
                                    <img src={Logo} alt="LOGO" className="black-to-white" />
                                </motion.h1>
                            )}
                        </AnimatePresence>

                        <div className="bars">
                            <FaBars onClick={toggle} />
                        </div>
                    </div>
                    <section className="routes">
                        {routes.map((route, index) => {
                            if (route.subRoutes) {
                                return (
                                    <SidebarMenu setIsOpen={setIsOpen} route={route} showAnimation={showAnimation} isOpen={isOpen} />
                                );
                            }
                            return (
                                <NavLink to={route.path} key={index} className="link" activeClassName="active">
                                    <div className="icon">{route.icon}</div>
                                    <AnimatePresence>
                                        {isOpen && (
                                            <motion.div variants={showAnimation} initial="hidden" animate="show" exit="hidden" className="link_text">
                                                {route.name}
                                            </motion.div>
                                        )}
                                    </AnimatePresence>
                                </NavLink>
                            );
                        })}
                        <NavLink to="/" className="link" activeClassName="active" onClick={handleLogout}>
                            <div className="icon"><FaPowerOff /></div>
                            <AnimatePresence>
                                {isOpen && (
                                    <motion.div variants={showAnimation} initial="hidden" animate="show" exit="hidden" className="link_text">
                                        Logout
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </NavLink>
                    </section>
                </motion.div>

                <main style={{ width: isOpen ? "81.6%" : "96.1%" }}>{children}</main>
            </div>
        </>
    );
};

export default SideBar;