import React, { useState } from "react";
import axios from "axios";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import SectionHeader from "../../components/SectionHeader";
import AppleLogin from 'react-apple-login'
import { GoogleLogin } from '@react-oauth/google';

const Register = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    cpassword: "",
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.cpassword) {
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: 'Password & Confirm Password do not match'
      });
    } else {
      if (!formData.name || !formData.email || !formData.password) {
        Swal.fire('Please fill out all fields.')
        return;
      }
      if (formData.password.length < 6) {
        Swal.fire('Password must be at least 6 characters long.')
        return;
      }
      try {
        let baseUrl = process.env.REACT_APP_BASE_URL;
        const response = await axios.post(
          baseUrl + '/api/register',
          formData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.status === 200) {
          // console.log("User registered successfully.");
          Swal.fire('User info submitted successfully')
        }
        // console.log("response:", response);
      } catch (error) {
        // console.error("Error:", error);
        Swal.fire("Registration failed", error.message, "error")
      }
      localStorage.setItem("userInfo", JSON.stringify(formData));
      navigate("/login");
    }
  };

  const handleAppleResponse = (response) => {
    console.log("Apple login success:", response);
    Swal.fire("Apple Login Success");
  };

  const handleAppleFailure = (error) => {
    console.error("Apple login failed:", error);
    Swal.fire("Apple Login Failed", error.error, "error");
  };

  const responseMessage = (response) => {
    console.log(response);
    Swal.fire("Google Login Success");
  };
  const errorMessage = (error) => {
    Swal.fire("Google Login Failed", error.error, "error");
    console.log(error);
  };
  return (
    <>
      <section>
        <SectionHeader title='Create an Account' PageLink='Create an Account'></SectionHeader>
        <div className="login-form pt-5 pb-5">
          <Container>
            <Row className="justify-content-center">
              <Col xl={6} lg={8} md={10}>
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <label className="form-label" for="formBasicName">Username</label>
                    <input placeholder="Username" type="text" id="formBasicName" name="name" value={formData.name} onChange={handleChange} className="form-control" />
                  </div>
                  <div className="mb-3">
                    <label className="form-label" for="formBasicEmail">Email address</label>
                    <input placeholder="Enter Your Email" type="email" name="email" value={formData.email} onChange={handleChange} id="formBasicEmail" className="form-control" />
                  </div>
                  <div className="mb-3">
                    <label className="form-label" for="formBasicPassword">Password</label>
                    <input placeholder="Password" type="password" name="password" value={formData.password} onChange={handleChange} id="formBasicPassword" className="form-control" />
                  </div>
                  <div className="mb-3">
                    <label className="form-label" for="formBasicCPassword">Confirm Password</label>
                    <input placeholder="Confirm Password" type="password" name="cpassword" value={formData.cpassword} onChange={handleChange} id="formBasicCPassword" className="form-control" />
                  </div>
                  <button type="submit" className="blue-btn btn-style round-btn btn btn-link">Create Account</button>
                </form>

                <div className="or-option">
                  <div className="pb-2 google-btn">
                    <GoogleLogin onSuccess={responseMessage} onError={errorMessage} />
                  <AppleLogin
                    clientId="com.example.myapp"
                    redirectURI="https://www.thatread.com"
                    responseType="code id_token"
                    responseMode="form_post"
                    usePopup={true}
                    onSuccess={handleAppleResponse}
                    onFailure={handleAppleFailure}
                    />

                    </div>
                  <span>-- OR --</span><p>Already have an account? <Link to="/login">Login</Link></p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
}
export default Register;