import React, { useEffect, useState } from 'react'
import SectionHeader from '../../components/SectionHeader'
import { Card, Container, Table } from 'react-bootstrap'
import axios from 'axios';

const UserPayment = () => {
    const [orders, setOrders] = useState([]);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));

        if (user && user.id) {
            axios.get(`${process.env.REACT_APP_BASE_URL}/api/orders/${user.id}`)
                .then(response => {
                    setOrders(response.data);
                })
                .catch(error => {
                    console.error('Error fetching orders:', error);
                });
        } else {
            console.error('User ID not found');
        }
    }, []);


    return (
        <>
            <section>
                <SectionHeader title="Royalties & Payments" PageLink="Royalties & Payments"></SectionHeader>
                <Container>
                    <div className=" pt-5 pb-5 mx-auto" >
                        <Card.Body>
                            <Table striped bordered hover variant="light">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Order ID</th>
                                        <th>Products slug</th>
                                        <th>Sales</th>
                                        <th>Reward (30%)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orders.length > 0 ? (
                                        orders.map((order, index) => (
                                            <tr key={order.id}>
                                                <td>{index + 1}</td>
                                                <td>{order.id}</td>
                                                <td>
                                                    <ul>
                                                        {order.order_products.map(orderProduct => (
                                                            <li key={orderProduct.product.id}>
                                                                {orderProduct.product.slug}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </td>
                                                <td>{order.total}</td>
                                                <td>{(order.total * 0.3).toFixed(2)}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="3" className="text-center">No orders found</td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </Card.Body>
                        {/* <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Sales</th>
                                    <th>Earn</th>
                                    <th>Royalties</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>01</td>
                                    <td>$ 154.0</td>
                                    <td>$ 0.20</td>
                                    <td>000</td>
                                </tr>
                            </tbody>
                        </Table> */}
                    </div>
                </Container>
            </section >
        </>
    )
}

export default UserPayment