import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap'
import Img1 from '../images/bookcoverpro.png'
import Img2 from '../images/bookcenter.png'
import { FaStar } from "react-icons/fa6";
import { Link } from 'react-router-dom';

const BestSellerBook = () => {
    const [offset, setOffset] = useState({ x: 0, y: 0 });

    const handleMouseMove = (event) => {
        const rect = event.currentTarget.getBoundingClientRect();
        const posX = event.clientX - (rect.left + rect.width / 2);
        const posY = event.clientY - (rect.top + rect.height / 2);

        const rotateX = (posY / rect.height) * 15;
        const rotateY = -(posX / rect.width) * 15;

        setOffset({
            x: posX * 0.012,
            y: posY * 0.012,
            rotateX,
            rotateY,
        });
    };

    const resetOffset = () => {
        setOffset({ x: 0, y: 0, rotateX: 0, rotateY: 0 });
    };
    return (
        <>
            <section>
                <div className="single-pro-banner">
                    <Container>
                        <Row className='align-items-center'>
                            <Col md={5} sm={12}>
                                <div className="img-holder">
                                    <div className="parallax-container">
                                        <img
                                            src={Img1}
                                            alt="Hero Img"
                                            onMouseMove={handleMouseMove}
                                            onMouseLeave={resetOffset}
                                            style={{
                                                willChange: 'transform',
                                                transition: 'transform 1000ms cubic-bezier(0.03, 0.98, 0.52, 0.99)',
                                                transform: `perspective(800px) rotateX(${offset.rotateX}deg) rotateY(${offset.rotateY}deg) scale3d(1, 1, 1) translate(${offset.x}%, ${offset.y}%)`,
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col md={3} sm={6}>
                                <div className="real-exp">
                                    <h3>Real Experiences from Real product</h3>
                                    <div className="img-holder">
                                        <img src={Img2} alt="image" />
                                    </div>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                </div>
                            </Col>
                            <Col md={4} sm={6}>
                                <div className="best-side">
                                    <div className="bseller-tag">
                                        <span>Best <br /> Seller</span>
                                    </div>
                                    <div className="content-area">
                                        <h3>Your Title Here</h3>
                                        <p>Loremipsum</p>
                                        <div className="rate-area">
                                            <ul>
                                                <li><FaStar /></li>
                                                <li><FaStar /></li>
                                                <li><FaStar /></li>
                                                <li><FaStar /></li>
                                                <li><FaStar /></li>
                                            </ul>
                                            <span>3943 Reveiws</span>
                                        </div>
                                        <Link className="white-btn btn-style" to="/shop">SHOP NOW</Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
        </>
    )
}

export default BestSellerBook