import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import Slider from 'react-slick';
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import Img1 from '../images/shopcat01.jpg';
import Img2 from '../images/shopcat02.jpg';
import Img3 from '../images/shopcat03.jpg';
import Img4 from '../images/shopcat04.jpg';
import Img5 from '../images/shopcat05.jpg';
import Img6 from '../images/shopcat06.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSettings } from '../Redux/actions/settingsActions';

// ******* Slick Slider function START ******* 
function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}>
            <FaArrowRight />
        </div>
    );
}
function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
        <div
            className={className}
            onClick={onClick}>
            <FaArrowLeft />
        </div>
    );
}
// ******* Slick Slider function END ******* 
const Category = () => {
    // ******* CMS START ******* 
    const dispatch = useDispatch();
    const { settings, loading, error } = useSelector(state => state.settings);
    useEffect(() => {
        dispatch(fetchSettings());
    }, [dispatch]);
    const section_title = settings.find(setting => setting.name === 'section_title');
    const section_sub_title = settings.find(setting => setting.name === 'section_sub_title');
    // ******* CMS END ******* 
    var setting = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,

                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    }
    return (
        <>
            <section>
                <div className="shop-by">
                    <Container>
                        <div className="center-heading">
                            <h2 className="head-sec">{section_title?.value}</h2>
                            <div dangerouslySetInnerHTML={{ __html: section_sub_title?.value }} />
                        </div>
                        <div className="shop-by-slider">
                            <Slider {...setting}>
                                <div className="sli-box">
                                    <div className="img-holder">
                                        <img src={Img1} alt="images" />
                                        <div className="hov-box">
                                            <a href="/products"><FaArrowRight /></a>
                                        </div>
                                    </div>
                                    <a className="cat-title" href="/products"><strong>Place your title here</strong></a>
                                </div>
                                <div className="sli-box">
                                    <div className="img-holder">
                                        <img src={Img2} alt="images" />
                                        <div className="hov-box">
                                            <a href="/products"><FaArrowRight /></a>
                                        </div>
                                    </div>
                                    <a className="cat-title" href="/products"><strong>Place your title here</strong></a>
                                </div>
                                <div className="sli-box">
                                    <div className="img-holder">
                                        <img src={Img3} alt="images" />
                                        <div className="hov-box">
                                            <a href="/products"><FaArrowRight /></a>
                                        </div>
                                    </div>
                                    <a className="cat-title" href="/products"><strong>Place your title here</strong></a>
                                </div>
                                <div className="sli-box">
                                    <div className="img-holder">
                                        <img src={Img4} alt="images" />
                                        <div className="hov-box">
                                            <a href="/products"><FaArrowRight /></a>
                                        </div>
                                    </div>
                                    <a className="cat-title" href="/products"><strong>Place your title here</strong></a>
                                </div>
                                <div className="sli-box">
                                    <div className="img-holder">
                                        <img src={Img5} alt="images" />
                                        <div className="hov-box">
                                            <a href="/products"><FaArrowRight /></a>
                                        </div>
                                    </div>
                                    <a className="cat-title" href="/products"><strong>Place your title here</strong></a>
                                </div>
                                <div className="sli-box">
                                    <div className="img-holder">
                                        <img src={Img6} alt="images" />
                                        <div className="hov-box">
                                            <a href="/products"><FaArrowRight /></a>
                                        </div>
                                    </div>
                                    <a className="cat-title" href="/products"><strong>Place your title here</strong></a>
                                </div>
                            </Slider>
                        </div>
                    </Container>
                </div>
            </section >
        </>
    )
}

export default Category