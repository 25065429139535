import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import Card from 'react-bootstrap/Card';
import { Table } from 'react-bootstrap';
import Loading from '../../components/Loading';
import axios from 'axios';
import { Link } from 'react-router-dom';

const Library = () => {
    const [download, setDownload] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState('');
    let userInfo = JSON.parse(localStorage.getItem('user-info'));

    useEffect(() => {
        const fetchDownload = async () => {
            try {
                let baseUrl = process.env.REACT_APP_BASE_URL;
                const response = await axios.get(baseUrl + '/api/orders/' + userInfo.id);
                setDownload(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching download:', error);
                setError('Failed to fetch download. Please try again later.');
                setLoading(false);
            }
        };
        fetchDownload();
    }, [userInfo.id]);
    if (loading) {
        return <><Loading /></>;
    }
    return (
        <>
             <div className="dash-card">
                <Card className='dash-sub-card'>
                    <Card.Header as="h4" className="dash-card-header">Account Information</Card.Header>
                    {error && <div className="alert alert-danger">{error}</div>}
                    {success && <div className="alert alert-success">{success}</div>}
                    <Card.Body>
                        <Table striped bordered hover variant="light">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Product Name</th>
                                    <th>Order state</th>
                                    <th>Download</th>
                                </tr>
                            </thead>
                            <tbody>
                                {download.map(order => (
                                    order.order_products.map(product => (
                                        <tr key={product.id}>
                                            <td>{order.id}</td>
                                            <td>{product.product.slug}</td>
                                            <td>{order.status}</td>
                                            <td>
                                                {order.status === "completed" ? (
                                                    <Link to={`http://localhost/thatreadsnew/storage/app/${product.product.digital_file}`}>
                                                        Download
                                                    </Link>
                                                ) : (
                                                    <span>N/A</span>
                                                )}
                                            </td>
                                        </tr>
                                    ))
                                ))}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </div>
        </>
    )
}

export default Library