import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import GoogleTranslate from './GoogleTranslate';

const TranslatePopup = () => {
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);
  return (
    <div>
      <Modal centered show={show} onHide={handleClose}>
      <Modal.Header closeButton>
          <Modal.Title>Select your language</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='google_translate'>
            <GoogleTranslate />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default TranslatePopup